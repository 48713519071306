import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import SelectInput from 'components/select-input';
import TextFieldInputV2 from 'components/text-field';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import OutlinedSection from '../outlined-section';
import useStyles from '../styles';
import { required } from '../validators';
import { getStep1Fields } from './fields';
// @ts-ignore
import countryList from 'react-select-country-list';

const SectionFields = ({
  showSaveSupplierInfoBtn,
  showRetrieveSupplierInfoBtn,
  saveSupplierInfoAsDefault,
  applyDefaultSupplierInfo,
  setShowSaveSupplierInfoBtn,
}: {
  showSaveSupplierInfoBtn: boolean;
  showRetrieveSupplierInfoBtn: boolean;
  saveSupplierInfoAsDefault: () => void;
  setShowSaveSupplierInfoBtn: (show: boolean) => void;
  applyDefaultSupplierInfo: () => void;
}) => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const sections = getStep1Fields();
  const { t } = useTranslation(['fields', 'sds_authoring']);
  const classes = useStyles();
  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((el: any) => {
          return { value: el.value.toLowerCase(), label: el.label };
        }),
    []
  );

  return (
    <>
      {sections.map((section, index) => {
        if (section.title === translations.headers.header_1_3) {
          return (
            <OutlinedSection key={index} title={section.title}>
              <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="subtitle1">
                    {t('sds_authoring:supplier_info')}
                  </Typography>
                </Grid>
                <Grid item>
                  {showSaveSupplierInfoBtn ? (
                    <Button onClick={() => saveSupplierInfoAsDefault()}>
                      {t('sds_authoring:save_supplier_info_as_default')}
                    </Button>
                  ) : showRetrieveSupplierInfoBtn ? (
                    <Button onClick={() => applyDefaultSupplierInfo()}>
                      {t('sds_authoring:retrieve_supplier_info')}
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item md={8} xs={12}>
                <Field
                  validate={[required]}
                  name="company_name"
                  onChange={() => setShowSaveSupplierInfoBtn(true)}
                  component={TextFieldInputV2}
                  label={translations.sections.company_name}
                  classes={{ root: classes.textInput }}
                  required={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  validate={[required]}
                  name="street"
                  onChange={() => setShowSaveSupplierInfoBtn(true)}
                  component={TextFieldInputV2}
                  label={translations.sections.street}
                  classes={{ root: classes.textInput }}
                  required={true}
                />
              </Grid>
              <Grid item md={8} xs={12}>
                <Field
                  name="postcode"
                  onChange={() => setShowSaveSupplierInfoBtn(true)}
                  component={TextFieldInputV2}
                  label={translations.sections.postcode}
                  classes={{ root: classes.textInput }}
                  required={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  validate={[required]}
                  name="city"
                  onChange={() => setShowSaveSupplierInfoBtn(true)}
                  component={TextFieldInputV2}
                  label={translations.sections.city}
                  classes={{ root: classes.textInput }}
                  required={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  onChange={() => setShowSaveSupplierInfoBtn(true)}
                  name="state"
                  component={TextFieldInputV2}
                  label={
                    translations.sections.state
                      ? translations.sections.state
                      : t('fields:state')
                  }
                  classes={{ root: classes.textInput }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  validate={[required]}
                  name="company_country"
                  onChange={() => setShowSaveSupplierInfoBtn(true)}
                  component={SelectInput}
                  valueField="label"
                  labelField="label"
                  label={t('sds_authoring:choose_country')}
                  classes={{ root: classes.textInput }}
                  options={countryOptions}
                  required={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  name="telephone"
                  onChange={() => setShowSaveSupplierInfoBtn(true)}
                  component={TextFieldInputV2}
                  label={
                    translations.sections.telephone
                      ? translations.sections.telephone
                      : t('fields:telephone')
                  }
                  classes={{ root: classes.textInput }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="website"
                  onChange={() => setShowSaveSupplierInfoBtn(true)}
                  component={TextFieldInputV2}
                  label={translations.sections.website}
                  classes={{ root: classes.textInput }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  onChange={() => setShowSaveSupplierInfoBtn(true)}
                  name="email"
                  component={TextFieldInputV2}
                  label={translations.sections.email}
                  type="email"
                  classes={{ root: classes.textInput }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t('sds_authoring:manufacturer_info')}
                </Typography>
              </Grid>
              {section.fields.map((field, index) => {
                return (
                  <Grid key={index} item md={field.xs} xs={12}>
                    <Field
                      validate={field.validate ?? null}
                      name={field.name}
                      component={field.component}
                      label={field.label}
                      multiline={field.multiline ?? null}
                      options={field.options ?? null}
                      required={field.required ?? null}
                      valueField={field.valueField ?? null}
                      labelField={field.labelField ?? null}
                      classes={{ root: classes.textInput }}
                    />
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        }
        return (
          <OutlinedSection key={index} title={section.title}>
            {section.fields.map((field, index) => {
              return (
                <Grid key={index} item md={field.xs} xs={12}>
                  <Field
                    validate={field.validate ?? null}
                    name={field.name}
                    component={field.component}
                    label={field.label}
                    multiline={field.multiline ?? null}
                    options={field.options ?? null}
                    required={field.required ?? null}
                    valueField={field.valueField ?? null}
                    labelField={field.labelField ?? null}
                    classes={{ root: classes.textInput }}
                  />
                </Grid>
              );
            })}
          </OutlinedSection>
        );
      })}
    </>
  );
};

export default SectionFields;
