import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DesktopDialog from 'components/dialog/Dialog';
import { PopupProps } from './PopupsInterface';
import useStyles from './styles';

const ConfirmActionPopup = ({ onClose, open, onSubmit, title }: PopupProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['popups', 'actions']);

  if (!open) {
    return null;
  }

  return (
    <DesktopDialog
      classes={{ paper: classes.confirmPopup }}
      onClose={() => onClose()}
      DialogTitleProps={{
        style: {
          textAlign: 'center',
        },
      }}
      title={title ?? ''}
      actions={
        <>
          <Button variant={'contained'} color={'primary'} onClick={onSubmit}>
            {t('actions:confirm')}
          </Button>
          <Button onClick={onClose} variant={'outlined'} color={'primary'}>
            {t('actions:cancel')}
          </Button>
        </>
      }
    />
  );
};

export default ConfirmActionPopup;
