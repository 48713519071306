import { Grid, Typography } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import OutlinedSection from '../outlined-section';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import { getStep15Fields } from './fields';
import TextFieldInputV2 from 'components/text-field';
import { CustomFormProps } from '../utils';
import SaveAsButton from 'components/save-as-btn';

const RegulationsForm = (props: CustomFormProps & InjectedFormProps<{}, CustomFormProps>) => {
  const classes = useStyles();
  const { handleNext, dirty, handleCopy } = props;
  const form = useSelector((state: any) => state.form.regulationsForm);
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );

  const sections = getStep15Fields();

  const handleSubmit = () => {
    if (dirty) {
      handleNext({ ...form.values });
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h5" mb={2}>
          {translations.headers['15_header_regulatory_info']}
        </Typography>
        <Field
          name="regulations_description"
          component={TextFieldInputV2}
          classes={{ root: classes.textInput }}
          multiline={true}
        />
        {sections.map((section, index) => {
          return (
            <OutlinedSection key={index} title={section.title}>
              {section.fields.map((field, idx) => {
                return (
                  <Grid key={idx} item xs={field.xs}>
                    <Field
                      validate={field.validate ?? null}
                      name={field.name}
                      component={field.component}
                      label={field.label}
                      multiline={field.multiline ?? null}
                      options={field.options ?? null}
                      required={field.required ?? null}
                      valueField={field.valueField ?? null}
                      labelField={field.labelField ?? null}
                      classes={{ root: classes.textInput }}
                    />
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        })}
        <SubmitButton handleSubmit={handleSubmit} />
        <SaveAsButton handleSubmit={handleCopy} />

      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.regulationsForm?.values) {
    return {
      initialValues: {
        regulations_description:
          state.pageReducer.sdsInfo?.regulations_description,
        safety_env_and_health_regulations_info:
          state.pageReducer.sdsInfo?.safety_env_and_health_regulations_info,
        eu_regulatory_info: state.pageReducer.sdsInfo?.eu_regulatory_info,
        other_eu_regulations: state.pageReducer.sdsInfo?.other_eu_regulations,
        international_regulations:
          state.pageReducer.sdsInfo?.international_regulations,
        other_regulations: state.pageReducer.sdsInfo?.other_regulations,
        national_regulatory_info:
          state.pageReducer.sdsInfo?.national_regulatory_info,
        us_federal_regulations:
          state.pageReducer.sdsInfo?.us_federal_regulations,
        canadian_regulations: state.pageReducer.sdsInfo?.canadian_regulations,
        uk_regulatory_info: state.pageReducer.sdsInfo?.uk_regulatory_info,
        chemical_safety_assessment:
          state.pageReducer.sdsInfo?.chemical_safety_assessment,
        additional_regulations_info:
          state.pageReducer.sdsInfo?.additional_regulations_info,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'regulationsForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(RegulationsForm)
);
