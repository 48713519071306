import { Typography, Card, Box } from '@mui/material';
import useStyles from './styles';

function OutlinedSection({
  title,
  children,
  variant,
  spacing
}: {
  title: string;
  children: React.ReactNode;
  variant?: any;
  spacing?: any;
}) {
  const classes = useStyles();
  return (
    <Box mt={variant ? 0 : 2}>
      <Typography variant={variant ?? "h6"} className={classes.title}>
        {title}
      </Typography>
      <Card variant="outlined" className={classes.contentWrapper}>
        {children}
      </Card>
    </Box>
  );
}

export default OutlinedSection;
