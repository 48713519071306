import { AccountCircle, AssignmentOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Popover
} from '@mui/material';
import { getUserAvatar } from 'api/get';
import AllMySdsIcon from 'assets/icons/AllMySds';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import PopoverItem from './popover-menu-item';
import useStyles from './styles';
import Logo from 'assets/icons/Logo';

const HeaderV2 = () => {
  const classes = useStyles();
  const history = useHistory();
  const userInfo = useSelector((state: any) => state.pageReducer.userInfo);
  const { t } = useTranslation(['header', 'languages']);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div className={classes.header}>
      <Box className={classes.headerNav}>
        <Logo 
          className={classes.logoImg}
          onClick={() => {
            history.push('/');
          }}
        />
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <MenuItem onClick={handleCloseNavMenu}>
              <NavLink
                style={{ textDecoration: 'none' }}
                to={'/our-components/'}
              >
                <Box className={classes.headerLink}>
                  <AssignmentOutlined
                    sx={{ width: '24px', color: '#424B5A', mr: '10px' }}
                  />
                  {t('header:our_components')}
                </Box>
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <NavLink style={{ textDecoration: 'none' }} to={'/'}>
                <Box className={classes.headerLink}>
                  <AllMySdsIcon
                    width={26}
                    height={26}
                    color={'#424B5A'}
                    style={{ marginRight: '10px' }}
                  />
                  {t('header:our_sds')}
                </Box>
              </NavLink>
            </MenuItem>
          </Menu>
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '20px' }}>
          <NavLink
            style={{ textDecoration: 'none' }}
            to={'/our-components/'}
          >
            <Box className={classes.headerLink}>
              <AssignmentOutlined
                sx={{ width: '24px', color: '#FFF', mr: '10px' }}
              />
              {t('header:our_components')}
            </Box>
          </NavLink>
          <NavLink style={{ textDecoration: 'none' }} to={'/'}>
            <Box className={classes.headerLink}>
              <AllMySdsIcon
                width={24}
                height={24}
                color={'#FFF'}
                style={{ marginRight: '10px' }}
              />
              {t('header:our_sds')}
            </Box>
          </NavLink>
        </Box>
      </Box>
      <Logo 
        className={classes.mobileLogo}
        onClick={() => {
          history.push('/');
        }}
      />
      <Box sx={{ marginRight: { xs: '10px', md: '20px' } }}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          {userInfo && userInfo.avatar ? (
            <Avatar alt="avatar" src={getUserAvatar(userInfo.avatar)} />
          ) : (
            <AccountCircle sx={{ color: '#FFF' }} />
          )}
        </IconButton>
        <Popover
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <PopoverItem user={userInfo} />
        </Popover>
      </Box>
    </div>
  );
};

export default HeaderV2;
