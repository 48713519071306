import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Loader } from 'components';

import Typography from '@mui/material/Typography';
import useStyles from './styles';
import OutlinedSection from 'components/sds-authoring-forms-v2/outlined-section';

const HazardsSectionPreview = () => {
  const classes = useStyles();
  const { t } = useTranslation(['sds_authoring']);
  const hazardsFormValues = useSelector(
    (state: any) => state.form.hazardsCodesForm.values
  );
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const [hazardsData, setHazardsData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const prepareData = () => {
    const data: any = {};
    data['signalWord'] = [];
    data['signalWord'] = [];
    data['ghsPictograms'] = [];
    data['hazardStatements'] = [];
    data['distinctHazardStatements'] = [];

    if (hazardsFormValues.hazardCodes) {
      const signalWords = hazardsFormValues.hazardCodes.map(
        (el: any) => el.signal_word
      );

      data['signalWord'] = signalWords.includes('Danger')
        ? 'Danger'
        : signalWords.includes('Warning')
        ? 'Warning'
        : '-';

      data['ghsPictograms'] = Array.from(
        new Set(
          hazardsFormValues.hazardCodes.map((el: any) => el.pictogram_url)
        )
      );
      data['hazardStatements'] = hazardsFormValues.hazardCodes.map(
        (el: any) => {
          return {
            statement_code: el.statement_code,
            statements: el.statements,
            hazard_category_abbreviation: el.hazard_category_abbreviation,
          };
        }
      );
      data['distinctHazardStatements'] = [];
      hazardsFormValues.hazardCodes.map((statement: any) => {
        if (
          !data['distinctHazardStatements'].find(
            (el: any) => el.statement_code === statement.statement_code
          )
        ) {
          data.distinctHazardStatements.push(statement);
        }
      });
    }

    data['precautionsStatements'] = [];
    if (hazardsFormValues.precautionsCodes) {
      hazardsFormValues.precautionsCodes.map((el: any) => {
        data.precautionsStatements.push({
          statement_code: el.statement_code,
          statements: el.statements,
        });
      });
    }
    setHazardsData(data);
  };

  React.useEffect(() => {
    if (hazardsFormValues) {
      setLoading(true);
      prepareData();
      setLoading(false);
    }
  }, [hazardsFormValues]);

  return (
    <div>
      {loading || !hazardsData ? (
        <Loader />
      ) : (
        <div>
          <OutlinedSection
            title={
              translations.headers['2_1_header_classification_of_the_substance']
            } 
            spacing={2}
          >
            <div className={classes.statementsBlock}>
              <Typography>{hazardsFormValues.classification}</Typography>

              <Typography className={classes.statementsHeading}>
                {t('sds_authoring:hazard_statements')}
              </Typography>
              {hazardsData.hazardStatements.map((el: any) => (
                <div className={classes.statementItem}>
                  <Typography className={classes.statementCode}>
                    {el.statement_code}
                    {el.hazard_category_abbreviation
                      ? `,${el.hazard_category_abbreviation}`
                      : ''}
                  </Typography>
                  <Typography className={classes.statements}>
                    {el.statements}
                  </Typography>
                </div>
              ))}
            </div>
          </OutlinedSection>
          <OutlinedSection
            title={translations.headers['2_2_header_label_elements']}
            spacing={2}
          >
            <div className={classes.statementsBlock}>
              {hazardsFormValues.is_not_classified_as_hazardous ? null : (
                <>
                  <Typography>
                    {translations.sections.signal_word}:{hazardsData.signalWord}
                  </Typography>
                  <Typography>
                    {translations.sections.hazard_pictograms}
                  </Typography>
                  <div className={classes.pictogramsWrapper}>
                    {hazardsData.ghsPictograms.map((el: any) => (
                      <img
                        src={el}
                        alt={'GHS pic.'}
                        className={classes.pictogram}
                      />
                    ))}
                  </div>
                </>
              )}
              {hazardsFormValues.hazard_components && (
                <div>
                  <Typography className={classes.sectionTitle}>
                    {translations.sections.hazard_components}
                  </Typography>
                  <Typography>{hazardsFormValues.hazard_components}</Typography>
                </div>
              )}
              <Typography className={classes.statementsHeading}>
                {t('sds_authoring:hazard_statements')}
              </Typography>
              {hazardsData.distinctHazardStatements.map((el: any) => (
                <div className={classes.statementItem}>
                  <Typography className={classes.statementCode}>
                    {el.statement_code}
                  </Typography>
                  <Typography className={classes.statements}>
                    {el.statements}
                  </Typography>
                </div>
              ))}
            </div>
            <div className={classes.statementsBlock}>
              <Typography className={classes.statementsHeading}>
                {translations.sections.precautions_statements}
              </Typography>
              {hazardsData.precautionsStatements.map((el: any) => (
                <div className={classes.statementItem}>
                  <Typography className={classes.statementCode}>
                    {el.statement_code}
                  </Typography>
                  <Typography className={classes.statements}>
                    {el.statements}
                  </Typography>
                </div>
              ))}
            </div>
            {hazardsFormValues.contains && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.contains}
                </Typography>
                <Typography>{hazardsFormValues.contains}</Typography>
              </div>
            )}
            {hazardsFormValues.supplemental_label_elements && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.supplemental_label_elements}
                </Typography>
                <Typography>
                  {hazardsFormValues.supplemental_label_elements}
                </Typography>
              </div>
            )}
            {hazardsFormValues.annex_restrictions && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.annex_restrictions}
                </Typography>
                <Typography>{hazardsFormValues.annex_restrictions}</Typography>
              </div>
            )}
            {hazardsFormValues.special_packing_requirements && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.special_packing_requirements}
                </Typography>
                <Typography>
                  {hazardsFormValues.special_packing_requirements}
                </Typography>
              </div>
            )}
            {hazardsFormValues.touch_hazard_warning && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.touch_hazard_warning}
                </Typography>
                <Typography>
                  {hazardsFormValues.touch_hazard_warning}
                </Typography>
              </div>
            )}
            {hazardsFormValues.hazards_additional_information && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.hazards_additional_information}
                </Typography>
                <Typography>
                  {hazardsFormValues.hazards_additional_information}
                </Typography>
              </div>
            )}
          </OutlinedSection>
          <OutlinedSection
            title={translations.headers['2_3_header_other_hazards']}
            spacing={2}
          >
            {hazardsFormValues.hazards_results_of_pbt_and_vpvb_assessment && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {
                    translations.sections
                      .hazards_results_of_pbt_and_vpvb_assessment
                  }
                </Typography>
                <Typography>
                  {hazardsFormValues.hazards_results_of_pbt_and_vpvb_assessment}
                </Typography>
              </div>
            )}
            {hazardsFormValues.hazards_potential_health_effects_accute && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {
                    translations.sections
                      .hazards_potential_health_effects_accute
                  }
                </Typography>
                <Typography>
                  {hazardsFormValues.hazards_potential_health_effects_accute}
                </Typography>
              </div>
            )}
            {hazardsFormValues.hazards_potential_health_effects && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.hazards_potential_health_effects}
                </Typography>
                <Typography>
                  {hazardsFormValues.hazards_potential_health_effects}
                </Typography>
              </div>
            )}
            {hazardsFormValues.long_term_hazards && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.long_term_hazards}
                </Typography>
                <Typography>{hazardsFormValues.long_term_hazards}</Typography>
              </div>
            )}
            {hazardsFormValues.primary_routes_of_exposure && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.primary_routes_of_exposure}
                </Typography>
                <Typography>
                  {hazardsFormValues.primary_routes_of_exposure}
                </Typography>
              </div>
            )}
            {hazardsFormValues.hazards_outside_classification && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.hazards_outside_classification}
                </Typography>
                <Typography>
                  {hazardsFormValues.hazards_outside_classification}
                </Typography>
              </div>
            )}
            {hazardsFormValues.other_hazards && (
              <div>
                <Typography className={classes.sectionTitle}>
                  {translations.sections.other_hazards}
                </Typography>
                <Typography>{hazardsFormValues.other_hazards}</Typography>
              </div>
            )}
          </OutlinedSection>
        </div>
      )}
    </div>
  );
};

export default HazardsSectionPreview;
