export function getStructuredDataForFields(structuredData, components, fields) {
  const structuredDataByComponent = {};
  Object.keys(structuredData).map((componentId) => {
    const componentStructuredData = structuredData[componentId];
    if (structuredData[componentId]) {
      const componentName = components.find(
        (el) => el.id?.toString() === componentId || el.hash === componentId
      )?.component_name;
      if (componentName) {
        fields.forEach((field) => {
          if (structuredDataByComponent[field] === undefined) {
            structuredDataByComponent[field] = [];
          }
          if (componentStructuredData[field]?.length) {
            structuredDataByComponent[field].push({
              componentName: componentName,
              description: componentStructuredData[`${field}_desc`],
              rows: componentStructuredData[field],
            });
          }
        });
      }
    }
  });
  return structuredDataByComponent;
}
