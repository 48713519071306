import { useParams } from 'react-router-dom';
import { BACKEND_URL } from 'api/constants';

const OriginalSDSViewerPage = () => {
  const params = useParams();

  return (
    <iframe
      width="99%"
      height="99%"
      src={`${BACKEND_URL}/sds-admin/public-view/${params.token}`}
    />
  );
};

export default OriginalSDSViewerPage;
