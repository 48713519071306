import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser } from 'api';
import useStyles from './styles';
import axiosAuthoringInstance from 'api/constants';
import { setUserInfo } from 'reducers/pageAction';
import LoginForm from 'components/login-form/LoginForm';
import { useTranslation } from 'react-i18next';
import Logo from 'assets/icons/Logo';

const LoginPageV2 = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['login']);
  const [errors, setErrors] = useState([]);
  const urlParams = new URLSearchParams(window.location?.search);
  const redirectPath = urlParams.get('redirectPath');

  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');
    if (access_token || refresh_token) {
      if (redirectPath) {
        window.location.href = redirectPath;
      }
      return;
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email = event.currentTarget.email.value;
    const password = event.currentTarget.password.value;

    const loginUserRequest = loginUser(email, password);
    loginUserRequest.then((response) => {
      if (response.status === 200) {
        setErrors([]);
        axiosAuthoringInstance.defaults.headers['Authorization'] =
          'JWT ' + response.data.access_token;
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        dispatch(setUserInfo(response.data));
        if (redirectPath) {
          window.location.href = redirectPath;
        } else {
          window.location.href = '/';
        }
        return;
      }
      if (response.data.errors) {
        setErrors(response.data.errors);
      }
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.loginCard}>
        <Logo 
          className={classes.loginLogo} 
          isLogin={true} 
          styles={{
            height: 50, 
            padding: '25px',
          }}
        />
        <div className={classes.loginCardContent}>
          <div className={classes.welcomeContent}>
            <div className={classes.welcomeText}>
              {' '}
              {t('login:welcome_to_sds_authoring')}{' '}
            </div>
            <div className={classes.welcomeSubtext}>
              {' '}
              {t('login:please_sign_in_to_your_account')}{' '}
            </div>
          </div>
          <LoginForm errors={errors} handleSubmit={handleSubmit} />
      </div>
      </div>
    </div>
  );
};

export default LoginPageV2;
