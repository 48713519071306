import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  addMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '10px 0px'
  },
  baseField: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
