import { Box, Button, Checkbox, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DesktopDialog from 'components/dialog/Dialog';
import { setSDSInfo } from 'reducers/pageAction';

import { update } from 'api';
import useStyles from './styles';

interface SelectAdditionalDataPopup {
  data: any;
  onClose: () => void;
  open: boolean;
}

const SelectAdditionalDataPopup = React.memo(
  ({ data, onClose, open }: SelectAdditionalDataPopup) => {
    const classes = useStyles();
    const { t } = useTranslation(['popups', 'actions']);
    const dispatch = useDispatch();
    const sdsInfo = useSelector((state: any) => state.pageReducer.sdsInfo);
    const sdsId = useSelector((state: any) => state.pageReducer.pdfId);
    const [selectedData, setSelectedData] = React.useState<any>({});
    const [showSections, setShowSections] = React.useState(false);

    const updateSDS = (data: any) => {
      if (sdsId) {
        const updateSDSRequest = update(`/sdsAuthoring/files/${sdsId}/`, data);
        updateSDSRequest.then((response) => {
          if (response?.status === 200) {
            dispatch(setSDSInfo(response.data));
          }
        });
      }
    };

    if (!open) {
      return null;
    }

    return (
      <DesktopDialog
        classes={{ paper: classes.selectComponentsPopup }}
        onClose={() => onClose()}
        title={t('popups:select_additional_values')}
        disableEnforceFocus
      >
        <div className={classes.content}>
          <div style={{ width: '100%' }}>
            {data &&
              Object.keys(data).map((el, index) => {
                if (!data[el].value) {
                  return;
                }
                return (
                  <div className={classes.additionalDataWrapper}>
                    <div
                      style={{ width: showSections ? '50%' : '100%' }}
                      key={index}
                      className={classes.additionalData}
                    >
                      <div className={classes.dataHeading}>
                        <Checkbox
                          color={'primary'}
                          onClick={() => {
                            if (selectedData[el]) {
                              let newData = selectedData;
                              delete newData[el];
                              setSelectedData({ ...newData });
                            } else {
                              const element: any = document.getElementById(
                                `${data[el].default_literal}`
                              );
                              setSelectedData({
                                ...selectedData,
                                [el]: element.value,
                              });
                            }
                          }}
                          checked={!!selectedData[el]}
                        />
                        <Typography>{data[el].default_literal}</Typography>
                      </div>
                      <TextField
                        id={data[el].default_literal}
                        defaultValue={data[el].value}
                        className={classes.additionalDataValue}
                        multiline={true}
                        fullWidth={true}
                        minRows={6}
                      >
                        {data[el].value}
                      </TextField>
                    </div>
                    {showSections && (
                      <div
                        className={classes.reviewDataRightSideContent}
                        dangerouslySetInnerHTML={{
                          __html: data[el]?.html_form,
                        }}
                      />
                    )}
                  </div>
                );
              })}
          </div>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Checkbox
              color={'primary'}
              onClick={() => setShowSections(!showSections)}
              checked={showSections}
            />
            <Typography>{t('popups:show_sections')}</Typography>
          </Box>
        </div>
        <div className={classes.actionWrapper}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              if (sdsId) {
                updateSDS({ ...selectedData });
              } else {
                dispatch(setSDSInfo({ ...sdsInfo, ...selectedData }));
              }

              onClose();
            }}
            className={classes.button}
          >
            {t('actions:save')}
          </Button>
          <Button
            onClick={onClose}
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
          >
            {t('actions:cancel')}
          </Button>
        </div>
      </DesktopDialog>
    );
  }
);

export default SelectAdditionalDataPopup;
