import { Grid, Typography } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import OutlinedSection from '../outlined-section';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import InformationHazardClassesFields from './toxic-fields';
import { CustomFormProps } from '../utils';
import InformationOtherHazardFields from './information-other-hazard';
import SaveAsButton from 'components/save-as-btn';

interface ToxicologicalFormProps extends CustomFormProps {
  components: any;
  doNotIncludeStructuredDataFromComponents: any;
  componentStructuredData: any;
}

const ToxicologicalForm = (
  props: ToxicologicalFormProps & InjectedFormProps<{}, ToxicologicalFormProps>
) => {
  const {
    handleNext,
    dirty,
    components,
    doNotIncludeStructuredDataFromComponents,
    componentStructuredData,
    handleCopy,
  } = props;
  const classes = useStyles();
  const form = useSelector((state: any) => state.form.toxicologicalForm);
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const handleSubmit = () => {
    if (dirty) {
      handleNext({ ...form.values });
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['11_header_toxological_info']}
      </Typography>
      <form onSubmit={handleSubmit}>
        <OutlinedSection
          variant="h5"
          title={
            translations.headers[
              '11_1_header_info_on_hazard_classes_as_defined_in_regulation'
            ]
          }
        >
          <Grid item xs={12}>
            <InformationHazardClassesFields
              components={components}
              doNotIncludeStructuredDataFromComponents={
                doNotIncludeStructuredDataFromComponents
              }
              componentStructuredData={componentStructuredData}
            />
          </Grid>
        </OutlinedSection>
        <OutlinedSection
          variant="h5"
          title={
            translations.headers['11_2_header_information_on_other_hazards']
          }
        >
          <Grid item xs={12}>
            <InformationOtherHazardFields />
          </Grid>
        </OutlinedSection>
        <SubmitButton handleSubmit={handleSubmit} />
        <SaveAsButton handleSubmit={handleCopy} />

      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.toxicologicalForm?.values) {
    return {
      initialValues: {
        acute_toxicity: state.pageReducer.sdsInfo.acute_toxicity,
        skin_corrosion: state.pageReducer.sdsInfo.skin_corrosion,
        eye_damage: state.pageReducer.sdsInfo.eye_damage,
        germ_cell_mutagenic: state.pageReducer.sdsInfo.germ_cell_mutagenic,
        carcinogenicity: state.pageReducer.sdsInfo.carcinogenicity,
        reproductive_toxicity: state.pageReducer.sdsInfo.reproductive_toxicity,
        mutagenicity: state.pageReducer.sdsInfo.mutagenicity,
        sensitization: state.pageReducer.sdsInfo.sensitization,
        teratogenicity: state.pageReducer.sdsInfo.teratogenicity,
        organ_toxicity_single_exposure:
          state.pageReducer.sdsInfo.organ_toxicity_single_exposure,
        organ_toxicity_repeated_exposure:
          state.pageReducer.sdsInfo.organ_toxicity_repeated_exposure,
        ways_of_exposure: state.pageReducer.sdsInfo.ways_of_exposure,
        potential_delayed_effects:
          state.pageReducer.sdsInfo.potential_delayed_effects,
        potential_immediate_effects:
          state.pageReducer.sdsInfo.potential_immediate_effects,
        potential_chronic_health_effects:
          state.pageReducer.sdsInfo.potential_chronic_health_effects,
        acute_toxicity_structured_data:
          state.pageReducer.sdsInfo.acute_toxicity_structured_data,
        skin_corrosion_structured_data:
          state.pageReducer.sdsInfo.skin_corrosion_structured_data,
        eye_damage_structured_data:
          state.pageReducer.sdsInfo.eye_damage_structured_data,
        carcinogenicity_structured_data:
          state.pageReducer.sdsInfo.carcinogenicity_structured_data,
        reproductive_toxicity_structured_data:
          state.pageReducer.sdsInfo.reproductive_toxicity_structured_data,
        mutagenicity_structured_data:
          state.pageReducer.sdsInfo.mutagenicity_structured_data,
        sensitization_structured_data:
          state.pageReducer.sdsInfo.sensitization_structured_data,
        teratogenicity_structured_data:
          state.pageReducer.sdsInfo.teratogenicity_structured_data,
        organ_toxicity_single_exposure_structured_data:
          state.pageReducer.sdsInfo
            .organ_toxicity_single_exposure_structured_data,
        organ_toxicity_repeated_exposure_structured_data:
          state.pageReducer.sdsInfo
            .organ_toxicity_repeated_exposure_structured_data,
        endocrine_disrupting_properties:
          state.pageReducer.sdsInfo.endocrine_disrupting_properties,
        toxic_other_information:
          state.pageReducer.sdsInfo.toxic_other_information,
      },
      components: state.form.ingredientsForm?.values?.ingredients
        ? state.form.ingredientsForm?.values?.ingredients
        : state.pageReducer?.sdsInfo?.components
        ? state.pageReducer?.sdsInfo?.components
        : [],
      doNotIncludeStructuredDataFromComponents:
        state.pageReducer.doNotIncludeStructuredDataFromComponents,
      componentStructuredData: state.pageReducer.structuredComponentData,
    };
  }
  return {
    components: state.form.ingredientsForm?.values?.ingredients
      ? state.form.ingredientsForm?.values?.ingredients
      : state.pageReducer?.sdsInfo?.components
      ? state.pageReducer?.sdsInfo?.components
      : [],
    doNotIncludeStructuredDataFromComponents:
      state.pageReducer.doNotIncludeStructuredDataFromComponents,
    componentStructuredData: state.pageReducer.structuredComponentData,
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, ToxicologicalFormProps>({
    form: 'toxicologicalForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(ToxicologicalForm)
);
