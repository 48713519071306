import { Checkbox, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import DataTable from '../data-table';

import InputField from '../input-field';
import OutlinedSection from '../outlined-section';
import useBaseStyles from '../styles';
import React, { useState } from 'react';

interface ComponentEcologicalDataProps {
  htmlSection?: any;
}
const ComponentEcologicalData = (
  props: ComponentEcologicalDataProps &
    InjectedFormProps<{}, ComponentEcologicalDataProps>
) => {
  const classes = useBaseStyles();
  const { t } = useTranslation(['create_component_structured_data']);
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const { htmlSection } = props;
  const [showSectionData, setShowSectionData] = useState(false);

  return (
    <form className={classes.form}>
      <OutlinedSection
        title={translations.headers['12_header_ecological_info']}
        variant="h5"
      >
        <div style={{}}>
          <div className={classes.checkboxWrapper}>
            <Checkbox
              color={'primary'}
              onClick={() => setShowSectionData(!showSectionData)}
              checked={showSectionData}
            />
            <Typography>{t('sds_authoring:show_imported_sds_data')}</Typography>
          </div>
          {showSectionData && htmlSection && (
            <div
              className={classes.reviewDataRightSideContent}
              dangerouslySetInnerHTML={{
                __html: htmlSection,
              }}
            />
          )}
        </div>
        <Typography variant="h6">
          {translations.headers['12_1_header_toxicity']}
        </Typography>
        <Field
          name="toxicity_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="toxicity"
          formName="toxicity"
          component={DataTable}
          translations={translations}
          fieldsNames={[
            'toxicity_effect',
            'endpoint',
            'time',
            'value',
            'unit',
            'organism',
            'test_method',
            'notes',
            'action',
          ]}
          fieldsStyles={{
            toxicity_effect: { width: '15%' },
            endpoint: { width: '10%' },
            time: { width: '10%' },
            value: { width: '10%' },
            unit: { width: '10%' },
            organism: { width: '15%' },
            test_method: { width: '15%' },
            notes: { width: '15%' },
          }}
          fieldsProps={{
            endpoint: {
              type: 'select',
              options: [
                {
                  value: 'lc50',
                  label: 'LC50',
                },
                {
                  value: 'ec50',
                  label: 'EC50',
                },
              ],
            },
            toxicity_effect: {
              type: 'select',
              options: [
                {
                  value: 'toxicity_to_fish',
                  label: translations.layout.toxicity_to_fish,
                },
                {
                  value: 'toxicity_to_daphnia',
                  label: translations.layout.toxicity_to_daphnia,
                },
                {
                  value: 'toxicity_to_algae',
                  label: translations.layout.toxicity_to_algae,
                },
                {
                  value: 'toxicity_to_bacteria',
                  label: translations.layout.toxicity_to_bacteria,
                },
              ],
            },
          }}
        />
        <Typography variant="h6">
          {translations.headers['12_2_header_persistence_and_degradability']}
        </Typography>
        <Field
          name="biodegradability_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="biodegradability"
          formName="biodegradability"
          component={DataTable}
          translations={translations}
          fieldsNames={[
            'hydrolysis_half_life',
            'photolysis',
            'biodegradation',
            'comment',
            'action',
          ]}
          fieldsStyles={{
            hydrolysis_half_life: { width: '25%' },
            photolysis: { width: '25%' },
            biodegradation: { width: '25%' },
            comment: { width: '25%' },
          }}
        />
        <Typography variant="h6">
          {translations.headers['12_3_header_bioaccumulative_potential']}
        </Typography>
        <Field
          name="bioaccumulative_potential_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="bioaccumulative_potential"
          formName="bioaccumulative_potential"
          component={DataTable}
          translations={translations}
          fieldsNames={['kow', 'bcf', 'potential', 'comment', 'action']}
          fieldsStyles={{
            kow: { width: '25%' },
            bcf: { width: '25%' },
            potential: { width: '25%' },
            comment: { width: '25%' },
          }}
        />
      </OutlinedSection>
    </form>
  );
};

const mapStateToProps = (state: any) => {
  return {
    htmlSection: state.pageReducer.componentDetails?.html_sections[12],
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'componentStructuredData',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    enableReinitialize: false,
  })(ComponentEcologicalData)
);
