import React from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components';
import axiosAuthoringInstance from 'api/constants';
import useStyles from './styles';

const ExternalLoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const urlParams = queryString.parse(window.location.search);
  React.useEffect(() => {
    axiosAuthoringInstance.defaults.headers['Authorization'] =
      'JWT ' + urlParams.access;
    localStorage.setItem('access_token', urlParams.access);
    localStorage.setItem('refresh_token', urlParams.refresh);
    history.push('/');
  }, []);
  return (
    <div className={classes.root}>
      <Loader />
    </div>
  );
};

export default ExternalLoginPage;
