import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { LanguageProvider } from './pages/LanguageProvider';
import i18next, { languages } from './utils/i18n';
import { get } from './api';
import {
  setDefaultSupplierInfo,
  setLanguageCode,
  setTranslations,
  setUserInfo,
} from './reducers/pageAction';
import LoginPageV2 from './pages/login';
import SDSAuthoringPage from './pages/sds-authoring';
import Layout from 'components/layout';
import OurSDS from 'pages/our-sds';
import OurComponents from 'pages/our-components';
import ComponentStructuredDataPage from 'pages/component-structured-data';
import { ExternalLoginPage, PDFDownloadViewerPage } from './pages';
import OriginalSDSViewerPage from './pages/original-sds-viewer/OriginalSDSViewerPage';
import PermissionsDeniedPage from './pages/permissions-denied/PermissionsDeniedPage';
import { isForWorkplaceSafety } from 'utils/helper';

function RoutesV2() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.pageReducer.userInfo);
  const location = window.location.pathname;
  const languageCode = localStorage.getItem('languageCode');
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const [authenticating, setAuthenticating] = React.useState<boolean>(true);

  const getTranslations = () => {
    const isAuthenticationPage = [`login/`, `/login/`].includes(location);
    if (!isAuthenticationPage) {
      const getTranslationsRequest = get('/sdsAuthoring/utils/translations', {
        language_code: languageCode,
      });
      getTranslationsRequest.then((response) => {
        if (response?.status === 200) {
          dispatch(setTranslations(response.data));
        }
      });
    }
  };

  const fetchUser = () => {
    setAuthenticating(true);
    const isAuthenticationPage = [`login/`, `/login/`].includes(location);

    if (!isAuthenticationPage) {
      const getUserRequest = get('/user/', {});
      getUserRequest.then((response) => {
        if (response.status === 200) {
          const userData = response.data;
          dispatch(setUserInfo(userData));
          dispatch(
            setDefaultSupplierInfo(
              userData.customer?.authoring_supplier_settings
            )
          );
          let setLang = languageCode
            ? languageCode
            : languages.includes(userData.language_code)
            ? userData.language_code
            : 'en';
          dispatch(setLanguageCode(setLang));
          if (i18next.language !== setLang) {
            i18next.changeLanguage(setLang);
          }
        }
      });
    }
  };

  const updateHeadElement = () => {
    const headElement=document.getElementsByTagName('head')[0];
       const linkIcon = document.createElement('link');
       const title = document.createElement('title');
       linkIcon.rel = "icon";
      if (isForWorkplaceSafety()) {
        linkIcon.href="/favicon-2.png";
        title.innerText = 'SDS creator'
      } 
      else {
        linkIcon.href="/favicon-1.png";
        title.innerText = 'SDS Authoring'
      }
      if (headElement) {
        headElement.appendChild(linkIcon);
        headElement.appendChild(title);
      }
  }

  React.useEffect(() => {
    updateHeadElement();
    fetchUser();
    if (!translations) {
      getTranslations();
    }
  }, []);

  const page = (
    <Switch>
      <Route exact path="/" component={OurSDS} />
      <Route exact path="/login" component={LoginPageV2} />
      <Route exact path="/sds-authoring" component={SDSAuthoringPage} />
      <Route exact path="/sds-authoring/:sdsId" component={SDSAuthoringPage} />
      <Route exact path="/our-components" component={OurComponents} />
      <Route
        exact
        path="/our-components/:id"
        component={ComponentStructuredDataPage}
      />
      <Route exact path="/pdf/:id" component={PDFDownloadViewerPage} />
      <Route exact path="/public-view/:token">
        <OriginalSDSViewerPage />
      </Route>
      <Route
        exact
        path="/permissions-denied/"
        component={PermissionsDeniedPage}
      />
      <Route exact path="/external-login/" component={ExternalLoginPage} />
    </Switch>
  );

  return (
    <LanguageProvider
      language={
        languageCode
          ? languageCode
          : languages.includes(userInfo?.language_code)
          ? userInfo?.language_code
          : 'en'
      }
    >
      <Router>
        <Layout user={userInfo}>{page}</Layout>
      </Router>
    </LanguageProvider>
  );
}

export default RoutesV2;
