import React from 'react';

import { Box, Checkbox, Typography } from '@mui/material';
import TextFieldInputV2 from 'components/text-field';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  setStructuredDataEdited,
  setIsSubstanceSDS,
} from 'reducers/pageAction';
import {
  change,
  Field,
  FieldArray,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import useStyles from '../styles';
import Ingredients from './Ingredients';
import SubmitButton from '../submit-btn';
import { CustomFormProps } from '../utils';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { ConfirmActionPopup } from 'components';
import SaveAsButton from 'components/save-as-btn';

interface IngredientsFormProps extends CustomFormProps {
  sdsLanguage: any;
  initialComponents: any;
  sdsInfo: any;
  productFormValues: any;
  sdsId: number;
}

const IngredientsForm = (
  props: IngredientsFormProps & InjectedFormProps<{}, IngredientsFormProps>
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['ingredientsForm']);
  const [showConfirmPopup, setShowConfirmPopup] =
    React.useState<boolean>(false);
  const [switchFormTo, setSwitchFormTo] = React.useState<string | null>(null);

  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const isSubstanceSDS = useSelector(
    (state: any) => state.pageReducer.isSubstanceSDS
  );
  const { handleNext, dirty, initialComponents, sdsInfo, productFormValues, handleCopy } =
    props;
  const form = useSelector((state: any) => state.form.ingredientsForm);
  const hazardCodes = useSelector((state: any) =>
    state.form.hazardsCodesForm?.values?.hazardCodes
      ? state.form.hazardsCodesForm?.values?.hazardCodes
      : state.pageReducer.sdsInfo?.hazards_information?.codes
  );
  const structuredData = useSelector(
    (state: any) => state.pageReducer.structuredComponentData
  );
  const structuredDataEdited = useSelector(
    (state: any) => state.pageReducer.structuredDataEdited
  );
  const doNotIncludeStructuredDataFromComponents = useSelector(
    (state: any) => state.pageReducer.doNotIncludeStructuredDataFromComponents
  );

  const handleChangeSDSType = (isSDSForSubstance: boolean) => {
    if (
      isSDSForSubstance === isSubstanceSDS &&
      initialComponents?.length >= 1
    ) {
      return;
    }
    if (isSDSForSubstance) {
      const hazardAbbreviation = getHazardAbbreviationsForSubstanceComponent();
      dispatch(
        change('ingredientsForm', 'ingredients', [
          {
            component_name: sdsInfo
              ? sdsInfo.product_name
              : productFormValues?.product_name,
            ec_no: '',
            cas_no: '',
            reach_no: '',
            hazard_abbreviation: hazardAbbreviation,
            concentration: '100%',
            hash: uuidv4(),
            manuallyCreated: true,
          },
        ])
      );
    }
    if (!isSDSForSubstance) {
      dispatch(change('ingredientsForm', 'ingredients', []));
    }
    dispatch(setIsSubstanceSDS(isSDSForSubstance));
  };

  const getHazardAbbreviationsForSubstanceComponent = () => {
    let hazardAbbreviation = '';
    if (hazardCodes) {
      hazardCodes.map(
        (el: any) =>
          (hazardAbbreviation += `${el.hazard_category_abbreviation}; ${el.statement_code}\n`)
      );
    }
    return hazardAbbreviation;
  };

  React.useEffect(() => {
    if (isSubstanceSDS && initialComponents?.length === 0) {
      const hazardAbbreviation = getHazardAbbreviationsForSubstanceComponent();
      dispatch(
        change('ingredientsForm', 'ingredients', [
          {
            component_name: sdsInfo
              ? sdsInfo.product_name
              : productFormValues?.product_name,
            ec_no: '',
            cas_no: '',
            reach_no: '',
            hazard_abbreviation: hazardAbbreviation,
            concentration: '100%',
            hash: uuidv4(),
            manuallyCreated: true,
          },
        ])
      );
    }
  }, []);

  const handleSubmit = () => {
    if (
      dirty ||
      structuredDataEdited ||
      sdsInfo?.is_substance_sds !== isSubstanceSDS
    ) {
      const components = form?.values?.ingredients
        ? form?.values?.ingredients.map((el: any) => {
            return el;
          })
        : sdsInfo?.components?.map((el: any) => {
            return el;
          });
      components.forEach((component: any) => {
        const identifier = component.id ? component.id : component.hash;
        component['structured_data'] =
          doNotIncludeStructuredDataFromComponents.includes(identifier)
            ? null
            : structuredData[identifier];
      });
      dispatch(setStructuredDataEdited(false));
      const sdsData = {
        components_description: form?.values?.components_description,
        components_input: components,
      };
      handleNext(sdsData);
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5" mb={2}>
        {translations.headers['3_header_composition']}
      </Typography>
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <div className={classes.checkboxWrapper}>
          <Checkbox
            color={'primary'}
            onClick={() => {
              setSwitchFormTo('substance');
              setShowConfirmPopup(true);
            }}
            checked={isSubstanceSDS}
          />
          <Typography>{t('ingredientsForm:section3_1')}</Typography>
        </div>
        <div className={classes.checkboxWrapper}>
          <Checkbox
            color={'primary'}
            onClick={() => {
              setSwitchFormTo('ingredients');
              setShowConfirmPopup(true);
            }}
            checked={!isSubstanceSDS}
          />
          <Typography>{t('ingredientsForm:section3_2')}</Typography>
        </div>
      </Box>
      <form onSubmit={handleSubmit}>
        {isSubstanceSDS ? null : (
          <Field
            name="components_description"
            component={TextFieldInputV2}
            classes={{ root: classes.textInput }}
            multiline={true}
          />
        )}
        <FieldArray
          name="ingredients"
          languageCode={props.sdsLanguage}
          component={Ingredients}
          isSubstanceSDS={isSubstanceSDS}
        />
        <SubmitButton handleSubmit={handleSubmit} />
        <SaveAsButton handleSubmit={handleCopy} />

      </form>
      {switchFormTo && showConfirmPopup && (
        <ConfirmActionPopup
          title={t('ingredientsForm:confirm_switch')}
          open={showConfirmPopup}
          onClose={() => setShowConfirmPopup(false)}
          onSubmit={() => {
            if (switchFormTo === 'substance') {
              handleChangeSDSType(true);
            } else {
              handleChangeSDSType(false);
            }
            setSwitchFormTo(null);
            setShowConfirmPopup(false);
          }}
        />
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  const initialComponents = state.pageReducer.sdsInfo?.components
    ? state.pageReducer.sdsInfo.components
    : [];

  if (initialComponents && !state.form.ingredientsForm?.values) {
    return {
      initialValues: {
        components_description:
          state.pageReducer.sdsInfo?.components_description,
        ingredients: initialComponents,
      },
      initialComponents: initialComponents,
      sdsLanguage: state.form.section0Form?.values?.language,
      sdsId: state.pageReducer.pdfId,
      sdsInfo: state.pageReducer.sdsInfo,
      productFormValues: state.form.productDescriptionForm?.values,
    };
  }
  return {
    sdsLanguage: state.form.section0Form?.values?.language,
    sdsId: state.pageReducer.pdfId,
    sdsInfo: state.pageReducer.sdsInfo,
    productFormValues: state.form.productDescriptionForm?.values,
    initialComponents: initialComponents,
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, IngredientsFormProps>({
    form: 'ingredientsForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(IngredientsForm)
);
