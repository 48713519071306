import { Add, AddCircle } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import useStyles from '../../styles';
import HazardCodeTable from '../hazard-table';

import { Box, Button, IconButton, Typography } from '@mui/material';
import SelectPrecautionsCodesPopup from 'components/popups/SelectPrecautionsCodesPopup';

const PrecautionsCodes = ({
  fields,
  statements,
}: {
  fields: any;
  statements: any;
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['sds_authoring']);
  const [openAddPopup, setOpenAddPopup] = React.useState(false);

  const handleSelectCodes = (statementIds: any) => {
    statementIds.map((id: any) => {
      const element = statements.find((el: any) => el.id === id);
      if (element) {
        fields.push({
          id: element.id,
          pictogram_url: element.pictogram_url,
          statement_code: element.statement_code,
          statements: element.statements,
          hazard_class: element.hazard_class,
          category: element.category,
          hazard_category_abbreviation: element.hazard_category_abbreviation,
          pictogram_code: element.pictogram_code,
          signal_word: element.signal_word,
          prevention: element.prevention,
          response: element.response,
          storage: element.storage,
          disposal: element.disposal,
          precautions_statements: element.precautions_statements,
        });
      }
    });
  };
  const selectHazardCodesProps = {
    open: openAddPopup,
    choices: statements,
    onSubmit: (selectedItems: any) => {
      handleSelectCodes(selectedItems);
      setOpenAddPopup(false);
    },
    onClose: () => setOpenAddPopup(false),
    defaultSelectedCodes: fields.map((el: any) => el.statement_code),
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 500 }}>{t('sds_authoring:p_codes')}</Typography>
        <IconButton color="primary" onClick={() => setOpenAddPopup(true)}>
          <AddCircle />
        </IconButton>
      </Box>

      {fields.getAll() && !!fields.getAll()?.length && (
        <HazardCodeTable pCodes fields={fields} />
      )}
      {openAddPopup && (
        <SelectPrecautionsCodesPopup
          {...selectHazardCodesProps}
        />
      )}
    </div>
  );
};

export default PrecautionsCodes;
