import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme?: any) => ({
    contentWrapper: {
        position: 'relative',
        padding: 20,
    },
    title: {
        position: 'relative',
        top: 10,
        left: 16,
        zIndex: 2,
        padding: '0px 6px', 
        background: '#fff',
        display: 'inline',
    }
}))

export default useStyles;
