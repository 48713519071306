import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: '5px 20px',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #CCCCCC',
    borderRadius: 4,
    paddingLeft: '18px',
    [theme.breakpoints.down('xs')]: {
      padding: '6px 10px',
    },
  },
  searchInput: {
    fontSize: '0.9rem',
    backgroundColor: 'faff',
    border: 'none',
    width: '100%',
    fontFamily: 'Montserrat, sans-serif',
    '&:focus': {
      outline: 'none',
    },
    '&:before, &:after': {
      border: '0 !important',
    },
  },
  clearTextButton: {
    marginRight: -20,
    [theme.breakpoints.down('xs')]: {
      marginRight: -10,
    },
  },
}));

export default useStyles;
