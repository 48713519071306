import { BASE_API_URL } from './constants';
import axiosAuthoringInstance from './constants';
import axios from 'axios';

const loginUser = (email, password) => {
  return axios
    .post(`${BASE_API_URL}/auth/login/`, {
      email: email,
      password: password,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const createUser = (data) => {
  return axios
    .post(`${BASE_API_URL}/auth/signUp/`, {
      email: data.email,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
      company_position: data.companyPosition,
      company: data.company,
      country: data.country,
      available_services: data.availableServices,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};


const importSDS = (sdsId) => {
  return axiosAuthoringInstance
    .post('/sdsAuthoring/utils/import/', { sds_id: sdsId })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export { createUser, loginUser, importSDS };
