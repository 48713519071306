import { ComponentsStructuredDataPreview } from 'components/sds-authoring-forms-v2/structured-data-preview';
import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { Field, FieldArray } from 'redux-form';
import { getStructuredDataForFields } from 'utils/structuredDataPreviewPreparation';
import useStyles from '../../styles';
import { useSelector } from 'react-redux';
import DataTable from '../../../create-component-structured-data/components/data-table';
import React from 'react';

const InformationHazardClassesFields = ({
  componentStructuredData,
  components,
  doNotIncludeStructuredDataFromComponents,
}: {
  componentStructuredData: any;
  components: any;
  doNotIncludeStructuredDataFromComponents: any;
}) => {
  const { t } = useTranslation(['fields']);
  const classes = useStyles();
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const structuredDataByComponent: any = getStructuredDataForFields(
    componentStructuredData,
    components.filter(
      (el: any) => !doNotIncludeStructuredDataFromComponents.includes(el.id)
    ),
    [
      'acute_toxicity',
      'skin_corrosion',
      'sensitization',
      'mutagenicity',
      'eye_damage',
      'carcinogenicity',
      'reproductive_toxicity',
      'teratogenicity',
    ]
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        marginTop: 20,
      }}
    >
      <Field
        name="acute_toxicity"
        component={TextFieldInputV2}
        label={
          translations.sections.acute_toxicity
            ? translations.sections.acute_toxicity
            : t('fields:acute_toxicity')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <FieldArray
        name="acute_toxicity_structured_data"
        formName="acute_toxicity_structured_data"
        translations={translations}
        component={DataTable}
        fieldsNames={['result', 'species', 'dose', 'exposure', 'action']}
        fieldsStyles={{
          result: { width: '40%' },
          species: { width: '20%' },
          dose: { width: '20%' },
          exposure: { width: '20%' },
        }}
      />
      {structuredDataByComponent.acute_toxicity?.length > 0 && (
        <ComponentsStructuredDataPreview
          fieldsNames={['result', 'species', 'dose', 'exposure']}
          fieldsStyles={{
            result: { width: '40%' },
            species: { width: '20%' },
            dose: { width: '20%' },
            exposure: { width: '20%' },
          }}
          structuredDataPerComponent={structuredDataByComponent.acute_toxicity}
          translations={translations}
        />
      )}
      <Field
        name="skin_corrosion"
        component={TextFieldInputV2}
        label={
          translations.sections.skin_corrosion
            ? translations.sections.skin_corrosion
            : t('fields:skin_corrosion')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <FieldArray
        name="skin_corrosion_structured_data"
        formName="skin_corrosion_structured_data"
        component={DataTable}
        translations={translations}
        fieldsNames={[
          'result',
          'species',
          'score',
          'exposure',
          'observation',
          'action',
        ]}
        fieldsStyles={{
          result: { width: '30%' },
          species: { width: '20%' },
          score: { width: '10%' },
          exposure: { width: '20%' },
          observation: { width: '20%' },
        }}
        fieldsProps={{
          result: {
            type: 'select',
            options: [
              {
                value: 'ld_oral',
                label: t('create_component_structured_data:ld_oral'),
              },
              {
                value: 'ld_dermal',
                label: t('create_component_structured_data:ld_dermal'),
              },
              {
                value: 'lc_inhalation',
                label: t('create_component_structured_data:lc_inhalation'),
              },
            ],
          },
        }}
      />
      {structuredDataByComponent.skin_corrosion?.length > 0 && (
        <ComponentsStructuredDataPreview
          fieldsNames={[
            'result',
            'species',
            'score',
            'exposure',
            'observation',
          ]}
          fieldsStyles={{
            result: { width: '30%' },
            species: { width: '20%' },
            score: { width: '10%' },
            exposure: { width: '20%' },
            observation: { width: '20%' },
          }}
          structuredDataPerComponent={structuredDataByComponent.skin_corrosion}
          translations={translations}
        />
      )}
      <Field
        name="eye_damage"
        component={TextFieldInputV2}
        label={
          translations.sections.eye_damage
            ? translations.sections.eye_damage
            : t('fields:eye_damage')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <FieldArray
        name="eye_damage_structured_data"
        formName="eye_damage_structured_data"
        component={DataTable}
        translations={translations}
        fieldsNames={['route_of_exposure', 'species', 'result', 'action']}
        fieldsStyles={{
          route_of_exposure: { width: '25%' },
          species: { width: '35%' },
          result: { width: '40%' },
        }}
        fieldsProps={{
          route_of_exposure: {
            type: 'select',
            options: [
              {
                value: 'inhalation',
                label: t('create_component_structured_data:inhalation'),
              },
              {
                value: 'skin',
                label: t('create_component_structured_data:skin'),
              },
              {
                value: 'eyes',
                label: t('create_component_structured_data:eyes'),
              },
              {
                value: 'swallowing',
                label: t('create_component_structured_data:swallowing'),
              },
              {
                value: 'injection',
                label: t('create_component_structured_data:injection'),
              },
            ],
          },
        }}
      />
      {structuredDataByComponent.eye_damage?.length > 0 && (
        <ComponentsStructuredDataPreview
          fieldsNames={['route_of_exposure', 'species', 'result']}
          fieldsStyles={{
            route_of_exposure: { width: '25%' },
            species: { width: '35%' },
            result: { width: '40%' },
          }}
          structuredDataPerComponent={structuredDataByComponent.eye_damage}
          translations={translations}
        />
      )}
      <Field
        name="carcinogenicity"
        component={TextFieldInputV2}
        label={
          translations.sections.carcinogenicity
            ? translations.sections.carcinogenicity
            : t('fields:carcinogenicity')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <FieldArray
        name="carcinogenicity_structured_data"
        formName="carcinogenicity_structured_data"
        component={DataTable}
        translations={translations}
        fieldsNames={['result', 'species', 'dose', 'exposure', 'action']}
        fieldsStyles={{
          result: { width: '30%' },
          species: { width: '25%' },
          dose: { width: '20%' },
          exposure: { width: '25%' },
        }}
      />
      {structuredDataByComponent.carcinogenicity?.length > 0 && (
        <ComponentsStructuredDataPreview
          fieldsNames={['result', 'species', 'dose', 'exposure']}
          fieldsStyles={{
            result: { width: '30%' },
            species: { width: '25%' },
            dose: { width: '20%' },
            exposure: { width: '25%' },
          }}
          structuredDataPerComponent={structuredDataByComponent.carcinogenicity}
          translations={translations}
        />
      )}
      <Field
        name="reproductive_toxicity"
        component={TextFieldInputV2}
        label={
          translations.sections.reproductive_toxicity
            ? translations.sections.reproductive_toxicity
            : t('fields:reproductive_toxicity')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <FieldArray
        name="reproductive_toxicity_structured_data"
        formName="reproductive_toxicity_structured_data"
        component={DataTable}
        translations={translations}
        fieldsNames={[
          'maternal_toxicity',
          'fertility',
          'development_toxin',
          'species',
          'dose',
          'exposure',
          'action',
        ]}
        fieldsStyles={{
          maternal_toxicity: { width: '15%' },
          fertility: { width: '15%' },
          development_toxin: { width: '15%' },
          species: { width: '25%' },
          dose: { width: '15%' },
          exposure: { width: '15%' },
        }}
      />
      {structuredDataByComponent.reproductive_toxicity?.length > 0 && (
        <ComponentsStructuredDataPreview
          fieldsNames={['result', 'species', 'dose', 'exposure']}
          fieldsStyles={{
            result: { width: '30%' },
            species: { width: '25%' },
            dose: { width: '20%' },
            exposure: { width: '25%' },
          }}
          structuredDataPerComponent={
            structuredDataByComponent.reproductive_toxicity
          }
          translations={translations}
        />
      )}
      <Field
        name="mutagenicity"
        component={TextFieldInputV2}
        label={
          translations.sections.mutagenicity
            ? translations.sections.mutagenicity
            : t('fields:mutagenicity')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <FieldArray
        name="mutagenicity_structured_data"
        formName="mutagenicity_structured_data"
        component={DataTable}
        translations={translations}
        fieldsNames={['test', 'experiment', 'result', 'action']}
        fieldsStyles={{
          test: { width: '30%' },
          experiment: { width: '40%' },
          result: { width: '30%' },
        }}
      />
      {structuredDataByComponent.mutagenicity?.length > 0 && (
        <ComponentsStructuredDataPreview
          fieldsNames={['test', 'experiment', 'result']}
          fieldsStyles={{
            test: { width: '30%' },
            experiment: { width: '40%' },
            result: { width: '30%' },
          }}
          structuredDataPerComponent={structuredDataByComponent.mutagenicity}
          translations={translations}
        />
      )}
      <Field
        name="sensitization"
        component={TextFieldInputV2}
        label={
          translations.sections.sensitization
            ? translations.sections.sensitization
            : t('fields:sensitization')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <FieldArray
        name="sensitization_structured_data"
        formName="sensitization_structured_data"
        component={DataTable}
        translations={translations}
        fieldsNames={['route_of_exposure', 'species', 'result', 'action']}
        fieldsStyles={{
          route_of_exposure: { width: '25%' },
          species: { width: '35%' },
          result: { width: '40%' },
        }}
        fieldsProps={{
          route_of_exposure: {
            type: 'select',
            options: [
              {
                value: 'inhalation',
                label: t('create_component_structured_data:inhalation'),
              },
              {
                value: 'skin',
                label: t('create_component_structured_data:skin'),
              },
              {
                value: 'eyes',
                label: t('create_component_structured_data:eyes'),
              },
              {
                value: 'swallowing',
                label: t('create_component_structured_data:swallowing'),
              },
              {
                value: 'injection',
                label: t('create_component_structured_data:injection'),
              },
            ],
          },
        }}
      />
      {structuredDataByComponent.sensitization?.length > 0 && (
        <ComponentsStructuredDataPreview
          fieldsNames={['route_of_exposure', 'species', 'result']}
          fieldsStyles={{
            route_of_exposure: { width: '25%' },
            species: { width: '35%' },
            result: { width: '40%' },
          }}
          structuredDataPerComponent={structuredDataByComponent.sensitization}
          translations={translations}
        />
      )}
      <Field
        name="teratogenicity"
        component={TextFieldInputV2}
        label={
          translations.sections.teratogenicity
            ? translations.sections.teratogenicity
            : t('fields:teratogenicity')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <FieldArray
        name="teratogenicity_structured_data"
        formName="teratogenicity_structured_data"
        component={DataTable}
        translations={translations}
        fieldsNames={['result', 'species', 'dose', 'exposure', 'action']}
        fieldsStyles={{
          result: { width: '30%' },
          species: { width: '25%' },
          dose: { width: '20%' },
          exposure: { width: '25%' },
        }}
      />
      {structuredDataByComponent.teratogenicity?.length > 0 && (
        <ComponentsStructuredDataPreview
          fieldsNames={['result', 'species', 'dose', 'exposure']}
          fieldsStyles={{
            result: { width: '30%' },
            species: { width: '25%' },
            dose: { width: '20%' },
            exposure: { width: '25%' },
          }}
          structuredDataPerComponent={structuredDataByComponent.teratogenicity}
          translations={translations}
        />
      )}
      <Field
        name="organ_toxicity_single_exposure"
        component={TextFieldInputV2}
        label={
          translations.sections.organ_toxicity_single_exposure
            ? translations.sections.organ_toxicity_single_exposure
            : t('fields:organ_toxicity_single_exposure')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <FieldArray
        name="organ_toxicity_single_exposure_structured_data"
        formName="organ_toxicity_single_exposure_structured_data"
        component={DataTable}
        translations={translations}
        fieldsNames={[
          'category',
          'route_of_exposure',
          'target_organs',
          'action',
        ]}
        fieldsStyles={{
          category: { width: '35%' },
          route_of_exposure: { width: '35%' },
          target_organs: { width: '30%' },
        }}
        fieldsProps={{
          route_of_exposure: {
            type: 'select',
            options: [
              {
                value: 'inhalation',
                label: t('create_component_structured_data:inhalation'),
              },
              {
                value: 'skin',
                label: t('create_component_structured_data:skin'),
              },
              {
                value: 'eyes',
                label: t('create_component_structured_data:eyes'),
              },
              {
                value: 'swallowing',
                label: t('create_component_structured_data:swallowing'),
              },
              {
                value: 'injection',
                label: t('create_component_structured_data:injection'),
              },
            ],
          },
          category: {
            type: 'select',
            options: [
              {
                value: 'category_1',
                label: `${translations.layout.category} 1`,
              },
              {
                value: 'category_2',
                label: `${translations.layout.category} 2`,
              },
              {
                value: 'category_3',
                label: `${translations.layout.category} 3`,
              },
            ],
          },
        }}
      />
      <Field
        name="organ_toxicity_repeated_exposure"
        component={TextFieldInputV2}
        label={
          translations.sections.organ_toxicity_repeated_exposure
            ? translations.sections.organ_toxicity_repeated_exposure
            : t('fields:organ_toxicity_repeated_exposure')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <FieldArray
        name="organ_toxicity_repeated_exposure_structured_data"
        formName="organ_toxicity_repeated_exposure_structured_data"
        component={DataTable}
        translations={translations}
        fieldsNames={[
          'category',
          'route_of_exposure',
          'target_organs',
          'action',
        ]}
        fieldsStyles={{
          category: { width: '35%' },
          route_of_exposure: { width: '35%' },
          target_organs: { width: '30%' },
        }}
        fieldsProps={{
          route_of_exposure: {
            type: 'select',
            options: [
              {
                value: 'inhalation',
                label: t('create_component_structured_data:inhalation'),
              },
              {
                value: 'skin',
                label: t('create_component_structured_data:skin'),
              },
              {
                value: 'eyes',
                label: t('create_component_structured_data:eyes'),
              },
              {
                value: 'swallowing',
                label: t('create_component_structured_data:swallowing'),
              },
              {
                value: 'injection',
                label: t('create_component_structured_data:injection'),
              },
            ],
          },
          category: {
            type: 'select',
            options: [
              {
                value: 'category_1',
                label: `${translations.layout.category} 1`,
              },
              {
                value: 'category_2',
                label: `${translations.layout.category} 2`,
              },
              {
                value: 'category_3',
                label: `${translations.layout.category} 3`,
              },
            ],
          },
        }}
      />
      <Field
        name="ways_of_exposure"
        component={TextFieldInputV2}
        label={
          translations.sections.ways_of_exposure
            ? translations.sections.ways_of_exposure
            : t('fields:ways_of_exposure')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <Field
        name="potential_delayed_effects"
        component={TextFieldInputV2}
        label={
          translations.sections.potential_delayed_effects
            ? translations.sections.potential_delayed_effects
            : t('fields:potential_delayed_effects')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <Field
        name="potential_immediate_effects"
        component={TextFieldInputV2}
        label={
          translations.sections.potential_immediate_effects
            ? translations.sections.potential_immediate_effects
            : t('fields:potential_immediate_effects')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <Field
        name="potential_chronic_health_effects"
        component={TextFieldInputV2}
        label={
          translations.sections.potential_chronic_health_effects
            ? translations.sections.potential_chronic_health_effects
            : t('fields:potential_chronic_health_effects')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
    </div>
  );
};

export default InformationHazardClassesFields;
