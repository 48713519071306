import React, { useRef } from 'react';
import { IconButton, Badge, Tooltip } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import { renderSnackbar } from 'utils';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';

interface UploadImageProps {
  onUpload: any;
  size?: number;
  imageUrl: string;
  component: React.FC<any>;
  checkSize?: boolean;
  disabled?: boolean;
  tooltip?: string
}
const MAX_SIZE_IMAGE = 2097152; //2MB

const UploadImage = ({
  onUpload,
  size = 70,
  imageUrl,
  component,
  checkSize = true,
  disabled = false,
  tooltip = ""
}: UploadImageProps) => {
  const imageRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(['upload_image']);
  const IconComponent = component;
  return (
    <>
      <Tooltip title={tooltip}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <IconButton
              style={{
                color: '#000',
                backgroundColor: '#fff',
                borderRadius: 50,
                padding: 3,
              }}
              disabled={disabled}
            >
              <FileUpload />
            </IconButton>
          }
          onClick={() => {
            imageRef.current?.click();
          }}
        >
          {imageUrl ? (
            <Avatar
              style={{
                height: size,
                width: size,
                border: '1px solid gray',
              }}
              imgProps={{
                sx: {
                  objectFit: 'contain',
                },
              }}
              src={imageUrl}
            />
          ) : (
            <IconComponent style={{ width: size, height: size }} />
          )}
        </Badge>
      </Tooltip>
      <input
        type="file"
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg, image/gif"
        ref={imageRef}
        onChange={(e: any) => {
          const newImageFile = e.target.files[0];
          if (!newImageFile) return;
          if (
            ['image/png', 'image/jpeg', 'image/gif'].indexOf(
              newImageFile.type
            ) === -1
          ) {
            renderSnackbar([t('upload_image:file_not_suitable')]);
            return;
          }
          if (checkSize && newImageFile.size > MAX_SIZE_IMAGE) {
            renderSnackbar([t('upload_image:file_too_big')]);
            return;
          }
          onUpload(newImageFile);
        }}
        hidden
      />
    </>
  );
};
export default UploadImage;
