import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { get, post } from 'api';
import { ConfirmActionPopup, Loader } from 'components';
import DesktopDialog from 'components/dialog/Dialog';
import SearchBar from 'components/search-bar/SearchBar';
import { renderSnackbar } from 'utils';

import { PictureAsPdfRounded } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { availableLanguages } from '../../constants';
import useStyles from './importSDSPopupStyles';
import { PopupProps } from './PopupsInterface';
import { isValidNumber } from 'utils/helper';

const AddComponentToOurListPopup = ({
  open,
  onClose,
  onSubmit,
}: PopupProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['sds_authoring', 'popups', 'actions']);
  const languageCode = useSelector(
    (state: any) => state.pageReducer.languageCode
  );
  const [selectedLanguage, setSelectedLanguage] = React.useState(languageCode);
  const [loadingId, setLoadingId] = React.useState(null);
  const [errorText, setErrorText] = React.useState(null);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState('');
  const [supplierSearchValue, setSupplierSearchValue] = React.useState('');
  const [showNoDataMessage, setShowNoDataMessage] = React.useState(false);
  const [openConfirmAddComponentPopup, setOpenConfirmAddComponentPopup] =
    React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [componentsPaginatedData, setComponentsPaginatedData] = React.useState({
    results: [],
    count: 10,
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleLanguageChange = (event: SelectChangeEvent<any>) => {
    setSelectedLanguage(event.target.value);
  };

  const handleAddComponent = (
    sdsId: any,
    cas_no: any,
    forceAddSameCasNo: any
  ) => {
    setLoadingId(sdsId);
    const addComponent = post('/sdsAuthoring/customerComponents/', {
      component_sds: sdsId,
      cas_no: cas_no,
      force_add: forceAddSameCasNo,
    });
    addComponent.then((response) => {
      if (response?.status === 201) {
        setLoadingId(null);
        setSelectedRow(null);
        onSubmit();
        onClose();
      } else if (
        response.data?.error_code ===
        'COMPONENT_WITH_SAME_CAS_NO_ALREADY_EXISTS'
      ) {
        setSelectedRow({
          component_sds: sdsId,
          cas_no: cas_no,
          force_add: forceAddSameCasNo,
        });
        setOpenConfirmAddComponentPopup(true);
      } else {
        renderSnackbar([t('popups:import_failed')]);
      }
    });
  };
  const handleSearchComponents = (
    page: number,
    rows: number,
    search: string,
    supplierSearch?: string
  ) => {
    if (search || supplierSearch) {
      setLoadingId(null);
      if (!searchValue || searchValue.length < 3) {
        setComponentsPaginatedData({
          results: [],
          count: 100,
        });
        setErrorText(t('sds_authoring:search_min_length'));
        return;
      }
      setLoading(true);
      const getComponentsRequest = get(`/sdsAuthoring/utils/components`, {
        search: search,
        supplier_search: supplierSearch,
        page: page + 1,
        page_size: rows,
        language_code: selectedLanguage,
        search_by_id: isValidNumber(search) ? true : '',
      });

      getComponentsRequest.then((response) => {
        if (response.status === 200) {
          setComponentsPaginatedData(response.data);
          if (response.data.results.length === 0) {
            setShowNoDataMessage(true);
          }
          setLoading(false);
        }
      });
    }
  };

  React.useEffect(() => {
    handleSearchComponents(page, rowsPerPage, searchValue);
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }
    handleSearchComponents(page, rowsPerPage, searchValue);
  }, [page, rowsPerPage]);

  const displayError = () => {
    return <div style={{ marginTop: '20px' }}>{errorText}</div>;
  };

  if (!open) {
    return null;
  }

  return (
    <DesktopDialog
      onClose={() => {
        onClose();
      }}
      maxWidth={'lg'}
      classes={{ paper: classes.dialog }}
      title={t('popups:search_for_components')}
      fullWidth={true}
    >
      <Box sx={{ display: 'flex', gap: '20px', mb: '20px' }}>
        <SearchBar
          id="location-management-add-substance-search-input"
          placeHolder={t('popups:filter_by')}
          onSearch={(text) => {
            handleSearchComponents(0, rowsPerPage, text, supplierSearchValue);
          }}
          onChange={(value) => setSearchValue(value)}
        />
        {/* <SearchBar
          id="location-management-add-substance-search-supplier-input"
          placeHolder={'Supplier search'}
          onSearch={(text) => {
            handleSearchComponents(0, rowsPerPage, searchValue, text);
          }}
          onChange={(value) => setSupplierSearchValue(value)}
        /> */}
        <FormControl variant="standard">
          <InputLabel>{t('sds_authoring:language')}</InputLabel>
          <Select
            id="language"
            labelId="language-input-label"
            value={selectedLanguage}
            label={t('sds_authoring:language')}
            className={classes.selectInput}
            onChange={handleLanguageChange}
            MenuProps={{ classes: { paper: classes.selectMenu } }}
          >
            {availableLanguages.map((el, i) => {
              return (
                <MenuItem key={i} value={el.value}>
                  {el.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <div className={classes.content}>
        {loading ? (
          <Loader />
        ) : componentsPaginatedData.results.length < 1 ? (
          displayError()
        ) : (
          <Paper elevation={3}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell width={'5%'} />
                  <TableCell width={'25%'}>
                    {t('sds_authoring:component_name')}
                  </TableCell>
                  <TableCell width={'20%'}>
                    {t('sds_authoring:supplier_name')}
                  </TableCell>
                  <TableCell width={'10%'}>
                    {t('sds_authoring:cas_number')}
                  </TableCell>
                  <TableCell width={'10%'}>
                    {t('sds_authoring:ec_number')}
                  </TableCell>
                  <TableCell width={'10%'}>
                    {t('sds_authoring:reach_number')}
                  </TableCell>
                  <TableCell width={'15%'}>
                    {t('sds_authoring:revision_date')}
                  </TableCell>
                  <TableCell width={'5%'}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {componentsPaginatedData.results.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell width={'5%'} align={'right'}>
                      {loadingId && loadingId === row.id ? (
                        <IconButton style={{ color: 'rgb(98, 109, 249)' }}>
                          <CircularProgress
                            style={{ width: '20px', height: '20px' }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() =>
                            handleAddComponent(row.id, row.cas_no, false)
                          }
                          color='primary'
                        >
                          <AddCircleOutlineIcon style={{ fontSize: 22 }} />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: 'bold' }}
                      component="th"
                      scope="row"
                    >
                      {row.sds_pdf_product_name}
                    </TableCell>
                    <TableCell>{row.sds_pdf_manufacture_name}</TableCell>
                    <TableCell>{row.cas_no}</TableCell>
                    <TableCell>{row.ec_no}</TableCell>
                    <TableCell>{row.reach_no}</TableCell>
                    <TableCell>{row.sds_pdf_revision_date}</TableCell>
                    <TableCell>
                      <Tooltip title={t('tooltips:open_tooltip')} describeChild>
                        <a
                          style={{ color: '#0d324f', height: '24px' }}
                          href={row.public_view_url}
                          target="_blank"
                        >
                          <PictureAsPdfRounded style={{ fill: '#0d324f' }} />
                        </a>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    count={componentsPaginatedData.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('sds_authoring:rows_per_page')}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        )}
      </div>
      {openConfirmAddComponentPopup && selectedRow && (
        <ConfirmActionPopup
          title={t('popups:component_already_exists')}
          open={openConfirmAddComponentPopup}
          onClose={() => {
            setSelectedRow(null);
            setOpenConfirmAddComponentPopup(false);
            setLoadingId(null);
          }}
          onSubmit={() => {
            handleAddComponent(
              selectedRow.component_sds,
              selectedRow.cas_no,
              true
            );
            setOpenConfirmAddComponentPopup(false);
          }}
        />
      )}
    </DesktopDialog>
  );
};

export default AddComponentToOurListPopup;
