import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { change, InjectedFormProps, reduxForm } from 'redux-form';
// @ts-ignore
import { Typography } from '@mui/material';
import { post, update } from 'api';
import { Loader } from 'components';
import { setDefaultSupplierInfo } from 'reducers/pageAction';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import SectionFields from './SectionFields';
import { CustomFormProps } from '../utils';
import SaveAsButton from 'components/save-as-btn';

const ProductIdentificationForm = (props: CustomFormProps & InjectedFormProps<{}, CustomFormProps>) => {
  const classes = useStyles();
  const { t } = useTranslation(['fields', 'sds_authoring']);
  const { handleNext, errors, handleCancel, dirty, handleSubmit, handleCopy} = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const form = useSelector((state: any) => state.form.productDescriptionForm);
  const countriesSettings = useSelector(
    (state: any) => state.pageReducer.countriesSettings
  );
  const sdsCountry = useSelector(
    (state: any) => state.form.section0Form?.values?.sds_country
  );
  
  const defaultSupplierInfo = useSelector(
    (state: any) => state.pageReducer.defaultSupplierInfo
  );
  const userInfo = useSelector((state: any) => state.pageReducer.userInfo);

  const [showSaveSupplierInfoBtn, setShowSaveSupplierInfoBtn] =
    React.useState(false);
  const [showRetrieveSupplierInfoBtn, setShowRetrieveSupplierInfoBtn] =
    React.useState(!!defaultSupplierInfo);

  const onSubmit = () => {
    if (dirty) {
      handleNext({ ...form.values });
    } else {
      handleNext(null);
    }
  };

  const saveSupplierInfoAsDefault = () => {
    const data = {
      company_name: form.values.company_name,
      street: form.values.street,
      postcode: form.values.postcode,
      city: form.values.city,
      state: form.values.state,
      company_country: form.values.company_country,
      telephone: form.values.telephone,
      website: form.values.website,
      email: form.values.email,
    };
    if (!defaultSupplierInfo?.id) {
      const saveSupplierInfoRequest = post(`/sdsAuthoring/supplierInfo/`, data);
      saveSupplierInfoRequest.then((_) => {
        setShowSaveSupplierInfoBtn(false);
      });
    } else {
      const saveSupplierInfoRequest = update(
        `/sdsAuthoring/supplierInfo/${defaultSupplierInfo.id}/`,
        data
      );
      saveSupplierInfoRequest.then((_) => {
        setShowSaveSupplierInfoBtn(false);
      });
    }
    dispatch(setDefaultSupplierInfo({ ...data, id: defaultSupplierInfo?.id }));
  };

  const applyDefaultSupplierInfo = () => {
    Object.keys(defaultSupplierInfo).forEach((el) => {
      dispatch(
        change('productDescriptionForm', `${el}`, defaultSupplierInfo[el])
      );
    });
    setShowRetrieveSupplierInfoBtn(false);
  };

  React.useEffect(() => {
    if (sdsCountry && countriesSettings) {
      if (!form?.values?.emergency_provider) {
        dispatch(
          change(
            'productDescriptionForm',
            'emergency_provider',
            countriesSettings[sdsCountry]?.emergency_provider
          )
        );
      }
      if (!form?.values?.emergency_telephone) {
        dispatch(
          change(
            'productDescriptionForm',
            'emergency_telephone',
            countriesSettings[sdsCountry]?.emergency_phone_number
          )
        );
      }
    }
  }, [sdsCountry, countriesSettings]);

  React.useEffect(() => {
    if (!form?.values?.company_country) {
      dispatch(
        change(
          'productDescriptionForm',
          'company_country',
          userInfo?.customer?.country?.name
        )
      );
    }
  }, []);

  React.useEffect(() => {
    setShowRetrieveSupplierInfoBtn(!!defaultSupplierInfo?.id);
  }, [defaultSupplierInfo]);

  return (
    <div className={classes.formWrapper}>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h5">{translations.headers.header_1}</Typography>
          <SectionFields
            showSaveSupplierInfoBtn={showSaveSupplierInfoBtn}
            showRetrieveSupplierInfoBtn={showRetrieveSupplierInfoBtn}
            saveSupplierInfoAsDefault={saveSupplierInfoAsDefault}
            applyDefaultSupplierInfo={applyDefaultSupplierInfo}
            setShowSaveSupplierInfoBtn={setShowSaveSupplierInfoBtn}
          />
          <div className={classes.errorsRow}>
            {errors?.map((el: any, index: number) => {
              return (
                <p key={index} className={classes.errorText}>
                  {el}
                </p>
              );
            })}
          </div>
          <SubmitButton handleSubmit={handleSubmit(onSubmit)} />
          <SaveAsButton handleSubmit={handleCopy} />
        </form>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.productDescriptionForm?.values) {
    return {
      initialValues: {
        synonyms: state.pageReducer.sdsInfo.synonyms,
        relevant_use: state.pageReducer.sdsInfo.relevant_use,
        uses_advised_against: state.pageReducer.sdsInfo.uses_advised_against,
        product_name: state.pageReducer.sdsInfo.product_name,
        description: state.pageReducer.sdsInfo.description,
        product_number: state.pageReducer.sdsInfo.product_number,
        trade_name: state.pageReducer.sdsInfo.trade_name,
        ufi: state.pageReducer.sdsInfo.ufi,
        sds_country: state.pageReducer.sdsInfo.sds_country,
        company_name: state.pageReducer.sdsInfo.company_name,
        company_country: state.pageReducer.sdsInfo.company_country,
        state: state.pageReducer.sdsInfo.state,
        city: state.pageReducer.sdsInfo.city,
        street: state.pageReducer.sdsInfo.street,
        postcode: state.pageReducer.sdsInfo.postcode,
        telephone: state.pageReducer.sdsInfo.telephone,
        manufacturer_company_name:
          state.pageReducer.sdsInfo.manufacturer_company_name,
        manufacturer_company_country:
          state.pageReducer.sdsInfo.manufacturer_company_country,
        manufacturer_state: state.pageReducer.sdsInfo.manufacturer_state,
        manufacturer_city: state.pageReducer.sdsInfo.manufacturer_city,
        manufacturer_street: state.pageReducer.sdsInfo.manufacturer_street,
        manufacturer_postcode: state.pageReducer.sdsInfo.manufacturer_postcode,
        manufacturer_telephone:
          state.pageReducer.sdsInfo.manufacturer_telephone,
        website: state.pageReducer.sdsInfo.website,
        email: state.pageReducer.sdsInfo.email,
        emergency_telephone: state.pageReducer.sdsInfo.emergency_telephone,
        emergency_provider: state.pageReducer.sdsInfo.emergency_provider,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'productDescriptionForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(ProductIdentificationForm)
);
