import { Delete } from '@mui/icons-material';
import { IconButton, TableCell } from '@mui/material';
import clsx from 'clsx';
import { Field } from 'redux-form';
import InputField from '../../input-field';
import SelectField from '../../select-field';
import useStyles from '../styles';

export const DataTableCell = ({
  key,
  index,
  style,
  formName,
  fieldsName,
  fieldsProps,
  onDelete,
}: {
  key: number;
  index: number;
  style: any;
  fieldsName: string;
  formName: string;
  fieldsProps: any;
  onDelete: () => void;
}) => {
  const classes = useStyles();
  
  if (fieldsName === 'action') {
    return (
      <TableCell>
        <IconButton
          onClick={onDelete}
          sx={{ color: 'colors.text' }}
        >
          <Delete />
        </IconButton>
      </TableCell>
    );
  }

  return (
    <TableCell key={key} style={style} className={clsx(classes.baseField)}>
      {fieldsProps && fieldsProps[fieldsName]?.type === 'select' ? (
        <Field
          name={`${formName}[${index}].${fieldsName}`}
          component={SelectField}
          fullWidth
          {...fieldsProps[fieldsName]}
        />
      ) : (
        <Field
          name={`${formName}[${index}].${fieldsName}`}
          component={InputField}
          fullWidth
        />
      )}
    </TableCell>
  );
};
