import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: '100%',
    padding: '20px 20px 0px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  subheading: {
    color: '#fff',
    marginBottom: '10px',
    padding: '5px',
    backgroundColor: '#0d324f',
  },
  formRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  inputWrapper: {
    width: '45%',
  },
  form: {
    marginTop: 20,
  },
  formColumn: {
    width: '45%',
  },
  textInput: {
    width: '100%',
  },
  errorText: {
    fontSize: '13px',
    color: theme.palette.colors.red,
  },
  errorsRow: {
    width: '100%',
  },
  wrapper: {
    width: '60%',
  },
  regWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 20,
  },
  addMoreWrapper: {
    display: 'flex',
  },
  chipsWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    alignItems: 'center',
  },
  pictogramsWrapper: {
    display: 'flex',
    margin: '10px 0px',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  pictogram: {
    width: '80px',
    height: '80px',
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tableContainer: {
    flex: 1,
  },
  tableHeader: {
    backgroundColor: '#F2F5F7',
  },
  tableColumn: {
    paddingLeft: '15px !important',
    paddingRight: '15px !important',
  },
  tableCell: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  title: {
    position: 'relative',
    top: 10,
    left: 16,
    zIndex: 2,
    padding: '0px 6px',
    background: '#fff',
    display: 'inline',
  },
}));

export default useStyles;
