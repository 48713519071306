import { useTranslation } from 'react-i18next';

export interface OurComponentsProps {
  id: string;
  title: string;
  minWidth: number;
  maxWidth?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
}

export const getColumnList = (): Array<OurComponentsProps> => {
  const { t } = useTranslation(['our_components', 'sds_authoring']);

  return [
    {
      id: 'component_name',
      title: t('our_components:component_name'),
      minWidth: 210,
      align: 'left',
    },
    {
      id: 'supplier_name',
      title: t('sds_authoring:supplier_name'),
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'cas_no',
      title: `${t('our_components:cas_no')}`,
      align: 'left',
      minWidth: 100,
    },
    {
      id: 'ec_no',
      title: `${t('our_components:ec_no')}`,
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'reach_no',
      title: `${t('our_components:reach_no')}`,
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'classifications',
      title: `${t('our_components:classifications')}`,
      align: 'left',
      minWidth: 150,
    },
    {
      id: 'signal_word',
      title: `${t('our_components:signal_word')}`,
      align: 'left',
      minWidth: 100,
    },
    {
      id: 'ghs_pictograms',
      title: `${t('our_components:ghs_pictograms')}`,
      align: 'left',
      minWidth: 100,
    },
    {
      id: 'status',
      title: `${t('our_components:status')}`,
      minWidth: 100,
    },
    {
      id: 'created_by',
      title: `${t('our_components:created_by')}`,
      minWidth: 100,
    },
    {
      id: 'created_date',
      title: `${t('our_components:created_date')}`,
      minWidth: 100,
    },
    {
      id: 'action',
      title: ``,
      minWidth: 100,
    },
  ];
};
