import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paginationButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  previewWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  approveBlockWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: 20
  },
  pdfWrapper: {
    alignSelf: 'center', 
    minWidth: 500,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
    },
  },
  pdfPreviewAndSelector: {
    display: "flex",
    justifyContent: "center",
    gap: 30
  },
  logoPositionSelectorWrapper: {
    width: 200
  }
}));
