import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from '../i18n/resources';

let initialized = false;

export const languages = [
  'en',
  'no',
  'de',
  'da',
  'sv',
  'el',
  'es',
  'fi',
  'fr',
  'it',
  'nl',
  'pl',
  'pt',
  'et',
  'hi',
  'hu',
  'id',
  'is',
  'lt',
  'mt',
  'ro',
  'ru',
  'sk',
  'sl',
  'tr',
];
export const defaultLanguage = 'en';

export function getI18n(defaultOptions: any) {
  if (!initialized) {
    const options = {
      debug: process.env.NODE_ENV === 'development',
      resources,
      fallbackLng: 'en',
      defaultNS: 'common',
      react: {
        useSuspense: false,
      },
      ...defaultOptions,
    };

    if (!options.lng) {
      options.lng = getBrowserLanguage();
    }

    i18next.use(initReactI18next).init(options);

    i18next.on('languageChanged', (lng) => {
      setBrowserLanguage(lng);
    });

    initialized = true;
  }

  return i18next;
}

export const getBrowserLanguage = () => {
  let lang = localStorage.getItem('browserLanguage') ?? '';
  if (languages.indexOf(lang) > -1) {
    return lang;
  }

  try {
    if (navigator.language) {
      lang = navigator.language.substring(0, 2).toLowerCase();
      if (languages.indexOf(lang) > -1) {
        return lang;
      }
    }

    return defaultLanguage;
  } catch (err) {
    return defaultLanguage;
  }
};

export const setBrowserLanguage = (language: string) => {
  return localStorage.setItem('browserLanguage', language);
};

export default i18next;
