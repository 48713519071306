import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StepFieldsProps } from '../utils';

export const getStep6Fields = (): Array<StepFieldsProps> => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const { t } = useTranslation(['fields']);
  return [
    {
      title: translations.headers['6_1_header_personal_precautions'],
      fields: [
        {
          name: 'personal_precautions',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'personal_precautions_for_non_emergency_personnel',
          component: TextFieldInputV2,
          label: translations.sections
            .personal_precautions_for_non_emergency_personnel
            ? translations.sections
                .personal_precautions_for_non_emergency_personnel
            : t('fields:personal_precautions_for_non_emergency_personnel'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'personal_precautions_for_emergency_responders',
          component: TextFieldInputV2,
          label: translations.sections
            .personal_precautions_for_emergency_responders
            ? translations.sections
                .personal_precautions_for_emergency_responders
            : t('fields:personal_precautions_for_emergency_responders'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['6_2_header_environmental_precautions'],
      fields: [
        {
          name: 'environmental_precautions',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title:
        translations.headers['6_3_header_methods_and_material_containment'],
      fields: [
        {
          name: 'methods_for_cleaning_up',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'small_spill',
          component: TextFieldInputV2,
          label: translations.sections.small_spill
            ? translations.sections.small_spill
            : t('fields:small_spill'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'large_spill',
          component: TextFieldInputV2,
          label: translations.sections.large_spill
            ? translations.sections.large_spill
            : t('fields:large_spill'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['6_4_header_refference_other_sections'],
      fields: [
        {
          name: 'reference_to_other_sections',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
      ],
    },
  ];
};
