import en from './messages.json';
import no from './messages.no.json';
import de from './messages.de.json';
import da from './messages.da.json';
import sv from './messages.sv.json';
import nl from './messages.nl.json';
import fi from './messages.fi.json';
import el from './messages.el.json';
import pl from './messages.pl.json';
import pt from './messages.pt.json';
import es from './messages.es.json';
import fr from './messages.fr.json';
import it from './messages.it.json';
import et from './messages.et.json';
import hi from './messages.hi.json';
import hu from './messages.hu.json';
import id from './messages.id.json';
import is from './messages.is.json';
import lt from './messages.lt.json';
import mt from './messages.mt.json';
import ro from './messages.ro.json';
import ru from './messages.ru.json';
import sk from './messages.sk.json';
import sl from './messages.sl.json';
import tr from './messages.tr.json';

export default {
  en,
  no,
  de,
  da,
  sv,
  el,
  es,
  fi,
  fr,
  it,
  nl,
  pl,
  pt,
  et,
  hi,
  hu,
  id,
  is,
  lt,
  mt,
  ro,
  ru,
  sk,
  sl,
  tr,
};
