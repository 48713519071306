import {
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { get } from 'api';
import { Loader } from 'components';
import DesktopDialog from 'components/dialog/Dialog';

import { PopupProps } from './PopupsInterface';
import useStyles from './styles';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const SelectComponentFromOurComponentsList = React.memo(
  ({ onClose, open, onSubmit }: PopupProps) => {
    const classes = useStyles();
    const { t } = useTranslation(['popups', 'sds_authoring', 'actions']);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [showNoDataMessage, setShowNoDataMessage] = React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [componentsPaginatedData, setComponentsPaginatedData] =
      React.useState({
        results: [],
        count: 10,
      });
    const [selectedComponents, setSelectedComponents] = React.useState<
      Array<any>
    >([]);

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(event.target.value);
      setPage(0);
    };

    const handleSelect = (row: any) => {
      if (selectedComponents.find((el: any) => el.id === row.id)) {
        setSelectedComponents(
          selectedComponents.filter((item: any) => item.id !== row.id)
        );
      } else {
        setSelectedComponents([row, ...selectedComponents]);
      }
    };

    const handleSearchComponents = (page: number, rows: number) => {
      setLoading(true);
      const getCreatedPDFs = get(`/sdsAuthoring/customerComponents/`, {
        page: page + 1,
        page_size: rows,
      });
      getCreatedPDFs.then((response) => {
        if (response.status === 200) {
          setComponentsPaginatedData(response.data);
          if (response.data.results.length === 0) {
            setShowNoDataMessage(true);
          }
          setLoading(false);
        }
      });
    };

    React.useEffect(() => {
      handleSearchComponents(page, rowsPerPage);
    }, [page, rowsPerPage]);

    if (!open) {
      return null;
    }

    return (
      <DesktopDialog
        classes={{ paper: classes.selectComponentsPopup }}
        onClose={() => onClose()}
        title={t('popups:select_components')}
        maxWidth={'xl'}
        fullWidth={true}
      >
        <div style={{ maxHeight: '80%', padding: '20px' }}>
          <div style={{ width: '100%' }}>
            {loading ? (
              <Loader />
            ) : componentsPaginatedData.results?.length > 0 ? (
              <TableContainer>
                <SimpleBar style={{ maxHeight: '70vh' }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>
                          {t('sds_authoring:component_name')}
                        </TableCell>
                        <TableCell>
                          {t('sds_authoring:supplier_name')}
                        </TableCell>
                        <TableCell>{t('sds_authoring:cas_number')}</TableCell>
                        <TableCell>{t('sds_authoring:ec_number')}</TableCell>
                        <TableCell>{t('sds_authoring:reach_number')}</TableCell>
                        <TableCell>
                          {t('sds_authoring:revision_date')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {componentsPaginatedData.results.map((row: any) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            <Checkbox
                              color={'primary'}
                              onClick={() => handleSelect(row)}
                              checked={selectedComponents.find(
                                (el: any) => el.id === row.id
                              )}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.component_name}
                          </TableCell>
                          <TableCell>{row.supplier_name}</TableCell>
                          <TableCell>{row.cas_no}</TableCell>
                          <TableCell>{row.ec_no}</TableCell>
                          <TableCell>{row.reach_no}</TableCell>
                          <TableCell>{row.revision_date}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          count={componentsPaginatedData.count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              'aria-label': 'rows per page',
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={t('sds_authoring:rows_per_page')}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </SimpleBar>
              </TableContainer>
            ) : null}
          </div>
        </div>
        <div className={classes.actionWrapper}>
          <Button
            variant={'contained'}
            color={'primary'}
            disabled={selectedComponents.length === 0}
            className={classes.button}
            onClick={() => {
              onSubmit(selectedComponents);
            }}
          >
            {t('actions:add')}
          </Button>
          <Button
            onClick={onClose}
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
          >
            {t('actions:cancel')}
          </Button>
        </div>
      </DesktopDialog>
    );
  }
);

export default SelectComponentFromOurComponentsList;
