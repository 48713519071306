import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  pictogramsWrapper: {
    display: 'flex',
    marginBottom: '20px',
  },
  pictogram: {
    width: '80px',
    height: '80px',
    marginRight: '10px',
  },
  statementItem: {
    display: 'flex',
  },
  statementCode: {
    width: '40%',
    fontWeight: '800 !important',
  },
  statements: {
    width: '60%',
  },
  statementsHeading: {
    fontSize: '18px',
    fontWeight: '800 !important',
  },
  statementsBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  sectionTitle: {
    fontWeight: '600 !important',
    marginTop: '10px',
  },
}));

export default useStyles;
