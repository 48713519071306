import { TableCell } from '@mui/material';
import { OurSDSColumnsProps } from '../helper';
import useStyles from './styles';

const SDSTableHeader = ({
  columns,
}: {
  columns: Array<OurSDSColumnsProps>;
}) => {
  return (
    <>
      {columns.map((col, index) => {
        return (
          <TableCell
            key={col.id}
            align={col.align ?? 'center'}
            style={{
              minWidth: col.minWidth,
              maxWidth: col.maxWidth,
              width: col.width,
            }}
          >
            {col.title}
          </TableCell>
        );
      })}
    </>
  );
};

export default SDSTableHeader;
