import { Add, Clear } from '@mui/icons-material';
import { Box, Button, Chip } from '@mui/material';
import SelectIsoIconsPopup from 'components/popups/SelectIsoIconsPopup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';

export const IsoIconsField = ({
  fields,
  availableIcons,
}: {
  fields: any;
  availableIcons: any;
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['actions']);
  const [openAddPopup, setOpenAddPopup] = React.useState(false);

  const handleSelectCodes = (elems: any) => {
    const previouslyAddedFields = fields.getAll();
    elems.map((isoIcon: any) => {
      if (!previouslyAddedFields.find((el: any) => el.id === isoIcon.id)) {
        fields.push({
          id: isoIcon.id,
          url: isoIcon.url,
          title: isoIcon.title,
        });
      }
    });
  };

  return (
    <div>
      <Box className={classes.chipsWrapper}>
        {fields.getAll()?.map((icon: any, index: number) => {
          return (
            <Chip
              sx={{ padding: '5px' }}
              label={icon.title}
              avatar={<img src={icon.url} alt={''} />}
              onDelete={() => fields.remove(index)}
              deleteIcon={<Clear />}
              variant="outlined"
            />
          );
        })}
        <Button
          onClick={() => setOpenAddPopup(true)}
          sx={{ color: 'colors.text', pl: 0 }}
        >
          <Add /> Add Icons
        </Button>
      </Box>

      {openAddPopup && (
        <SelectIsoIconsPopup
          open={openAddPopup}
          choices={availableIcons}
          onSubmit={(selectedItems) => {
            handleSelectCodes(selectedItems);
            setOpenAddPopup(false);
          }}
          onClose={() => setOpenAddPopup(false)}
          defaultSelectedIcons={fields.getAll()}
        />
      )}
    </div>
  );
};
