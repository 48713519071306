import { Clear, Search } from '@mui/icons-material';
import { IconButton, Input } from '@mui/material';
import { useState } from 'react';
import useStyles from './styles';

const SearchBar = ({
  id,
  placeHolder,
  defaultValue,
  onChange,
  onSearch,
  onClear,
}: {
  id: any;
  placeHolder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onSearch: (value: string) => void;
  onClear?: () => void;
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue ?? '');

  const onTypeSearch = (e: any) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      onSearch(value);
    }
  };

  return (
    <div className={classes.searchWrapper}>
      <Input
        className={classes.searchInput}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          if (onChange) onChange(e.target.value);
        }}
        id={id}
        onKeyDown={onTypeSearch}
        placeholder={placeHolder}
      />
      {value && onClear ? (
        <IconButton
          size="small"
          className={classes.clearTextButton}
          onClick={() => {
            if (!value) return;
            onClear();
            setValue('');
          }}
        >
          <Clear />
        </IconButton>
      ) : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          onSearch(value);
        }}
      >
        <Search height={20} width={20} color={'primary'} />
      </div>
    </div>
  );
};

export default SearchBar;
