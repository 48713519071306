import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme?: any) => ({
  formWrapper: {
  },
  formHeader: {
    color: '#000',
    fontSize: '28px',
    fontWeight: 'bold',
  },
  formRow: {
    marginBottom: '20px',
  },
  textInput: {
    width: '100%',
    border: '0.5px solid #CCCCCC',
    borderRadius: '4px',
    marginBottom: '10px',
  },
  submitButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  submitButton: {
    width: '50%',
    padding: '5px',
    color: theme.palette.colors.light,
    fontSize: '21px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: 'none',
    marginTop: '20px',
  },
  errorText: {
    fontSize: '13px',
    color: theme.palette.colors.red,
  },
  errorsRow: {
    width: '100%',
  },
}));

export default useStyles;
