import {
  Autocomplete,
  Button,
  MenuItem,
  MenuList,
  TextField,
} from '@mui/material';
import DesktopDialog from 'components/dialog/Dialog';
import { OUR_SDS_STATUS } from 'enums/our-sds-status.enum';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

export const AddFilterPopup = ({
  selected,
  onClose,
  open,
  onSubmit,
}: {
  selected: Array<OUR_SDS_STATUS>;
  onClose: () => void;
  open: boolean;
  onSubmit: (value: Array<OUR_SDS_STATUS>) => void;
}) => {
  const { t } = useTranslation(['actions', 'status_choices', 'sds_list']);
  const classes = useStyles();
  const [selectedStatuses, setSelectedStatuses] = useState<Array<any>>([]);

  const statusChoices = [
    { value: OUR_SDS_STATUS.DRAFT, label: t('status_choices:draft') },
    { value: OUR_SDS_STATUS.APPROVED, label: t('status_choices:approved') },
    { value: OUR_SDS_STATUS.ARCHIVED, label: t('status_choices:archived') },
    { value: OUR_SDS_STATUS.DELETED, label: t('status_choices:deleted') },
    {
      value: OUR_SDS_STATUS.DEPRECATED_VERSION,
      label: t('status_choices:deprecated_version'),
    },
    {
      value: OUR_SDS_STATUS.READY_FOR_APPROVAL,
      label: t('status_choices:ready_for_approval'),
    },
  ];

  useEffect(() => {
    setSelectedStatuses(() => {
      return statusChoices.filter((status) => selected.includes(status.value));
    });
  }, []);

  const handleSelectChange = (_: any, status: Array<any>) => {
    setSelectedStatuses(status);
  };

  const handleSubmit = () => {
    const selectValue = selectedStatuses.map((state: any) => state.value);
    onSubmit(selectValue)
  }

  const desktopDialogProps = {
    classes: { paper: classes.popup },
    onClose: () => onClose(),
    open: open,
    title: t('sds_list:add_filter'),
    actions: (
      <>
        <Button
          style={{ width: 100 }}
          variant={'outlined'}
          color={'primary'}
          onClick={onClose}
        >
          {t('actions:cancel')}
        </Button>
        <Button
          style={{ width: 100 }}
          variant={'contained'}
          color={'primary'}
          onClick={handleSubmit}
        >
          {t('actions:add')}
        </Button>
      </>
    ),
  };

  return (
    <DesktopDialog {...desktopDialogProps}>
      <MenuList>
        <MenuItem>
          <Autocomplete
            multiple
            fullWidth
            value={selectedStatuses}
            options={statusChoices}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={(option) => option.label}
            onChange={handleSelectChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t('sds_list:status')}
              />
            )}
          />
        </MenuItem>
      </MenuList>
    </DesktopDialog>
  );
};
