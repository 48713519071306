import { TableCell } from '@mui/material';
import { ColumnsProps } from '../helper';

const ProtectionTableHeader = ({
  columns,
}: {
  columns: Array<ColumnsProps>;
}) => {
  return (
    <>
      {columns.map((col, index) => {
        return (
          <TableCell
            key={col.id}
            align={col.align ?? 'center'}
            style={{
              minWidth: col.minWidth,
              maxWidth: col.maxWidth,
            }}
          >
            {col.title}
          </TableCell>
        );
      })}
    </>
  );
};

export default ProtectionTableHeader;
