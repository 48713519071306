import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface SectionFields {
  name: string;
  component?: any;
  label: string;
  xs: number;
  multiline?: boolean;
  isArray?: boolean;
  icon_field_name?: string;
}

interface StepFieldsProps {
  title: string;
  fields: Array<SectionFields>;
}

export const getStep13Fields = (): Array<StepFieldsProps> => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const { t } = useTranslation(['fields']);
  return [
    {
      title: translations.sections.un_number ?? t('fields:un_number'),
      fields: [
        {
          name: 'imdg_un_number',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 4,
        },
        {
          name: 'iata_un_number',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 4,
        },
        {
          name: 'adr_un_number',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 4,
        },
        {
          name: 'rid_un_number',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 4,
        },
        {
          name: 'adn_un_number',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 4,
        },
      ],
    },
    {
      title: translations.sections.shipping_name
        ? translations.sections.shipping_name
        : t('fields:shipping_name'),
      fields: [
        {
          name: 'imdg_shipping_name',
          component: TextFieldInputV2,
          label: '',
          xs: 4,
        },
        {
          name: 'iata_shipping_name',
          component: TextFieldInputV2,
          label: '',
          xs: 4,
        },
        {
          name: 'adr_shipping_name',
          component: TextFieldInputV2,
          label: '',
          xs: 4,
        },
        {
          name: 'rid_shipping_name',
          component: TextFieldInputV2,
          label: '',
          xs: 4,
        },
        {
          name: 'adn_shipping_name',
          component: TextFieldInputV2,
          label: '',
          xs: 4,
        },
      ],
    },
    {
      title: translations.sections.transport_hazard_classes
        ? translations.sections.transport_hazard_classes
        : t('fields:transport_hazard_classes'),
      fields: [
        {
          name: 'imdg_transport_hazard_classes',
          component: TextFieldInputV2,
          label: '',
          xs: 12,
          icon_field_name: 'imdgTransportIcons',
        },
        {
          name: 'iata_transport_hazard_classes',
          component: TextFieldInputV2,
          label: '',
          xs: 12,
          icon_field_name: 'iataTransportIcons',
        },
        {
          name: 'adr_transport_hazard_classes',
          component: TextFieldInputV2,
          label: '',
          xs: 12,
          icon_field_name: 'adrTransportIcons',
        },
        {
          name: 'rid_transport_hazard_classes',
          component: TextFieldInputV2,
          label: '',
          xs: 12,
          icon_field_name: 'ridTransportIcons',
        },
        {
          name: 'adn_transport_hazard_classes',
          component: TextFieldInputV2,
          label: '',
          xs: 12,
          icon_field_name: 'adnTransportIcons',
        },
      ],
    },
    {
      title: translations.sections.packing_group
        ? translations.sections.packing_group
        : t('fields:packing_group'),
      fields: [
        {
          name: 'imdg_packing_group',
          component: TextFieldInputV2,
          label: '',
          xs: 4,
        },
        {
          name: 'iata_packing_group',
          component: TextFieldInputV2,
          label: '',
          xs: 4,
        },
        {
          name: 'adr_packing_group',
          component: TextFieldInputV2,
          label: '',
          xs: 4,
        },
        {
          name: 'rid_packing_group',
          component: TextFieldInputV2,
          label: '',
          xs: 4,
        },
        {
          name: 'adn_packing_group',
          component: TextFieldInputV2,
          label: '',
          xs: 4,
        },
      ],
    },
    {
      title: translations.sections.environmental_hazards
        ? translations.sections.environmental_hazards
        : t('fields:environmental_hazards'),
      fields: [
        {
          name: 'imdg_environmental_hazards',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'iata_environmental_hazards',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'adr_environmental_hazards',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'rid_environmental_hazards',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'adn_environmental_hazards',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },

      ],
    },
  ];
};


export const getOtherTransportFields = (): Array<StepFieldsProps> => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const { t } = useTranslation(['fields']);
  return [
    {
      title: translations.sections.special_precautions_for_user
        ? translations.sections.special_precautions_for_user
        : t('fields:special_precautions_for_user'),
      fields: [
        {
          name: 'special_precautions_for_user',
          component: TextFieldInputV2,
          label: translations.sections.special_precautions_for_user
            ? translations.sections.special_precautions_for_user
            : t('fields:special_precautions_for_user'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.sections.transport_in_bulk
        ? translations.sections.transport_in_bulk
        : t('fields:transport_in_bulk'),
      fields: [
        {
          name: 'transport_in_bulk',
          component: TextFieldInputV2,
          label: translations.sections.transport_in_bulk
            ? translations.sections.transport_in_bulk
            : t('fields:transport_in_bulk'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.sections.other_transport_information
        ? translations.sections.other_transport_information
        : t('fields:other_transport_information'),
      fields: [
        {
          name: 'other_transport_information',
          component: TextFieldInputV2,
          label: translations.sections.other_transport_information
            ? translations.sections.other_transport_information
            : t('fields:other_transport_information'),
          multiline: true,
          xs: 12,
        },
      ],
    },
  ];
};