import React, { memo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface DesktopDialogProps {
  title: string | undefined;
  onClose: () => void;
  onSave?: () => void;
  children?: React.ReactNode;
  DialogContentProps?: any;
  DialogTitleProps?: any;
  actions?: any;
  maxWidth?: any;
  fullWidth?: boolean;
  classes?: any;
  disableEnforceFocus?: boolean;
}

const DesktopDialog = memo(
  ({
    title,
    onClose,
    onSave,
    children,
    DialogContentProps,
    DialogTitleProps,
    actions,
    ...props
  }: DesktopDialogProps) => {
    const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onClose();
    };

    return (
      <Dialog open fullScreen={false} onClose={handleClose} {...props}>
        {title && (
          <DialogTitle style={{ textAlign: 'center' }} {...DialogTitleProps}>
            {title}
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent {...DialogContentProps}>{children}</DialogContent>
        {actions && (
          <DialogActions
            style={{ justifyContent: 'center', paddingBottom: '5px' }}
          >
            {actions}
          </DialogActions>
        )}
      </Dialog>
    );
  }
);
export default DesktopDialog;
