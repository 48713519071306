import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  pdfIcon: {
    color: theme.palette.colors.text,
    display: 'flex'
  },
  tableActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10
  },
  edit: {
    color: theme.palette.colors.text,
  }
}));

export default useStyles;
