import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import { Button } from '@mui/material';
import DesktopDialog from 'components/dialog/Dialog';
import ComponentExposureProtectionsFormData from './components/component-exposure-protection-data';
import ComponentToxicologicalData from './components/component-toxicological-data';
import ComponentEcologicalData from './components/component-ecological-data';
import {
  setComponentStructuredData,
  setStructuredDataEdited,
} from 'reducers/pageAction';
import useStyles from './styles';

const CreateComponentStructuredDataPopup = ({
  open,
  onClose,
  componentId,
  componentStructuredDataForm,
}: {
  open: boolean;
  onClose: () => void;
  componentId: any;
  componentStructuredDataForm: any;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['popups', 'actions']);
  if (!open) {
    return null;
  }

  const handleSave = (componentId: any) => {
    dispatch(
      setComponentStructuredData({
        [componentId]: componentStructuredDataForm.values,
      })
    );
    dispatch(setStructuredDataEdited(true));
    onClose();
  };

  const desktopDialogProps = {
    onClose: onClose,
    maxWidth: 'xl',
    classes: { paperFullWidth: classes.dialogPaper },
    title: t('popups:create_structured_data'),
    fullWidth: true,
    DialogContentProps: {
      className: classes.dialogContent,
    },
    actions: (
      <>
        <Button
          variant={'contained'}
          onClick={() => {
            handleSave(componentId);
          }}
        >
          {t('actions:add')}
        </Button>
        <Button onClick={onClose} variant={'outlined'}>
          {t('actions:cancel')}
        </Button>
      </>
    ),
  };

  return (
    <DesktopDialog {...desktopDialogProps}>
      <div className={classes.contentWrapper}>
        <ComponentExposureProtectionsFormData />
        <ComponentToxicologicalData />
        <ComponentEcologicalData />
      </div>
    </DesktopDialog>
  );
};

const mapStateToProps = (state: any) => {
  return {
    componentStructuredDataForm: state.form.componentStructuredData,
  };
};
export default connect(mapStateToProps)(CreateComponentStructuredDataPopup);
