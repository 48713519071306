import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { ConfirmRemoveApprove, Loader } from 'components';
import { getPDFFile, post } from 'api';
import { useStyles } from './styles';

import { setSDSInfo } from 'reducers/pageAction';
import { renderSnackbar } from '../../utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EventsTable = ({ events }) => {
  const classes = useStyles();
  const { t } = useTranslation(['events_table']);

  return (
    <TableContainer>
      <Table
        classes={{
          root: classes.table,
        }}
        size="small"
        aria-label="a dense table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{ minWidth: 130 }}
              className={classes.tableHeading}
            >
              {t('events_table:date')}
            </TableCell>
            <TableCell className={classes.tableHeading}>
              {t('events_table:action')}
            </TableCell>
            <TableCell className={classes.tableHeading}>
              {t('events_table:user')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell>{row.created_at}</TableCell>
                <TableCell>{t(`events_table:${row.event}`)}</TableCell>
                <TableCell>{row.created_by}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const SDSPreview = ({ pdfFileId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['actions', 'sds_authoring']);
  const [fileLoading, setFileLoading] = React.useState(false);
  const [fileContent, setFileContent] = React.useState(null);
  const sdsInfo = useSelector((state) => state.pageReducer.sdsInfo);
  const [openConfirmRemoveApprovePopup, setOpenConfirmRemoveApprovePopup] =
    React.useState(false);
  const completedSteps = useSelector(
    (state) => state.pageReducer.completedAuthoringStep
  );

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const approvePDF = () => {
    if (completedSteps.length < 17) {
      renderSnackbar([t('sds_authoring:review_sections_message')]);
      return;
    }
    setFileLoading(true);
    const approvePDFRequest = post(`/sdsAuthoring/files/${pdfFileId}/approve/`);
    approvePDFRequest.then((response) => {
      if (response.status === 200) {
        dispatch(setSDSInfo(response.data));
        setFileLoading(false);
      }
    });
  };

  const removeApprovePDF = () => {
    const removeApproveRequest = post(
      `/sdsAuthoring/files/${pdfFileId}/removeApprove/`
    );
    removeApproveRequest.then((response) => {
      if (response.status === 200) {
        dispatch(setSDSInfo(response.data));
      }
    });
  };

  React.useEffect(() => {
    if (pdfFileId) {
      setFileLoading(true);
      const getPDFFileDRequest = getPDFFile(pdfFileId);
      getPDFFileDRequest.then((response) => {
        if (response.status === 200) {
          setFileLoading(false);
          setFileContent(response.data.sds_file);
        }
      });
    }
  }, [pdfFileId]);

  return (
    <div>
      {!fileLoading && fileContent ? (
        <div className={classes.previewWrapper}>
          <div style={{ border: '1px solid gray' }}>
            <div className={classes.paginationButtons}>
              <Button
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                {t('actions:prev')}
              </Button>
              <Button
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber === numPages}
              >
                {t('actions:next')}
              </Button>
            </div>
            <Document
              loading={<Loader />}
              file={`data:application/pdf;base64,${fileContent}`}
              options={{ workerSrc: '/pdf.worker.js' }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
          <div className={classes.approveBlockWrapper}>
            {sdsInfo.status === 'approved' ? (
              <Button onClick={() => setOpenConfirmRemoveApprovePopup(true)}>
                {t('actions:remove_approval')}
              </Button>
            ) : sdsInfo.status === 'draft' ? (
              <>
                <Button onClick={approvePDF}>{t('actions:approve')}</Button>
                <Alert severity={'info'}>
                  <AlertTitle>{t('sds_authoring:approve_info')}</AlertTitle>
                </Alert>
              </>
            ) : null}
            {sdsInfo.events?.length > 0 && (
              <EventsTable events={sdsInfo.events} />
            )}
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
      {openConfirmRemoveApprovePopup && (
        <ConfirmRemoveApprove
          open={openConfirmRemoveApprovePopup}
          onClose={() => setOpenConfirmRemoveApprovePopup(false)}
          onSubmit={() => {
            removeApprovePDF();
            setOpenConfirmRemoveApprovePopup(false);
          }}
        />
      )}
    </div>
  );
};

export default SDSPreview;
