import { AccountCircle, ExitToAppRounded, Language } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { getUserAvatar } from 'api/get';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { setLanguageCode, setUserInfo } from 'reducers/pageAction';
import i18next, { languages } from 'utils/i18n';
import { availableLanguages } from '../../../constants';
import useStyles from '../styles';
import UploadImage from 'components/upload-image';
import { useEffect, useState } from 'react';
import { updateAuthoringCustomerLogo } from 'api/patch';
import { renderSnackbar } from 'utils';

const PopoverItem = ({ user }: { user: any }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['sds_authoring', 'header']);
  const languageCode = useSelector(
    (state: any) => state.pageReducer.languageCode
  );
  const [logo, setLogo] = useState<Blob | null>(null);
  const [logoSrc, setLogoSrc] = useState<string>('');

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    dispatch(setUserInfo(null));
    history.push(`/login/`);
  };

  const handleLanguageChange = (value: string) => {
    dispatch(setLanguageCode(value));
    let setLang = languages.includes(value) ? value : 'en';
    localStorage.setItem('languageCode', setLang);
    if (i18next.language !== setLang) {
      i18next.changeLanguage(setLang);
    }
  };

  const changeLogoInSDS = (logo: Blob | null) => {
    if (!user?.customer) return null;
    const updateData = new FormData();
    if (logo) updateData.append('authoring_logo', logo);
    updateAuthoringCustomerLogo(user?.customer.id, updateData).then(
      (response: { status: number }) => {
        if (response.status == 200) {
          renderSnackbar(['Upload logo successfully']);
          setLogo(logo);
        }
      }
    );
  };

  const renderLogoItem = () => {
    return (
      <>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemAvatar>
              <UploadImage
                onUpload={(logo_file: Blob | null) => {
                  changeLogoInSDS(logo_file)
                }}
                size={40}
                imageUrl={logoSrc}
                component={AccountCircle}
                tooltip={t("header:click_to_upload_logo")}
              />
            </ListItemAvatar>
            <ListItemText>{user.customer.name}</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
      </>
    );
  };

  useEffect(() => {
    user?.customer?.authoring_logo &&
      setLogoSrc(getUserAvatar(user.customer.authoring_logo));
  }, [user]);

  useEffect(() => {
    if (!logo) return;
    setLogoSrc(URL.createObjectURL(logo));
  }, [logo]);

  if (!user) return null;

  return (
    <div className={classes.popover}>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            {user && user.avatar ? (
              <ListItemAvatar>
                <Avatar alt="avatar" src={getUserAvatar(user.avatar)} />
              </ListItemAvatar>
            ) : (
              <ListItemIcon className={classes.popoverIcon}>
                <AccountCircle />
              </ListItemIcon>
            )}
            <ListItemText>
              {user && `${user.first_name} ${user.last_name}`}
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
        {renderLogoItem()}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon className={classes.popoverIcon}>
              <Language width={30} />
            </ListItemIcon>
            <ListItemText>
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <Select
                  id="language"
                  labelId="language-input-label"
                  value={languageCode}
                  onChange={(event: any) =>
                    handleLanguageChange(event.target.value)
                  }
                  MenuProps={{
                    classes: { paper: classes.optionInput },
                  }}
                >
                  {availableLanguages.map((el, i) => {
                    return (
                      <MenuItem key={i} value={el.value}>
                        {t(`languages:${el.value}`)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon className={classes.popoverIcon}>
              <ExitToAppRounded />
            </ListItemIcon>
            <ListItemText>
              {user ? (
                <NavLink
                  to={''}
                  className={classes.menuLink}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleLogout();
                  }}
                >
                  {t('header:logout')}
                </NavLink>
              ) : (
                <NavLink
                  to={''}
                  className={classes.menuLink}
                  onClick={(e) => history.push(`/login/`)}
                >
                  {t('header:login')}
                </NavLink>
              )}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

export default PopoverItem;
