import { connect, useSelector } from 'react-redux';
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import { Typography, Grid } from '@mui/material';
import { ComponentsStructuredDataPreview } from '../structured-data-preview';
import { getStructuredDataForFields } from 'utils/structuredDataPreviewPreparation';
import useStyles from '../styles';
import { getStep12Fields } from './fields';
import OutlinedSection from '../outlined-section';
import SubmitButton from '../submit-btn';
import TextFieldInputV2 from 'components/text-field';
import { CustomFormProps } from '../utils';
import DataTable from '../../create-component-structured-data/components/data-table';
import React from 'react';
import SaveAsButton from 'components/save-as-btn';

interface EcologicalFormProps extends CustomFormProps {
  components: any;
  doNotIncludeStructuredDataFromComponents: any;
  componentStructuredData: any;
}

const EcologicalForm = (
  props: EcologicalFormProps & InjectedFormProps<{}, EcologicalFormProps>
) => {
  const classes = useStyles();
  const {
    handleNext,
    dirty,
    components,
    doNotIncludeStructuredDataFromComponents,
    componentStructuredData,
    handleCopy,
  } = props;
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const form = useSelector((state: any) => state.form.ecologicalForm);
  const sections = getStep12Fields();

  const handleSubmit = () => {
    if (dirty) {
      handleNext({ ...form.values });
    } else {
      handleNext(null);
    }
  };

  const structuredDataByComponent: any = getStructuredDataForFields(
    componentStructuredData,
    components.filter(
      (el: any) => !doNotIncludeStructuredDataFromComponents.includes(el.id)
    ),
    ['toxicity', 'biodegradability', 'bioaccumulative_potential']
  );

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['12_header_ecological_info']}
      </Typography>
      <form onSubmit={handleSubmit}>
        {sections.map((section, index) => {
          if (section.title === translations.headers['12_1_header_toxicity']) {
            return (
              <OutlinedSection key={index} title={section.title}>
                <Grid item xs={12}>
                  <Field
                    name="toxicity"
                    component={TextFieldInputV2}
                    classes={{ root: classes.textInput }}
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldArray
                    name="toxicity_structured_data"
                    formName="toxicity_structured_data"
                    component={DataTable}
                    translations={translations}
                    fieldsNames={[
                      'toxicity_effect',
                      'endpoint',
                      'time',
                      'value',
                      'unit',
                      'organism',
                      'test_method',
                      'notes',
                      'action',
                    ]}
                    fieldsStyles={{
                      toxicity_effect: { width: '15%' },
                      endpoint: { width: '10%' },
                      time: { width: '10%' },
                      value: { width: '10%' },
                      unit: { width: '10%' },
                      organism: { width: '15%' },
                      test_method: { width: '15%' },
                      notes: { width: '15%' },
                    }}
                    fieldsProps={{
                      endpoint: {
                        type: 'select',
                        options: [
                          {
                            value: 'lc50',
                            label: 'LC50',
                          },
                          {
                            value: 'ec50',
                            label: 'EC50',
                          },
                        ],
                      },
                      toxicity_effect: {
                        type: 'select',
                        options: [
                          {
                            value: 'toxicity_to_fish',
                            label: translations.layout.toxicity_to_fish,
                          },
                          {
                            value: 'toxicity_to_daphnia',
                            label: translations.layout.toxicity_to_daphnia,
                          },
                          {
                            value: 'toxicity_to_algae',
                            label: translations.layout.toxicity_to_algae,
                          },
                          {
                            value: 'toxicity_to_bacteria',
                            label: translations.layout.toxicity_to_bacteria,
                          },
                        ],
                      },
                    }}
                  />
                </Grid>
                {structuredDataByComponent.toxicity?.length > 0 && (
                  <Grid item xs={12}>
                    <ComponentsStructuredDataPreview
                      fieldsNames={[
                        'toxicity_effect',
                        'endpoint',
                        'time',
                        'value',
                        'unit',
                        'organism',
                        'test_method',
                        'notes',
                      ]}
                      fieldsStyles={{
                        toxicity_effect: { width: '15%' },
                        endpoint: { width: '10%' },
                        time: { width: '10%' },
                        value: { width: '10%' },
                        unit: { width: '10%' },
                        organism: { width: '15%' },
                        test_method: { width: '15%' },
                        notes: { width: '15%' },
                      }}
                      structuredDataPerComponent={
                        structuredDataByComponent.toxicity
                      }
                      translations={translations}
                    />
                  </Grid>
                )}
                {section.fields.map((field, idx) => {
                  return (
                    <Grid key={idx} item xs={field.xs}>
                      <Field
                        validate={field.validate ?? null}
                        name={field.name}
                        component={field.component}
                        label={field.label}
                        multiline={field.multiline ?? null}
                        required={field.required ?? null}
                        classes={{ root: classes.textInput }}
                      />
                    </Grid>
                  );
                })}
              </OutlinedSection>
            );
          }
          if (
            section.title ===
            translations.headers['12_2_header_persistence_and_degradability']
          ) {
            return (
              <OutlinedSection key={index} title={section.title}>
                {structuredDataByComponent.biodegradability?.length > 0 && (
                  <Grid item xs={12}>
                    <ComponentsStructuredDataPreview
                      fieldsNames={[
                        'hydrolysis_half_life',
                        'photolysis',
                        'biodegradation',
                        'comment',
                      ]}
                      fieldsStyles={{
                        hydrolysis_half_life: { width: '25%' },
                        photolysis: { width: '25%' },
                        biodegradation: { width: '25%' },
                        comment: { width: '25%' },
                      }}
                      structuredDataPerComponent={
                        structuredDataByComponent.biodegradability
                      }
                      translations={translations}
                    />
                  </Grid>
                )}

                {section.fields.map((field, idx) => {
                  return (
                    <Grid key={idx} item xs={field.xs}>
                      <Field
                        validate={field.validate ?? null}
                        name={field.name}
                        component={field.component}
                        label={field.label}
                        multiline={field.multiline ?? null}
                        required={field.required ?? null}
                        classes={{ root: classes.textInput }}
                      />
                    </Grid>
                  );
                })}
              </OutlinedSection>
            );
          }
          if (
            section.title ===
            translations.headers['12_3_header_bioaccumulative_potential']
          ) {
            return (
              <OutlinedSection key={index} title={section.title}>
                {structuredDataByComponent.bioaccumulative_potential?.length >
                  0 && (
                  <Grid item xs={12}>
                    <ComponentsStructuredDataPreview
                      fieldsNames={['kow', 'bcf', 'potential', 'comment']}
                      fieldsStyles={{
                        kow: { width: '25%' },
                        bcf: { width: '25%' },
                        potential: { width: '25%' },
                        comment: { width: '25%' },
                      }}
                      structuredDataPerComponent={
                        structuredDataByComponent.bioaccumulative_potential
                      }
                      translations={translations}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    name="bioaccumulative_potential"
                    component={TextFieldInputV2}
                    classes={{ root: classes.textInput }}
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldArray
                    name="bioaccumulative_potential_structured_data"
                    formName="bioaccumulative_potential_structured_data"
                    component={DataTable}
                    translations={translations}
                    fieldsNames={[
                      'kow',
                      'bcf',
                      'potential',
                      'comment',
                      'action',
                    ]}
                    fieldsStyles={{
                      kow: { width: '25%' },
                      bcf: { width: '25%' },
                      potential: { width: '25%' },
                      comment: { width: '25%' },
                    }}
                  />
                </Grid>
              </OutlinedSection>
            );
          }

          return (
            <OutlinedSection key={index} title={section.title}>
              {section.fields.map((field, idx) => {
                return (
                  <Grid key={idx} item xs={field.xs}>
                    <Field
                      validate={field.validate ?? null}
                      name={field.name}
                      component={field.component}
                      label={field.label}
                      multiline={field.multiline ?? null}
                      required={field.required ?? null}
                      classes={{ root: classes.textInput }}
                    />
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        })}
        <SubmitButton handleSubmit={handleSubmit} />
        <SaveAsButton handleSubmit={handleCopy} />

      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.ecologicalForm?.values) {
    return {
      initialValues: {
        toxicity: state.pageReducer.sdsInfo.toxicity,
        bioaccumulative_potential_structured_data:
          state.pageReducer.sdsInfo.bioaccumulative_potential_structured_data,
        toxicity_structured_data:
          state.pageReducer.sdsInfo.toxicity_structured_data,
        ecotoxicity: state.pageReducer.sdsInfo.ecotoxicity,
        aquatic_toxicity: state.pageReducer.sdsInfo.aquatic_toxicity,
        algae_aquatic_toxicity:
          state.pageReducer.sdsInfo.algae_aquatic_toxicity,
        fish_aquatic_toxicity: state.pageReducer.sdsInfo.fish_aquatic_toxicity,
        m_factor_aquatic_toxicity:
          state.pageReducer.sdsInfo.m_factor_aquatic_toxicity,
        microorganisms_toxicity:
          state.pageReducer.sdsInfo.microorganisms_toxicity,
        bacteria_toxicity: state.pageReducer.sdsInfo.bacteria_toxicity,
        biodegradation: state.pageReducer.sdsInfo.biodegradation,
        biodegradability: state.pageReducer.sdsInfo.biodegradability,
        bioaccumulative_potential:
          state.pageReducer.sdsInfo.bioaccumulative_potential,
        mobility: state.pageReducer.sdsInfo.mobility,
        koc: state.pageReducer.sdsInfo.koc,
        mobility_additional_ecological_instructions:
          state.pageReducer.sdsInfo.mobility_additional_ecological_instructions,
        mobility_general_notes:
          state.pageReducer.sdsInfo.mobility_general_notes,
        pbt: state.pageReducer.sdsInfo.pbt,
        vpvb: state.pageReducer.sdsInfo.vpvb,
        persistent_organic_pollutant:
          state.pageReducer.sdsInfo.persistent_organic_pollutant,
        ozone_depletion_potential: state.pageReducer.sdsInfo.ozone_depletion_potential,
        other_adverse_effects: state.pageReducer.sdsInfo.other_adverse_effects,
      },
      components: state.form.ingredientsForm?.values?.ingredients
        ? state.form.ingredientsForm?.values?.ingredients
        : state.pageReducer.sdsInfo.components
        ? state.pageReducer.sdsInfo.components
        : [],
      doNotIncludeStructuredDataFromComponents:
        state.pageReducer.doNotIncludeStructuredDataFromComponents,
      componentStructuredData: state.pageReducer.structuredComponentData,
    };
  }
  return {
    components: state.form.ingredientsForm?.values?.ingredients
      ? state.form.ingredientsForm?.values?.ingredients
      : state.pageReducer?.sdsInfo?.components
      ? state.pageReducer?.sdsInfo?.components
      : [],
    doNotIncludeStructuredDataFromComponents:
      state.pageReducer.doNotIncludeStructuredDataFromComponents,
    componentStructuredData: state.pageReducer.structuredComponentData,
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, EcologicalFormProps>({
    form: 'ecologicalForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(EcologicalForm)
);
