import { FormControl, Autocomplete, TextField } from '@mui/material';
import { StringIfPlural, useTranslation } from 'react-i18next';

type Props = {
  label: string;
  input: any;
  meta: any;
  options: any;
  classes?: any;
  t?: any;
  custom: any;
};
type OptionsType = {
  name: string;
  value: string;
};

export const AutocompleteOptions = (): Array<OptionsType> => {
  const { t } = useTranslation(['sds_authoring']);
  return [
    {
      name: 'no_data_available',
      value: t('sds_authoring:no_data_available'),
    },
    {
      name: 'not_applicable',
      value: t('sds_authoring:not_applicable'),
    },
  ];
};

export const AutocompleteInput = ({
  input,
  label,
  meta: { touched, invalid, error },
  ...custom
}: Props) => {
  const { classes } = custom;
  const options = AutocompleteOptions();

  var inputValue = input.value;
  options.map((option) => {
    if (input.value === option.name) {
      return (inputValue = option.value);
    }
  });

  return (
    <FormControl classes={classes} error={touched && error}>
      <Autocomplete
        freeSolo
        value={inputValue}
        options={options.map((option) => option.value)}
        renderInput={(params) => (
          <TextField
            {...input}
            {...params}
            variant="filled"
            label={label}
            classes={classes}
            error={touched && invalid}
            helperText={touched && error}
            {...custom}
          />
        )}
      ></Autocomplete>
    </FormControl>
  );
};
