import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StepFieldsProps } from '../utils';
import DataTable from '../../create-component-structured-data/components/data-table';
import { FieldArray } from 'redux-form';
import React, { useMemo } from 'react';
//@ts-ignore
import countryList from 'react-select-country-list';


export const getStep8Fields = (): Array<StepFieldsProps> => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((el: any) => {
          return { value: el.label, label: el.label };
        }),
    []
  );

  const { t } = useTranslation(['fields']);
  return [
    {
      title: translations.headers['8_1_header_control_parameters'],
      fields: [
        {
          name: 'exposure_limits',
          component: TextFieldInputV2,
          label: translations.sections.exposure_limits
            ? translations.sections.exposure_limits
            : t('fields:exposure_limits'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'exposure_limits_structured_data',
          component: (
            <FieldArray
              name="exposure_limits_structured_data"
              formName={'exposure_limits_structured_data'}
              component={DataTable}
              translations={translations}
              fieldsNames={[
                'type',
                'description',
                'duration',
                'limit_value',
                'source',
                'comment',
                'action',
              ]}
              fieldsStyles={{
                type: { width: '15%' },
                description: { width: '25%' },
                duration: { width: '10%' },
                limit_value: { width: '10%' },
                source: { width: '15%' },
                comment: { width: '25%' },
              }}
              fieldsProps={{
                type: {
                  type: 'select',
                  options: [
                    { value: 'STEL', label: 'STEL' },
                    { value: 'TLV-STEL', label: 'TLV-STEL' },
                    { value: 'IOELV STEL', label: 'IOELV STEL' },
                    { value: 'IOELV-TWA', label: 'IOELV-TWA' },
                    { value: 'EU-IOELV', label: 'EU-IOELV' },
                    { value: 'TWA', label: 'TWA' },
                    { value: 'TLV-TWA', label: 'TLV-TWA' },
                    { value: 'WEL-TWA', label: 'WEL-TWA' },
                    { value: 'WEL-STEL', label: 'WEL-STEL' },
                    { value: 'WEL - SHORT TERM', label: 'WEL - SHORT TERM' },
                    { value: 'WEL - LONG TERM', label: 'WEL - LONG TERM' },
                    { value: 'OSHA PEL', label: 'OSHA PEL' },
                    { value: 'GV', label: 'GV' },
                  ],
                },
                source: {
                  type: 'select',
                  options: countryOptions,
                },
              }}
            />
          ),
          label: '',
          multiline: true,
          xs: 12,
          isStructuredDataField: true,
        },
        {
          name: 'occupational_limits',
          component: TextFieldInputV2,
          label: translations.sections.occupational_limits,
          multiline: true,
          xs: 12,
        },
        {
          name: 'stel',
          component: TextFieldInputV2,
          label: translations.sections.stel,
          multiline: true,
          xs: 12,
        },
        {
          name: 'long_term_exposure_limit',
          component: TextFieldInputV2,
          label: translations.sections.long_term_exposure_limit,
          multiline: true,
          xs: 12,
        },
        {
          name: 'exposure_time',
          component: TextFieldInputV2,
          label: translations.sections.exposure_time,
          multiline: true,
          xs: 12,
        },
        {
          name: 'monitoring_method',
          component: TextFieldInputV2,
          label: translations.sections.monitoring_method
            ? translations.sections.monitoring_method
            : t('fields:monitoring_method'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'dnel',
          component: TextFieldInputV2,
          label: translations.sections.dnel,
          multiline: true,
          xs: 12,
        },
        {
          name: 'pnec',
          component: TextFieldInputV2,
          label: translations.sections.pnec,
          multiline: true,
          xs: 12,
        },
        {
          name: 'biological_limits',
          component: TextFieldInputV2,
          label: translations.sections.biological_limits
            ? translations.sections.biological_limits
            : t('fields:biological_limits'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'required_monitoring',
          component: TextFieldInputV2,
          label: translations.sections.required_monitoring
            ? translations.sections.required_monitoring
            : t('fields:required_monitoring'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'route_of_exposure',
          component: TextFieldInputV2,
          label: translations.sections.route_of_exposure,
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['8_2_header_exposure_controls'],
      fields: [
        {
          name: 'skin_protection_description',
          component: TextFieldInputV2,
          label: translations.sections.skin_protection_description,
          multiline: true,
          xs: 12,
        },
        {
          name: 'occupational_exposure_control',
          component: TextFieldInputV2,
          label: translations.sections.occupational_exposure_control,
          multiline: true,
          xs: 12,
        },
        {
          name: 'large_scale',
          component: TextFieldInputV2,
          label: translations.sections.large_scale
            ? translations.sections.large_scale
            : t('fields:large_scale'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'small_scale',
          component: TextFieldInputV2,
          label: translations.sections.small_scale
            ? translations.sections.small_scale
            : t('fields:small_scale'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'material_of_gloves',
          component: TextFieldInputV2,
          label: translations.sections.material_of_gloves,
          xs: 12,
        },
        {
          name: 'penetration_time_of_gloves',
          component: TextFieldInputV2,
          label: translations.sections.penetration_time_of_gloves,
          multiline: true,
          xs: 12,
        },
        {
          name: 'protective_clothing',
          component: TextFieldInputV2,
          label: translations.sections.protective_clothing
            ? translations.sections.protective_clothing
            : t('fields:protective_clothing'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'protective_clothing_material',
          component: TextFieldInputV2,
          label: translations.sections.protective_clothing_material,
          multiline: true,
          xs: 12,
        },
        {
          name: 'engineering_controls',
          component: TextFieldInputV2,
          label: translations.sections.engineering_controls,
          multiline: true,
          xs: 12,
        },
        {
          name: 'recommended_filter_type',
          component: TextFieldInputV2,
          label: translations.sections.recommended_filter_type,
          multiline: true,
          xs: 12,
        },

        {
          name: 'personal_protection_symbols',
          component: TextFieldInputV2,
          label: translations.sections.personal_protection_symbols,
          multiline: true,
          xs: 12,
        },
        {
          name: 'other_protective_equipment',
          component: TextFieldInputV2,
          label: translations.sections.other_protective_equipment,
          multiline: true,
          xs: 12,
        },
        {
          name: 'exposure_protective_measures',
          component: TextFieldInputV2,
          label: translations.sections.exposure_protective_measures,
          multiline: true,
          xs: 12,
        },
        {
          name: 'environmental_exposure_controls',
          component: TextFieldInputV2,
          label: translations.sections.environmental_exposure_controls,
          multiline: true,
          xs: 12,
        },
        {
          name: 'other_exposure_info',
          component: TextFieldInputV2,
          label: translations.sections.other_exposure_info,
          multiline: true,
          xs: 12,
        },
      ],
    },
  ];
};

export interface ColumnsProps {
  id: string;
  title: string;
  minWidth: number;
  maxWidth?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
}

export const getColumns = (): Array<ColumnsProps> => {
  const { t } = useTranslation(['sds_authoring']);

  return [
    {
      id: 'link_ppe_icon',
      title: '',
      minWidth: 50,
      maxWidth: 60,
    },
    {
      id: 'short_desc',
      title: t('sds_authoring:name'),
      minWidth: 10,
      align: 'left',
    },
    {
      id: 'long_desc',
      title: t('sds_authoring:description'),
      minWidth: 150,
      maxWidth: 450,
      align: 'left',
    },
    {
      id: 'action',
      title: '',
      minWidth: 50,
    },
  ];
};
