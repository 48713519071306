import { connect, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Typography } from '@mui/material';
import useStyles from '../styles';
import SectionFields from './SectionFields';
import SubmitButton from '../submit-btn';
import { CustomFormProps } from '../utils';
import SaveAsButton from 'components/save-as-btn';
import { SDSInfo } from 'interfaces';

interface ExposureProtectionFormProps extends CustomFormProps {
  overwrittenDescriptions: any;
  languageCode: any;
  components: any;
  doNotIncludeStructuredDataFromComponents: any;
  componentStructuredData: any;
}

const ExposureProtectionForm = (
  props: ExposureProtectionFormProps &
    InjectedFormProps<{}, ExposureProtectionFormProps>
) => {
  const classes = useStyles();
  const {
    handleNext,
    dirty,
    overwrittenDescriptions,
    languageCode,
    components,
    doNotIncludeStructuredDataFromComponents,
    componentStructuredData,
    handleCopy,
  } = props;
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const form = useSelector((state: any) => state.form.exposureProtectionForm);
  const sdsInfo = useSelector((state: any) => state.pageReducer.sdsInfo);

  const handleSubmit = () => {
    if (dirty) {
      const respiratoryProtectionIds =
        form?.values?.respiratoryProtection?.length > 0
          ? form?.values?.respiratoryProtection?.map((el: any) => el.id)
          : sdsInfo?.respiratory_protection?.map((el: any) => el.id);
      const eyesProtectionIds =
        form?.values?.eyesProtection?.length > 0
          ? form?.values?.eyesProtection?.map((el: any) => el.id)
          : sdsInfo?.eyes_protection?.map((el: any) => el.id);
      const handsProtectionIds =
        form?.values?.handsProtection?.length > 0
          ? form?.values?.handsProtection?.map((el: any) => el.id)
          : sdsInfo?.hands_protection?.map((el: any) => el.id);
      const bodyProtectionIds =
        form?.values?.bodyProtection?.length > 0
          ? form?.values?.bodyProtection?.map((el: any) => el.id)
          : sdsInfo?.body_protection?.map((el: any) => el.id);

      handleNext({
        ...form.values,
        body_protection_input: bodyProtectionIds,
        hands_protection_input: handsProtectionIds,
        eyes_protection_input: eyesProtectionIds,
        respiratory_protection_input: respiratoryProtectionIds,
        protections_overwritten_descriptions: overwrittenDescriptions,
      });
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['8_header_exposure_controls']}
      </Typography>
      <form onSubmit={handleSubmit}>
        <SectionFields
          components={components}
          doNotIncludeStructuredDataFromComponents={
            doNotIncludeStructuredDataFromComponents
          }
          componentStructuredData={componentStructuredData}
          languageCode={languageCode}
        />
        <SubmitButton handleSubmit={handleSubmit} />
        <SaveAsButton handleSubmit={handleCopy} />

      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.exposureProtectionForm?.values) {
    const sdsInfo = state.pageReducer.sdsInfo as SDSInfo;
    return {
      initialValues: {
        exposure_limits: sdsInfo.exposure_limits,
        exposure_limits_structured_data:
          sdsInfo.exposure_limits_structured_data,
        exposure_controls_structured_data:
          sdsInfo.exposure_controls_structured_data,
        monitoring_method: sdsInfo.monitoring_method,
        occupational_limits: sdsInfo.occupational_limits,
        biological_limits: sdsInfo.biological_limits,
        dnel: sdsInfo.dnel,
        required_monitoring: sdsInfo.required_monitoring,
        large_scale: sdsInfo.large_scale,
        small_scale: sdsInfo.small_scale,
        protective_clothing: sdsInfo.protective_clothing,
        respiratory_protection_description:
          sdsInfo.respiratory_protection_description,
        eyes_protection_description:
          sdsInfo.eyes_protection_description,
        hands_protection_description:
          sdsInfo.hands_protection_description,
        body_protection_description:
          sdsInfo.body_protection_description,
        recommended_filter_type:
          sdsInfo.recommended_filter_type,
        stel: sdsInfo.stel,
        other_exposure_info: sdsInfo.other_exposure_info,
        long_term_exposure_limit:
          sdsInfo.long_term_exposure_limit,
        exposure_time: sdsInfo.exposure_time,
        route_of_exposure: sdsInfo.route_of_exposure,
        pnec: sdsInfo.pnec,
        personal_protection: sdsInfo.personal_protection,
        skin_protection_description:
          sdsInfo.skin_protection_description,
        occupational_exposure_control:
          sdsInfo.occupational_exposure_control,
        material_of_gloves: sdsInfo.material_of_gloves,
        penetration_time_of_gloves:
          sdsInfo.penetration_time_of_gloves,
        protective_clothing_material:
          sdsInfo.protective_clothing_material,
        engineering_controls: sdsInfo.engineering_controls,
        personal_protection_symbols:
          sdsInfo.personal_protection_symbols,
        other_protective_equipment:
          sdsInfo.other_protective_equipment,
        exposure_protective_measures:
          sdsInfo.exposure_protective_measures,
        environmental_exposure_controls:
          sdsInfo.environmental_exposure_controls,
        exposure_controls: sdsInfo.exposure_controls,
        respiratoryProtection: sdsInfo.respiratory_protection
          ? sdsInfo.respiratory_protection
          : [],
        eyesProtection: sdsInfo.eyes_protection
          ? sdsInfo.eyes_protection
          : [],
        handsProtection: sdsInfo.hands_protection
          ? sdsInfo.hands_protection
          : [],
        bodyProtection: sdsInfo.body_protection
          ? sdsInfo.body_protection
          : [],
        emergencyActions: sdsInfo.emergency_actions
          ? sdsInfo.emergency_actions
          : [],
      },
      sdsId: state.pageReducer.pdfId,
      components: state.form.ingredientsForm?.values?.ingredients
        ? state.form.ingredientsForm?.values?.ingredients
        : state.pageReducer?.sdsInfo?.components
        ? state.pageReducer?.sdsInfo?.components
        : [],
      doNotIncludeStructuredDataFromComponents:
        state.pageReducer.doNotIncludeStructuredDataFromComponents,
      componentStructuredData: state.pageReducer.structuredComponentData,
      overwrittenDescriptions: state.pageReducer.overwrittenPPEDescriptions,
      formEdited: state.pageReducer.formEdited,
      languageCode: state.form.section0Form?.values?.language,
    };
  }
  return {
    sdsId: state.pageReducer.pdfId,
    components: state.form.ingredientsForm?.values?.ingredients
      ? state.form.ingredientsForm?.values?.ingredients
      : state.pageReducer?.sdsInfo?.components
      ? state.pageReducer?.sdsInfo?.components
      : [],
    doNotIncludeStructuredDataFromComponents:
      state.pageReducer.doNotIncludeStructuredDataFromComponents,
    componentStructuredData: state.pageReducer.structuredComponentData,
    overwrittenDescriptions: state.pageReducer.overwrittenPPEDescriptions,
    formEdited: state.pageReducer.formEdited,
    languageCode: state.form.section0Form?.values?.language,
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, ExposureProtectionFormProps>({
    form: 'exposureProtectionForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(ExposureProtectionForm)
);
