import { useParams } from 'react-router-dom';
import { BASE_API_URL } from '../../api/constants';

const PDFDownloadViewerPage = () => {
  const params = useParams();

  return (
    <iframe
      width="99%"
      height="99%"
      src={`${BASE_API_URL}/sdsAuthoring/files/${
        params.id
      }/pdfView?token=${localStorage.getItem('access_token')}`}
    />
  );
};

export default PDFDownloadViewerPage;
