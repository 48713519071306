import { Delete, Save } from '@mui/icons-material';
import Edit from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Chip,
  FilledInput,
  IconButton,
  InputAdornment,
  Stack,
  TableCell,
  Typography,
} from '@mui/material';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOverwrittenHazardStatements,
  setFormEdited,
} from 'reducers/pageAction';
import { HazardCodeColumnsProps } from '../helper';

const EditableCell = ({ style, row }: { style: any; row: any }) => {
  return (
    <FormControl variant="filled" style={{ width: '100%' }}>
      <PreviewMode style={style} row={row} />
    </FormControl>
  );
};

const PreviewMode = ({ style, row }: { style: any; row: any }) => {
  const dispatch = useDispatch();
  const [statements, setStatements] = React.useState(row.statements);
  const [editMode, setEditMode] = React.useState(false);
  const overwrittenHazardStatements = useSelector(
    (state: any) => state.pageReducer.overwrittenHazardStatements
  );

  const { focused } = useFormControl() || {};

  useEffect(() => {
    setStatements(overwrittenHazardStatements[row.id] ?? row.statements);

    if (!focused && editMode) {
      dispatch(
        setOverwrittenHazardStatements({
          ...overwrittenHazardStatements,
          [row.id]: statements,
        })
      );
      dispatch(setFormEdited(true));
      setEditMode(false);
    }
  }, [focused]);

  if (!editMode) {
    return (
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Typography variant={style}>
          {overwrittenHazardStatements[row.id] ?? row.statements}
        </Typography>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setEditMode(true);
          }}
        >
          <Edit fontSize={'small'} />
        </IconButton>
      </Box>
    );
  }
  return (
    <FilledInput
      hiddenLabel
      value={statements}
      onChange={(e) => setStatements(e.target.value)}
      autoFocus
      fullWidth
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(
                setOverwrittenHazardStatements({
                  ...overwrittenHazardStatements,
                  [row.id]: statements,
                })
              );
              dispatch(setFormEdited(true));
              setEditMode(false);
            }}
            edge="end"
          >
            <Save />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

const HazardTableCellValue = ({
  column,
  row,
  onDelete,
}: {
  column: string;
  row: any;
  onDelete: () => void;
}) => {
  const preventions = [row.prevention, row.response, row.storage, row.disposal];

  if (column === 'pictogram_url') {
    return <img alt="" src={row.pictogram_url} width={50} height={50} />;
  }

  if (column === 'name') {
    return (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
        <Typography variant="subtitle1">{row.statement_code}</Typography>
        <EditableCell style="subtitle2" row={row} />
      </Box>
    );
  }
  if (column === 'description') {
    return <EditableCell style="subtitle1" row={row} />;
  }

  if (column === 'action') {
    return (
      <IconButton onClick={onDelete} sx={{ color: 'colors.text' }}>
        <Delete />
      </IconButton>
    );
  }

  if (column === 'prevention_statements') {
    return (
      <Stack style={{ flexWrap: 'wrap' }} direction="row" spacing={1}>
        {preventions.map((state, index) => {
          return state?.split(',')?.map((el: any) => {
            return el ? (
              <Chip
                style={{ margin: '3px' }}
                key={el}
                label={el}
                color={'primary'}
                variant="outlined"
              />
            ) : null;
          });
        })}
      </Stack>
    );
  }

  return <Typography variant="subtitle1">{row[column] ?? null}</Typography>;
};

export const HazardTableCell = ({
  index,
  column,
  row,
  onDelete,
}: {
  index: string;
  column: HazardCodeColumnsProps;
  row: any;
  onDelete: any;
}) => {
  return (
    <TableCell
      key={column.id + index}
      align={column.align ? column.align : 'center'}
      style={{
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        width: column.width,
      }}
    >
      <HazardTableCellValue column={column.id} row={row} onDelete={onDelete} />
    </TableCell>
  );
};
