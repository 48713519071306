import { Select, FormControl, MenuItem, InputLabel, Checkbox, ListItemText } from '@mui/material';

type Props = {
  label: string;
  input: any;
  meta: any;
  options: any;
  classes?: any;
  wrapperClassName?: any;
  valueField?: string;
  labelField?: string;
  style?: any;
  required?: boolean;
};

const MultipleSelectInput = ({
    input,
    label,
    meta: { touched, error },
    options,
    style,
    required,
    ...custom
  }: Props) => {
    const { classes, valueField, labelField } = custom;
  
    return (
      <FormControl
        classes={classes}
        error={touched && error}
        variant="filled"
        style={style}
      >
        <InputLabel>
          {label}
          {required ? '*' : ''}
        </InputLabel>
        <Select
          {...input}
          multiple
          value={Array.isArray(input.value) ? input.value : []} // Ensure value is always an array
          renderValue={(selected: any) =>
            selected
              .map((value: any) => {
                if (!valueField) return value;
                const option = options.find((opt: any) => opt[valueField] === value);
                return option && labelField ? option[labelField] : value;
              })
              .filter((val: any) => val !== undefined && val !== null) // Remove invalid entries
              .join(', ') // Use a separator for readability
          }
        >
          {options.map((item: any, index: number) => (
            <MenuItem key={index} value={valueField ? item[valueField] : item}>
              <Checkbox checked={input.value.indexOf(valueField ? item[valueField] : item) > -1} />
              <ListItemText primary={labelField ? item[labelField] : item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  
  export default MultipleSelectInput;
  