import React from 'react';
import { Provider } from 'react-redux';
import store from './reducers';
import RoutesV2 from './routes';
import AppThemeProvider from 'utils/AppThemeProvider';

function App() {
  return (
    <Provider store={store}>
      <AppThemeProvider>
        <RoutesV2 />
      </AppThemeProvider>
    </Provider>
  );
}

export default App;
