import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, FieldArray } from 'redux-form';
import { Box, Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { get } from 'api';
import OutlinedSection from '../outlined-section';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import { getOtherTransportFields, getStep13Fields } from './fields';
import { CustomFormProps } from '../utils';
import { IsoIconsField } from '../common/IsoIconsFields';
import { multipleClasses } from 'utils/helper';
import { useTranslation } from 'react-i18next';
import SaveAsButton from 'components/save-as-btn';

interface TransportIcon {
  id: string;
}

interface TransportDetails {
  un_number: string;
  shipping_name: string;
  transport_hazard_classes: string;
  packing_group: string;
}

export interface TransportRequirements {
  id: string;
  rid_transport_classes_icons_input?: string[];
  adn_transport_classes_icons_input?: string[];
  adr_transport_classes_icons_input?: string[];
  iata_transport_classes_icons_input?: string[];
  imdg_transport_classes_icons_input?: string[];
  imdg: TransportDetails;
  iata: TransportDetails;
  adr: TransportDetails;
  rid: TransportDetails;
  adn: TransportDetails;
  environmental_hazards: string;
  special_precautions_for_user: string;
  transport_in_bulk: string;
  other_transport_information: string;
}


const TransportRequirementsForm = (
  props: CustomFormProps & InjectedFormProps<{}, CustomFormProps>
) => {
  const classes = useStyles();
  const { handleNext, dirty, handleCopy } = props;
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const form = useSelector(
    (state: any) => state.form.transportRequirementsForm
  );
  const { t } = useTranslation();

  const [isoIcons, setIsoIcons] = React.useState([]);

  const sections = getStep13Fields();
  const otherSections = getOtherTransportFields();

  React.useEffect(() => {
    const getIsoIconsRequest = get('/sdsAuthoring/utils/isoIcons/', {
      type: 'T',
    });
    getIsoIconsRequest.then((response) => {
      if (response.status === 200) {
        setIsoIcons(response.data);
      }
    });
  }, []);


  const getTransportRequirements = () => {
    return {
      id: form.values.id,
      rid_transport_classes_icons_input: form.values.ridTransportIcons?.map(
        (el: TransportIcon) => el.id
      ),
      adn_transport_classes_icons_input: form.values.adnTransportIcons?.map(
        (el: TransportIcon) => el.id
      ),
      adr_transport_classes_icons_input: form.values.adrTransportIcons?.map(
        (el: TransportIcon) => el.id
      ),
      iata_transport_classes_icons_input: form.values.iataTransportIcons?.map(
        (el: TransportIcon) => el.id
      ),
      imdg_transport_classes_icons_input: form.values.imdgTransportIcons?.map(
        (el: TransportIcon) => el.id
      ),
      imdg: {
        un_number: form.values.imdg_un_number,
        shipping_name: form.values.imdg_shipping_name,
        transport_hazard_classes: form.values.imdg_transport_hazard_classes,
        packing_group: form.values.imdg_packing_group,
        environmental_hazards: form.values.imdg_environmental_hazards,
      },
      iata: {
        un_number: form.values.iata_un_number,
        shipping_name: form.values.iata_shipping_name,
        transport_hazard_classes: form.values.iata_transport_hazard_classes,
        packing_group: form.values.iata_packing_group,
        environmental_hazards: form.values.iata_environmental_hazards,

      },
      adr: {
        un_number: form.values.adr_un_number,
        shipping_name: form.values.adr_shipping_name,
        transport_hazard_classes: form.values.adr_transport_hazard_classes,
        packing_group: form.values.adr_packing_group,
        environmental_hazards: form.values.adr_environmental_hazards,
      },
      rid: {
        un_number: form.values.rid_un_number,
        shipping_name: form.values.rid_shipping_name,
        transport_hazard_classes: form.values.rid_transport_hazard_classes,
        packing_group: form.values.rid_packing_group,
        environmental_hazards: form.values.rid_environmental_hazards,
      },
      adn: {
        un_number: form.values.adn_un_number,
        shipping_name: form.values.adn_shipping_name,
        transport_hazard_classes: form.values.adn_transport_hazard_classes,
        packing_group: form.values.adn_packing_group,
        environmental_hazards: form.values.adn_environmental_hazards,
      },
      environmental_hazards: form.values.environmental_hazards,
      special_precautions_for_user: form.values.special_precautions_for_user,
      transport_in_bulk: form.values.transport_in_bulk,
      other_transport_information: form.values.other_transport_information,
    } as TransportRequirements;
  }

  const handleSubmit = () => {
    if (dirty) {
      handleNext({
        transport_requirements: getTransportRequirements()
      });
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['14_header_transport_info']}
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* {sections.map((section, index) => {
          return (
            <OutlinedSection key={index} title={section.title}>
              {section.fields.map((field, idx) => {
                return (
                  <Grid key={idx} item md={field.xs} xs={12}>
                    {field.isArray ? (
                      <FieldArray
                        name={field.name}
                        component={IsoIconsField}
                        availableIcons={isoIcons}
                      />
                    ) : (
                      <Field
                        name={field.name}
                        component={field.component ?? null}
                        label={field.label}
                        multiline={field.multiline ?? null}
                        classes={{ root: classes.textInput }}
                      />
                    )}
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        })} */}
        <Box>
          <Typography variant="h6" className={classes.title}>
            {t('sds_authoring:transport_information_table')}
          </Typography>
          <Card variant="outlined" style={{ padding: '20px' }}>
            <TableContainer className={classes.tableContainer}>
              <Table aria-label="" style={{ tableLayout: 'fixed' }}>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell className={classes.tableColumn}></TableCell>
                    <TableCell className={classes.tableColumn}>IMDG</TableCell>
                    <TableCell className={classes.tableColumn}>IATA</TableCell>
                    <TableCell className={classes.tableColumn}>ADR</TableCell>
                    <TableCell className={classes.tableColumn}>RID</TableCell>
                    <TableCell className={classes.tableColumn}>ADN</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sections.map((section, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className={multipleClasses([
                          classes.tableHeader,
                          classes.tableCell,
                        ])}
                      >
                        {section.title}
                      </TableCell>
                      {section.fields.map((field, idx) => (
                        <TableCell key={idx} className={classes.tableCell} style={{ verticalAlign: 'top' }}>
                          {field.icon_field_name ?
                            <>
                              <Field
                                name={field.name}
                                component={field.component ?? null}
                                label={field.label}
                                multiline={field.multiline ?? null}
                                classes={{ root: classes.textInput }}
                                sx={{ fontSize: '12px !important' }}
                              />
                              <br />
                              <FieldArray
                                name={field.icon_field_name}
                                component={IsoIconsField}
                                availableIcons={isoIcons}
                              />
                            </> :
                            <>
                              <Field
                                name={field.name}
                                component={field.component ?? null}
                                label={field.label}
                                multiline={field.multiline ?? null}
                                classes={{ root: classes.textInput }}
                                sx={{ fontSize: '12px !important' }}

                              />
                            </>}

                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Box>

        {otherSections.map((section, index) => {
          return (
            <OutlinedSection key={index} title={section.title}>

              <>
                {section.fields.map((field, idx) => {
                  return (
                    <Grid key={idx} item md={field.xs} xs={12}>
                      {field.isArray ? (
                        <FieldArray
                          name={field.name}
                          component={IsoIconsField}
                          availableIcons={isoIcons}
                        />
                      ) : (
                        <Field
                          name={field.name}
                          component={field.component ?? null}
                          // label={field.label}
                          multiline={field.multiline ?? null}
                          classes={{ root: classes.textInput }}
                        />
                      )}
                    </Grid>
                  );
                })}
              </>
            </OutlinedSection>

          );
        })}
        <SubmitButton handleSubmit={handleSubmit} />
        <SaveAsButton handleSubmit={handleCopy} />

      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (
    state.pageReducer.sdsInfo &&
    !state.form.transportRequirementsForm?.values
  ) {
    return {
      initialValues: {
        id: state.pageReducer.sdsInfo?.transport_requirements?.id ? state.pageReducer.sdsInfo.transport_requirements.id : null,
        adnTransportIcons: state.pageReducer.sdsInfo?.transport_requirements
          ?.adn_transport_classes_icons
          ? state.pageReducer.sdsInfo.transport_requirements
            .adn_transport_classes_icons
          : [],
        ridTransportIcons: state.pageReducer.sdsInfo?.transport_requirements
          ?.rid_transport_classes_icons
          ? state.pageReducer.sdsInfo.transport_requirements
            .rid_transport_classes_icons
          : [],
        adrTransportIcons: state.pageReducer.sdsInfo?.transport_requirements
          ?.adr_transport_classes_icons
          ? state.pageReducer.sdsInfo.transport_requirements
            .adr_transport_classes_icons
          : [],
        iataTransportIcons: state.pageReducer.sdsInfo?.transport_requirements
          ?.iata_transport_classes_icons
          ? state.pageReducer.sdsInfo.transport_requirements
            .iata_transport_classes_icons
          : [],
        imdgTransportIcons: state.pageReducer.sdsInfo?.transport_requirements
          ?.imdg_transport_classes_icons
          ? state.pageReducer.sdsInfo.transport_requirements
            .imdg_transport_classes_icons
          : [],
        imdg_un_number:
          state.pageReducer.sdsInfo?.transport_requirements?.imdg?.un_number,
        iata_un_number:
          state.pageReducer.sdsInfo?.transport_requirements?.iata?.un_number,
        adr_un_number:
          state.pageReducer.sdsInfo?.transport_requirements?.adr?.un_number,
        rid_un_number:
          state.pageReducer.sdsInfo?.transport_requirements?.rid?.un_number,
        adn_un_number:
          state.pageReducer.sdsInfo?.transport_requirements?.adn?.un_number,
        imdg_shipping_name:
          state.pageReducer.sdsInfo?.transport_requirements?.imdg
            ?.shipping_name,
        iata_shipping_name:
          state.pageReducer.sdsInfo?.transport_requirements?.iata
            ?.shipping_name,
        adr_shipping_name:
          state.pageReducer.sdsInfo?.transport_requirements?.adr?.shipping_name,
        rid_shipping_name:
          state.pageReducer.sdsInfo?.transport_requirements?.rid?.shipping_name,
        adn_shipping_name:
          state.pageReducer.sdsInfo?.transport_requirements?.adn?.shipping_name,
        imdg_transport_hazard_classes:
          state.pageReducer.sdsInfo?.transport_requirements?.imdg
            ?.transport_hazard_classes,
        iata_transport_hazard_classes:
          state.pageReducer.sdsInfo?.transport_requirements?.iata
            ?.transport_hazard_classes,
        adr_transport_hazard_classes:
          state.pageReducer.sdsInfo?.transport_requirements?.adr
            ?.transport_hazard_classes,
        rid_transport_hazard_classes:
          state.pageReducer.sdsInfo?.transport_requirements?.rid
            ?.transport_hazard_classes,
        adn_transport_hazard_classes:
          state.pageReducer.sdsInfo?.transport_requirements?.adn
            ?.transport_hazard_classes,
        imdg_packing_group:
          state.pageReducer.sdsInfo?.transport_requirements?.imdg
            ?.packing_group,
        iata_packing_group:
          state.pageReducer.sdsInfo?.transport_requirements?.iata
            ?.packing_group,
        adr_packing_group:
          state.pageReducer.sdsInfo?.transport_requirements?.adr?.packing_group,
        rid_packing_group:
          state.pageReducer.sdsInfo?.transport_requirements?.rid?.packing_group,
        adn_packing_group:
          state.pageReducer.sdsInfo?.transport_requirements?.adn?.packing_group,
        imdg_environmental_hazards: state.pageReducer.sdsInfo?.transport_requirements?.imdg?.environmental_hazards,
        iata_environmental_hazards: state.pageReducer.sdsInfo?.transport_requirements?.iata?.environmental_hazards,
        adr_environmental_hazards: state.pageReducer.sdsInfo?.transport_requirements?.adr?.environmental_hazards,
        rid_environmental_hazards: state.pageReducer.sdsInfo?.transport_requirements?.rid?.environmental_hazards,
        adn_environmental_hazards: state.pageReducer.sdsInfo?.transport_requirements?.adn?.environmental_hazards,
        environmental_hazards:
          state.pageReducer.sdsInfo?.transport_requirements
            ?.environmental_hazards,
        special_precautions_for_user:
          state.pageReducer.sdsInfo?.transport_requirements
            ?.special_precautions_for_user,
        transport_in_bulk:
          state.pageReducer.sdsInfo?.transport_requirements?.transport_in_bulk,
        other_transport_information:
          state.pageReducer.sdsInfo?.transport_requirements
            ?.other_transport_information,
        sds_transport_info: state.pageReducer.sdsInfo?.sds_transport_info,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'transportRequirementsForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(TransportRequirementsForm)
);
