import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
//@ts-ignore
import { Document, Page, pdfjs } from 'react-pdf';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getPDFFile, post } from 'api';
import { ConfirmRemoveApprove, Loader } from 'components';
import { useStyles } from './styles';

import { setSDSInfo } from 'reducers/pageAction';
import { renderSnackbar } from 'utils';
import { isMobile } from 'utils/helper';
import { LOGO_POSITION_OPTIONS } from '../../../constants';
import { updateLogoPosition } from 'api/patch';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EventsTable = ({ events }: { events: any }) => {
  const { t } = useTranslation(['events_table']);

  return (
    <TableContainer>
      <Table
        size="small"
        aria-label="a dense table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: 130 }}>
              {t('events_table:date')}
            </TableCell>
            <TableCell>{t('events_table:action')}</TableCell>
            <TableCell>{t('events_table:user')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((row: any) => {
            return (
              <TableRow key={row.id}>
                <TableCell>{row.created_at}</TableCell>
                <TableCell>{t(`events_table:${row.event}`)}</TableCell>
                <TableCell>{row.created_by}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const PreviewPDFFile = ({ pdfFileId }: { pdfFileId: any }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sdsInfo = useSelector((state: any) => state.pageReducer.sdsInfo);
  const { t } = useTranslation(['actions', 'sds_authoring']);
  const [fileLoading, setFileLoading] = React.useState(false);
  const [fileContent, setFileContent] = React.useState(null);
  
  const [logoPosition, setLogoPosition] = React.useState(sdsInfo.logo_position)
  const [openConfirmRemoveApprovePopup, setOpenConfirmRemoveApprovePopup] =
    React.useState(false);
  const completedSteps = useSelector(
    (state: any) => state.pageReducer.completedAuthoringStep
  );

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
    setNumPages(numPages);
  }

  const approvePDF = () => {
    if (completedSteps.length < 16) {
      renderSnackbar([t('sds_authoring:review_sections_message')]);
      return;
    }
    const approvePDFRequest = post(`/sdsAuthoring/files/${pdfFileId}/approve/`);
    approvePDFRequest.then((response) => {
      if (response.status === 200) {
        dispatch(setSDSInfo(response.data));
      }
    });
  };

  const removeApprovePDF = () => {
    const removeApproveRequest = post(
      `/sdsAuthoring/files/${pdfFileId}/removeApprove/`
    );
    removeApproveRequest.then((response) => {
      if (response.status === 200) {
        dispatch(setSDSInfo(response.data));
      }
    });
  };
  
  const saveLogoPosition = (preSelectPosition: string) => {
    updateLogoPosition(pdfFileId, preSelectPosition)
    .then((response) => {
      if (response?.status === 200) {
        setLogoPosition(preSelectPosition)
        dispatch(setSDSInfo(response.data));
      }
    });
  }

  React.useEffect(() => {
    const preSelectPosition = localStorage.getItem('logoPosition')
    if (
        !logoPosition &&
        preSelectPosition &&
        LOGO_POSITION_OPTIONS.map(el => el.value).indexOf(preSelectPosition) != -1
    ) {
      saveLogoPosition(preSelectPosition)
    }
  }, [])
  
  React.useEffect(() => {
    if (pdfFileId) {
      setFileLoading(true);
      const getPDFFileDRequest = getPDFFile(pdfFileId, logoPosition);
      getPDFFileDRequest.then((response) => {
        if (response.status === 200) {
          setFileLoading(false);
          setFileContent(response.data.sds_file);
        }
      });
    }
  }, [pdfFileId, logoPosition]);

  return (
    <>
      {!fileLoading && fileContent ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div className={classes.pdfPreviewAndSelector}>
            <FormControl className={classes.logoPositionSelectorWrapper}>
              <InputLabel id="logo_position_selector_label">{t("sds_authoring:logo_position")}</InputLabel>
              <Select
                labelId="logo_position_selector_selector_label"
                id="logo_position_selector_selector"
                value={logoPosition}
                label="Logo position"
                onChange={(event) =>  {
                  localStorage.setItem('logoPosition', event.target.value)
                  saveLogoPosition(event.target.value)
                }}
              >
                {LOGO_POSITION_OPTIONS.map(option => (
                  <MenuItem value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Paper variant="outlined" className={classes.pdfWrapper}>
              <div className={classes.paginationButtons}>
                <Button
                  onClick={() => setPageNumber(pageNumber - 1)}
                  disabled={pageNumber === 1}
                >
                  {t('actions:prev')}
                </Button>
                <Button
                  onClick={() => setPageNumber(pageNumber + 1)}
                  disabled={pageNumber === numPages}
                >
                  {t('actions:next')}
                </Button>
              </div>
              <Document
                loading={<Loader />}
                file={`data:application/pdf;base64,${fileContent}`}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {isMobile() ? (
                  <Page pageNumber={pageNumber} width={window.innerWidth - 10} />
                ) : (
                  <Page pageNumber={pageNumber}/>
                )}
              </Document>
            </Paper>
          </div>
          <div className={classes.approveBlockWrapper}>
            {sdsInfo.status === 'approved' ? (
              <Button variant='outlined' onClick={() => setOpenConfirmRemoveApprovePopup(true)}>
                {t('actions:remove_approval')}
              </Button>
            ) : sdsInfo.status === 'draft' ? (
              <>
                <Button
                  variant="contained"
                  onClick={approvePDF}
                  sx={{ marginBottom: '20px' }}
                >
                  {t('actions:approve')}
                </Button>
                <Alert severity={'info'}>
                  <AlertTitle>{t('sds_authoring:approve_info')}</AlertTitle>
                </Alert>
              </>
            ) : null}
            {sdsInfo.events?.length > 0 && (
              <EventsTable events={sdsInfo.events} />
            )}
          </div>
        </Box>
      ) : (
        <Loader />
      )}
      {openConfirmRemoveApprovePopup && (
        <ConfirmRemoveApprove
          open={openConfirmRemoveApprovePopup}
          onClose={() => setOpenConfirmRemoveApprovePopup(false)}
          onSubmit={() => {
            removeApprovePDF();
            setOpenConfirmRemoveApprovePopup(false);
          }}
        />
      )}
    </>
  );
};

export default PreviewPDFFile;
