import {
  ArchiveRounded,
  DeleteRounded,
  NoteAdd,
  PictureAsPdf,
} from '@mui/icons-material';
import { TableCell, Tooltip } from '@mui/material';
import StatusLabel from 'components/status-label';
import { OUR_SDS_STATUS } from 'enums/our-sds-status.enum';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCountries, OurSDSColumnsProps } from '../helper';
import useStyles from './styles';
import { UnarchiveRounded } from '@material-ui/icons';

const SDSTableCellValue = ({ column, row }: { column: string; row: any }) => {
  const language = useSelector((state: any) => state.pageReducer.languageCode);
  const { t } = useTranslation(['languages']);
  const countries = getCountries();

  if (column === 'status') {
    const status = row.status;
    if (status === OUR_SDS_STATUS.ARCHIVED) {
      return <StatusLabel color={'amber'} label={status} />;
    }
    if (status === OUR_SDS_STATUS.APPROVED) {
      return <StatusLabel color={'success'} label={status} />;
    }
    if (status === OUR_SDS_STATUS.DELETED) {
      return <StatusLabel color={'red'} label={status} />;
    }
    if (status === OUR_SDS_STATUS.DEPRECATED_VERSION) {
      return <StatusLabel color={'purple'} label={status} />;
    }
    if (status === OUR_SDS_STATUS.READY_FOR_APPROVAL) {
      return <StatusLabel color={'primary'} label={status} />;
    }
    return <StatusLabel color={'secondary'} label={status} />;
  }

  if (column === 'language') {
    return t(`languages:${row.language}`);
  }
  if (column === 'country') {
    return countries.getName(row.sds_country, language) ?? null;
  }
  if (column === 'supplier_name') {
    return row.company_name;
  }

  return row[column] ?? null;
};

const SDSTableCellAction = ({
  row,
  setSelectedSDS,
  setOpenArchivePopup,
  setOpenDeletePopup,
  setOpenCreateRevisionPopup,
  setOpenUnArchivePopup,
}: {
  row: any;
  setSelectedSDS: (value: any) => void;
  setOpenArchivePopup: (value: boolean) => void;
  setOpenDeletePopup: (value: boolean) => void;
  setOpenCreateRevisionPopup: (value: boolean) => void;
  setOpenUnArchivePopup: (value: boolean) => void;
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['tooltips']);

  return (
    <div className={classes.tableActions}>
      <Tooltip title={t('tooltips:open_tooltip')} describeChild>
        <a className={classes.pdfIcon} href={`/pdf/${row.id}`} target="_blank">
          <PictureAsPdf />
        </a>
      </Tooltip>

      {row.status !== 'archived' && (
        <Tooltip title={t('tooltips:move_to_archive')} describeChild>
          <ArchiveRounded
            fill={'#0d324f'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedSDS(row);
              setOpenArchivePopup(true);
            }}
          />
        </Tooltip>
      )}
      {row.status === 'archived' && (
        <Tooltip title={t('tooltips:remove_from_archive')} describeChild>
          <UnarchiveRounded
            fill={'#0d324f'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedSDS(row);
              setOpenUnArchivePopup(true);
            }}
          />
        </Tooltip>
      )}
      {row.status === 'draft' && (
        <Tooltip title={t('tooltips:mark_as_deleted')} describeChild>
          <DeleteRounded
            fill={'#0d324f'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedSDS(row);
              setOpenDeletePopup(true);
            }}
          />
        </Tooltip>
      )}
      {row.status === 'approved' && (
        <Tooltip title={t('actions:create_new_revision')} describeChild>
          <NoteAdd
            fill={'#0d324f'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedSDS(row);
              setOpenCreateRevisionPopup(true);
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export const SDSTableCell = ({
  index,
  column,
  row,
  setSelectedSDS,
  setOpenArchivePopup,
  setOpenDeletePopup,
  setOpenCreateRevisionPopup,
  setOpenUnArchivePopup,
}: {
  index: number;
  column: OurSDSColumnsProps;
  row: any;
  setSelectedSDS: (value: any) => void;
  setOpenArchivePopup: (value: boolean) => void;
  setOpenDeletePopup: (value: boolean) => void;
  setOpenCreateRevisionPopup: (value: boolean) => void;
  setOpenUnArchivePopup: (value: boolean) => void;
}) => {
  if (column.id === 'action')
    return (
      <TableCell key={column.id + index} onClick={(e) => e.stopPropagation()}>
        <SDSTableCellAction
          row={row}
          setSelectedSDS={setSelectedSDS}
          setOpenArchivePopup={setOpenArchivePopup}
          setOpenDeletePopup={setOpenDeletePopup}
          setOpenCreateRevisionPopup={setOpenCreateRevisionPopup}
          setOpenUnArchivePopup={setOpenUnArchivePopup}
        />
      </TableCell>
    );

  return (
    <TableCell
      key={column.id + index}
      align={column.align ? column.align : 'center'}
      style={{
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        width: column.width,
        padding: '20px 10px',
      }}
    >
      <SDSTableCellValue column={column.id} row={row} />
    </TableCell>
  );
};
