import HeaderV2 from 'components/header/Header';
import useStyles from './styles';

const Layout = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user?: any;
}) => {
  const location = window.location.pathname;

  if (location.includes('login')) return <>{children}</>;

  return <AuthenticatedLayout>{children}</AuthenticatedLayout>;
};

const AuthenticatedLayout = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeaderV2 />
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Layout;
