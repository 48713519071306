import { MenuItem, TextField } from '@mui/material';

const SelectField = ({
  label,
  input,
  options,
  meta: { touched, invalid, error },
  ...custom
}: {
  label?: string;
  input?: any;
  meta?: any;
  custom: any;
  options?: any;
}) => {
  return (
    <TextField
      hiddenLabel
      id="outlined-select"
      variant={'filled'}
      size="small"
      select
      {...input}
      {...custom}
    >
      {options.map((option: any) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectField;
