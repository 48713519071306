import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  formRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  inputWrapper: {
    width: '45%',
  },
  submitBtn: {
    backgroundColor: '#0d324f',
    color: '#fff',
  },
  textInput: {
    width: '100%',
  },
});

export default useStyles;
