import { TableCell } from '@mui/material';

export const DataTableHeader = ({
  translations,
  key,
  fieldsStyles,
  fieldsName,
}: {
  translations: any;
  key: number;
  fieldsStyles: any;
  fieldsName: string;
}) => {
  if (fieldsName === 'action') {
    return <TableCell sx={{ minWidth: '40px' }} />;
  }

  return (
    <TableCell key={key} style={fieldsStyles[fieldsName]}>
      {translations?.layout[`${fieldsName}`]
        ? translations?.layout[`${fieldsName}`]
        : fieldsName}
    </TableCell>
  );
};
