import {
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { get } from 'api';
import { Loader, SearchBar, SelectAdditionalDataPopup } from 'components';
import DesktopDialog from 'components/dialog/Dialog';

import { PictureAsPdfRounded } from '@mui/icons-material';
import { availableLanguages } from '../../constants';
import { PopupProps } from './PopupsInterface';
import useStyles from './styles';
import { isValidNumber } from 'utils/helper';

interface SelectComponentsPopupProps extends PopupProps {
  languageCode: string;
}

const SelectComponentsPopup = React.memo(
  ({ onClose, open, onSubmit, languageCode }: SelectComponentsPopupProps) => {
    const classes = useStyles();
    const { t } = useTranslation(['popups', 'sds_authoring', 'actions']);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [searchValue, setSearchValue] = React.useState('');
    const [supplierSearchValue, setSupplierSearchValue] = React.useState('');
    const [showNoDataMessage, setShowNoDataMessage] = React.useState(false);
    const [openAddAdditionalDataPopup, setOpenAddAdditionalDataPopup] =
      React.useState(false);
    const [selectedComponentData, setSelectedComponentData] =
      React.useState(null);
    const [selectedLanguage, setSelectedLanguage] =
      React.useState(languageCode);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [componentsPaginatedData, setComponentsPaginatedData] =
      React.useState({
        results: [],
        count: 10,
      });
    const [selectedComponents, setSelectedComponents] = React.useState<
      Array<any>
    >([]);

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(event.target.value);
      setPage(0);
    };

    const handleSelect = (row: any) => {
      if (selectedComponents.find((el: any) => el.id === row.id)) {
        setSelectedComponents(
          selectedComponents.filter((item: any) => item.id !== row.id)
        );
      } else {
        setSelectedComponents([row, ...selectedComponents]);
      }
    };
    const handleLanguageChange = (event: SelectChangeEvent<any>) => {
      setSelectedLanguage(event.target.value);
    };

    const handleSearchComponents = (
      page: number,
      rows: number,
      search: string,
      supplierSearch?: string
    ) => {
      if (search || supplierSearch) {
        setLoading(true);
        const getCreatedPDFs = get(`/sdsAuthoring/utils/components`, {
          search: search,
          supplier_search: supplierSearch,
          page: page + 1,
          page_size: rows,
          language_code: selectedLanguage,
          add_additional_data: true,
          search_by_id: isValidNumber(search) ? true : '',
        });
        getCreatedPDFs.then((response) => {
          if (response.status === 200) {
            setComponentsPaginatedData(response.data);
            if (response.data.results.length === 0) {
              setShowNoDataMessage(true);
            }
            setLoading(false);
          }
        });
      }
    };

    React.useEffect(() => {
      handleSearchComponents(page, rowsPerPage, searchValue);
    }, [page, rowsPerPage]);

    if (!open) {
      return null;
    }

    return (
      <DesktopDialog
        classes={{ paper: classes.selectComponentsPopup }}
        onClose={() => onClose()}
        title={t('popups:search_for_components')}
        maxWidth={'xl'}
        fullWidth={true}
      >
        <Box sx={{ display: 'flex', gap: '20px', mb: '20px' }}>
          <SearchBar
            id="location-management-add-substance-search-input"
            placeHolder={t('popups:select_components_placeholder')}
            onSearch={(text) => {
              handleSearchComponents(0, rowsPerPage, text, supplierSearchValue);
            }}
            onChange={(value) => setSearchValue(value)}
          />
          <SearchBar
            id="location-management-add-substance-search-supplier-input"
            placeHolder={'Supplier search'}
            onSearch={(text) => {
              handleSearchComponents(0, rowsPerPage, searchValue, text);
            }}
            onChange={(value) => setSupplierSearchValue(value)}
          />
          <FormControl variant="standard">
            <InputLabel>{t('sds_authoring:language')}</InputLabel>
            <Select
              id="language"
              labelId="language-input-label"
              value={selectedLanguage}
              label={t('sds_authoring:language')}
              className={classes.selectInput}
              onChange={handleLanguageChange}
            >
              <MenuItem key={99999} value={'all'}>
                {t('sds_authoring:all')}
              </MenuItem>
              {availableLanguages.map((el, i) => {
                return (
                  <MenuItem key={i} value={el.value}>
                    {el.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <div className={classes.content}>
          {loading ? (
            <Loader />
          ) : componentsPaginatedData.results?.length > 0 ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>{t('sds_authoring:component_name')}</TableCell>
                    <TableCell>{t('sds_authoring:supplier_name')}</TableCell>
                    <TableCell>{t('sds_authoring:cas_number')}</TableCell>
                    <TableCell>{t('sds_authoring:ec_number')}</TableCell>
                    <TableCell>{t('sds_authoring:reach_number')}</TableCell>
                    <TableCell>{t('sds_authoring:revision_date')}</TableCell>
                    <TableCell>dsf</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {componentsPaginatedData.results.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Checkbox
                          color={'primary'}
                          onClick={() => handleSelect(row)}
                          checked={selectedComponents.find(
                            (el) => el.id === row.id
                          )}
                        />
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold' }}
                        component="th"
                        scope="row"
                      >
                        {row.sds_pdf_product_name}
                      </TableCell>
                      <TableCell>{row.sds_pdf_manufacture_name}</TableCell>
                      <TableCell>{row.cas_no}</TableCell>
                      <TableCell>{row.ec_no}</TableCell>
                      <TableCell>{row.reach_no}</TableCell>
                      <TableCell>{row.sds_pdf_revision_date}</TableCell>
                      <TableCell>
                        <Tooltip
                          title={t('tooltips:open_tooltip')}
                          describeChild
                        >
                          <a
                            style={{ color: '#0d324f', height: '24px' }}
                            href={row.public_view_url}
                            target="_blank"
                          >
                            <PictureAsPdfRounded style={{ fill: '#0d324f' }} />
                          </a>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {row.additional_data &&
                          Object.keys(row.additional_data).length > 0 && (
                            <>
                              <Tooltip
                                describeChild
                                title={t('tooltips:add_more_data')}
                              >
                                <Button
                                  onClick={() => {
                                    setSelectedComponentData(
                                      row.additional_data
                                    );
                                    setOpenAddAdditionalDataPopup(true);
                                  }}
                                >
                                  {t('actions:add_more_data')}
                                </Button>
                              </Tooltip>
                            </>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      count={componentsPaginatedData.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={t('sds_authoring:rows_per_page')}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : null}
        </div>
        <div className={classes.actionWrapper}>
          <Button
            variant={'contained'}
            color={'primary'}
            disabled={selectedComponents.length === 0}
            onClick={() => {
              onSubmit(selectedComponents);
            }}
            className={classes.button}
          >
            {t('actions:add')}
          </Button>
          <Button
            onClick={onClose}
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
          >
            {t('actions:cancel')}
          </Button>
        </div>
        {openAddAdditionalDataPopup && (
          <SelectAdditionalDataPopup
            data={selectedComponentData}
            open={openAddAdditionalDataPopup}
            onClose={() => {
              setOpenAddAdditionalDataPopup(false);
              setSelectedComponentData(null);
            }}
          />
        )}
      </DesktopDialog>
    );
  }
);

export default SelectComponentsPopup;
