import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme?: any) => ({
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.colors.primaryLight,
    display: 'flex',
  },
  loginCard: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.colors.light,
    boxShadow: '0px 2px 10px rgba(58, 53, 65, 0.1)',
    borderRadius: '6px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  loginLogo: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 15,
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    fontSize: 24,
    color: theme.palette.colors.light,
  },
  loginCardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 47px 48px 47px',
    gap: '10px',
  },
  welcomeTitle: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 600,
  },
  welcomeContent: {
    margin: '30px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  welcomeText: {
    fontSize: '18px',
    fontWeight: 600,
    color: theme.palette.colors.text,
  },
  welcomeSubtext: {
    fontSize: '14px',
    color: theme.palette.colors.textLight,
  },
}));

export default useStyles;
