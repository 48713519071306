import { Button, Checkbox } from '@mui/material';

import DesktopDialog from 'components/dialog/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import HazardCodePanel from '../sds-authoring-forms-v2/hazards-codes-form/hazard-codes-panel';
import { PopupProps } from './PopupsInterface';
import useStyles from './styles';
import { SearchBar } from 'components';

interface SelectPrecautionsCodesPopupProps extends PopupProps {
  choices: any;
  defaultSelectedCodes: any;
}

const SelectPrecautionsCodesPopup = React.memo(
  ({
    choices,
    defaultSelectedCodes,
    onClose,
    open,
    onSubmit,
  }: SelectPrecautionsCodesPopupProps) => {
    const classes = useStyles();
    const { t } = useTranslation(['actions', 'popups']);
    const [codes, setCodes] = React.useState(choices);
    const [searched, setSearched] = React.useState('');
    const [selectedCodes, setSelectedCodes] =
      React.useState(defaultSelectedCodes);

    const requestSearch = (searchedVal: string) => {
      const filteredCodes = choices.filter((row: any) => {
        return row.statement_code
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      });
      setCodes(filteredCodes);
    };

    const handleSelect = (value: any) => {
      if (selectedCodes.includes(value)) {
        setSelectedCodes(selectedCodes.filter((item: any) => item !== value));
      } else {
        setSelectedCodes([value, ...selectedCodes]);
      }
    };

    if (!open) {
      return null;
    }

    return (
      <DesktopDialog
        classes={{ paper: classes.popup }}
        onClose={() => onClose()}
        title={t('popups:select_p_codes')}
        disableEnforceFocus
        maxWidth={'md'}
      >
        <div className={classes.content}>
          <div style={{ width: '80%', marginBottom: '20px' }}>
            <SearchBar
              id={'search-p-codes'}
              onChange={requestSearch}
              onSearch={requestSearch}
              onClear={() => {
                setSearched('');
                setCodes(choices);
              }}
            />
          </div>
          <Virtuoso
            style={{ height: '650px', width: '100%' }}
            totalCount={codes.length}
            itemContent={(index, el) => (
              <div
                key={index}
                style={{ justifyContent: 'center' }}
                className={classes.rowItem}
              >
                {codes?.[index] && (
                  <>
                    <Checkbox
                      color={'primary'}
                      onClick={() => handleSelect(codes[index].id)}
                      checked={selectedCodes.includes(codes[index].id)}
                    />
                    <HazardCodePanel hazard={codes[index]} />
                  </>
                )}
              </div>
            )}
          />
        </div>
        <div className={classes.actionWrapper}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              onSubmit(selectedCodes);
            }}
            className={classes.button}
          >
            {t('actions:add')}
          </Button>
          <Button
            onClick={onClose}
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
          >
            {t('actions:cancel')}
          </Button>
        </div>
      </DesktopDialog>
    );
  }
);

export default SelectPrecautionsCodesPopup;
