import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentWrapper: {
    width: '60%',
    marginTop: '40px',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    width: '400px',
  },
  infoRowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userInfoHeader: {
    width: '150px',
  },
  sdsFilesBlock: {
    marginTop: '70px',
  },
});

export default useStyles;
