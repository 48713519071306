import { Button, Checkbox, Typography } from '@mui/material';
import DesktopDialog from 'components/dialog/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { PopupProps } from './PopupsInterface';
import useStyles from './styles';

interface SelectRegulationsPopupProps extends PopupProps {
  choices: any;
  defaultSelectedCodes?: any;
  selected: any;
}

const SelectRegulationsPopup = React.memo(
  ({
    choices,
    selected,
    onClose,
    open,
    onSubmit,
  }: SelectRegulationsPopupProps) => {
    const classes = useStyles();
    const { t } = useTranslation(['popups', 'actions']);

    const [selectedRegulations, setSelectedRegulations] =
      React.useState(selected);

    const handleSelect = (value: any) => {
      if (selectedRegulations.includes(value)) {
        setSelectedRegulations(
          selectedRegulations.filter((item: any) => item !== value)
        );
      } else {
        setSelectedRegulations([value, ...selectedRegulations]);
      }
    };

    if (!open) {
      return null;
    }

    return (
      <DesktopDialog
        classes={{ paper: classes.popup }}
        onClose={onClose}
        title={t('popups:select_regulations')}
        disableEnforceFocus
      >
        <div className={classes.content}>
          <Virtuoso
            style={{ height: '650px', width: '100%' }}
            totalCount={choices.length}
            itemContent={(index, el) => {
              const regulationItem = choices[index];
              return (
                <div key={index} className={classes.rowItem}>
                  <Checkbox
                    color={'primary'}
                    onClick={() => handleSelect(choices[index].id)}
                    checked={selectedRegulations.includes(choices[index].id)}
                  />
                  <Typography>{`${regulationItem.literal}`}</Typography>
                </div>
              );
            }}
          />
        </div>
        <div className={classes.actionWrapper}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              onSubmit(selectedRegulations);
            }}
            className={classes.button}
          >
            {t('actions:add')}
          </Button>
          <Button
            onClick={onClose}
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
          >
            {t('actions:cancel')}
          </Button>
        </div>
      </DesktopDialog>
    );
  }
);

export default SelectRegulationsPopup;
