import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    popup: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            minWidth: '95%',
        },
        '& .MuiDialogContent-root': {
            minWidth: '100%',
            padding: 0,
        },
    },
    actionWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        gap: 20,
        margin: '20px 0px',
    }
}));

export default useStyles;
