import { Button, Checkbox, Typography } from '@material-ui/core';
import { getPPEItems } from 'api';
import DesktopDialog from 'components/dialog/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PopupProps } from './PopupsInterface';
import useStyles from './styles';

interface SelectPPEItemsPopupProps extends PopupProps {
  selected: any;
  ppeCategories: any;
  languageCode: string;
}

const SelectPPEItemsPopup = React.memo(
  ({
    title,
    selected,
    onClose,
    open,
    onSubmit,
    ppeCategories,
    languageCode,
  }: SelectPPEItemsPopupProps) => {
    const classes = useStyles();
    const { t } = useTranslation(['popups', 'actions']);
    const [ppeItems, setPPEItems] = React.useState([]);
    const [selectedItems, setSelectedItems] = React.useState(selected);

    const handleSelect = (value: any) => {
      if (selectedItems.includes(value)) {
        setSelectedItems(selectedItems.filter((item: any) => item !== value));
      } else {
        setSelectedItems([value, ...selectedItems]);
      }
    };

    React.useEffect(() => {
      const getPPEItemsRequest = getPPEItems(ppeCategories, languageCode);
      getPPEItemsRequest.then((response) => {
        if (response.status === 200) {
          setPPEItems(response.data);
        }
      });
    }, []);

    if (!open) {
      return null;
    }

    return (
      <DesktopDialog
        classes={{ paper: classes.selectPPEPopup }}
        onClose={() => onClose()}
        title={title}
        disableEnforceFocus
      >
        <div className={classes.content}>
          <div style={{ padding: '0px 15px', width: 'calc(100% - 30px)' }}>
            {ppeItems.map((el: any) => (
              <div className={classes.ppeItemWrapper}>
                <Checkbox
                  color={'primary'}
                  onClick={() => handleSelect(el.id)}
                  checked={selectedItems.includes(el.id)}
                />
                <img
                  src={el.link_ppe_icon}
                  alt={''}
                  className={classes.ppeIcon}
                />
                <div>
                  <Typography>{el.long_desc}</Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.actionWrapper}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              onSubmit(
                ppeItems.filter((el: any) => selectedItems.includes(el.id))
              );
            }}
            className={classes.button}
          >
            {t('actions:add')}
          </Button>
          <Button
            onClick={onClose}
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
          >
            {t('actions:cancel')}
          </Button>
        </div>
      </DesktopDialog>
    );
  }
);

export default SelectPPEItemsPopup;
