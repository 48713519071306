import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const StatusLabel = ({
  label,
  color,
  style = null,
}: {
  label: string | ReactElement;
  color?: any;
  style?: any;
}) => {
  const { t } = useTranslation(['status_choices']);

  return (
    <Button
      size={'small'}
      style={{
        borderRadius: 20,
        padding: '10px',
        minWidth: '100px',
        ...style,
      }}
      variant={'outlined'}
      color={color}
    >
      {t(`status_choices:${label}`)}
    </Button>
  );
};

export default StatusLabel;
