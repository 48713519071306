import { Add, Clear } from '@mui/icons-material';
import { Box, Button, Chip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddFilterPopup } from '../add-filter-popup';
import useStyles from './style';
import { OUR_COMPONENTS_STATUS } from 'enums/our-components-status.enum';

export const OurComponentsFilter = ({
  selectedStatuses,
  handleStatusesChange,
}: {
  selectedStatuses: Array<OUR_COMPONENTS_STATUS>;
  handleStatusesChange: (value: Array<OUR_COMPONENTS_STATUS>) => void;
}) => {
  const classes = useStyles();
  const [openAddPopup, setOpenAddPopup] = React.useState(false);
  const { t } = useTranslation(['sds_authoring', 'sds_list']);

  const getChipColor = (status: OUR_COMPONENTS_STATUS): string => {
    if (status === OUR_COMPONENTS_STATUS.DRAFT) {
      return 'secondary';
    }
    if (status === OUR_COMPONENTS_STATUS.ARCHIVED) {
      return 'amber';
    }
    return 'success';
  };

    const handleOnDelete = (del: OUR_COMPONENTS_STATUS) => {
    const deleted = selectedStatuses.filter((status) => status !== del);
    handleStatusesChange(deleted);
  };

  return (
    <>
      <Box className={classes.chipsWrapper}>
        {selectedStatuses.map(
          (status: OUR_COMPONENTS_STATUS, index: number) => {
            const chipColor = getChipColor(status);
            return (
              <Chip
                key={index}
                label={t(`status_choices:${status}`)}
                deleteIcon={<Clear />}
                onDelete={() => handleOnDelete(status)}
                variant={'outlined'}
                sx={{
                  color: `${chipColor}.main`,
                  borderColor: `${chipColor}.main`,
                  '& .MuiChip-deleteIcon': {
                    color: `${chipColor}.main`,
                  },
                }}
              />
            );
          }
        )}
        <Button
          onClick={() => setOpenAddPopup(true)}
          sx={{ color: 'colors.text', pl: 0 }}
        >
          <Add />
          {t('sds_list:add_filter')}
        </Button>
      </Box>
      {openAddPopup && (
        <AddFilterPopup
          open={openAddPopup}
          onSubmit={(selectedItems: Array<OUR_COMPONENTS_STATUS>) => {
            handleStatusesChange(selectedItems);
            setOpenAddPopup(false);
          }}
          onClose={() => setOpenAddPopup(false)}
          selected={selectedStatuses}
        />
      )}
    </>
  );
};
