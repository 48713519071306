import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  addMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  tableTitle: {
    fontWeight: 600,
    fontSize: '15px',
  },
  form: {
    width: '100%',
    marginBottom: '40px',
  },
  baseField: {
    display: 'flex',
    justifyContent: 'center',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  reviewDataRightSideContent: {
    width: '100%',
    fontSize: '18px',
  },
}));

export default useStyles;
