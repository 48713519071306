import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function SaveAsButton({ handleSubmit }: { handleSubmit: () => void }) {
  const { t } = useTranslation(['actions']);
  const sdsId = useSelector((state: any) => state.pageReducer.pdfId);
  const [open, setOpen] = useState(false);

  if (!sdsId) {
    return null;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleSubmit();
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{ padding: '10px 35px', marginTop: '20px', marginLeft: '10px' }}
      >
        {t('actions:save_as')}
      </Button>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        PaperProps={{
          style: {
            padding: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <DialogTitle id="confirm-dialog-title">{t('actions:confirm_save_as')}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontSize: '14px !important'}} id="confirm-dialog-description">
            {t('actions:confirm_save_as_description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} variant="contained" color="primary" autoFocus>
            {t('actions:ok')}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t('actions:cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SaveAsButton;