import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Add, Home, NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';

import { getCreatedComponents, post, update } from 'api';
import {
  AddComponentToOurListPopup,
  ConfirmActionPopup,
  EditComponentClassificationsPopup,
} from 'components';
import { OurComponentsTable } from 'components/our-components/our-components-table';
import { setComponentClassifications } from 'reducers/pageAction';
import useStyles from './styles';
import { OUR_COMPONENTS_STATUS } from 'enums/our-components-status.enum';
import { OurComponentsFilter } from 'components/our-components/filter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

const OurComponents = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation([
    'our_components',
    'sds_authoring',
    'confirm_archive',
    'status_choices',
    'sds_authoring',
  ]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [showNoDataMessage, setShowNoDataMessage] = React.useState(false);
  const [openAddPopup, setOpenAddPopup] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openArchivePopup, setOpenArchivePopup] = React.useState(false);
  const [openUnArchivePopup, setOpenUnArchivePopup] = React.useState(false);
  const [openEditClassificationsPopup, setOpenEditClassificationsPopup] =
    React.useState(false);
  const [selectedComponent, setSelectedComponent] = React.useState<any>(null);
  const [componentsPaginatedData, setComponentsPaginatedData] = React.useState({
    results: [],
    count: 10,
  });
  const [selectedStatuses, setSelectedStatuses] = React.useState<
    Array<OUR_COMPONENTS_STATUS>
  >([OUR_COMPONENTS_STATUS.DRAFT, OUR_COMPONENTS_STATUS.APPROVED]);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getComponents = () => {
    setLoading(true);
    const getCreatedComponentsRequest = getCreatedComponents(
      page + 1,
      rowsPerPage,
      selectedStatuses
    );
    getCreatedComponentsRequest.then((response) => {
      if (response.status === 200) {
        setComponentsPaginatedData(response.data);
        setLoading(false);
      }
    });
  };

  const handleArchiveComponent = (componentId: any) => {
    const archiveComponentRequest = post(
      `/sdsAuthoring/customerComponents/${componentId}/archive/`
    );
    archiveComponentRequest.then((response) => {
      if (response.status === 200) {
        getComponents();
        setOpenArchivePopup(false);
        setSelectedComponent(null);
      }
    });
  };
  const handleUnArchiveComponent = (componentId: any) => {
    const unArchiveComponentRequest = post(
      `/sdsAuthoring/customerComponents/${componentId}/unArchive/`
    );
    unArchiveComponentRequest.then((response) => {
      if (response.status === 200) {
        getComponents();
        setOpenUnArchivePopup(false);
        setSelectedComponent(null);
      }
    });
  };

  const handleStatusesChange = (value: Array<OUR_COMPONENTS_STATUS>) => {
    setSelectedStatuses(value);
  };

  const handleEditComponentClassifications = (classifications: any) => {
    const updateComponentRequest = update(
      `/sdsAuthoring/customerComponents/${selectedComponent.id}/`,
      { classifications: classifications }
    );
    updateComponentRequest.then((response) => {
      if (response.status === 200) {
        getComponents();
        setSelectedComponent(false);
        dispatch(setComponentClassifications(null));
        setOpenEditClassificationsPopup(false);
      }
    });
  };

  React.useEffect(() => {
    setLoading(true);
    const getCreatedComponentsRequest = getCreatedComponents(
      page + 1,
      rowsPerPage,
      []
    );
    getCreatedComponentsRequest.then((response) => {
      if (response.status === 200) {
        if (response.data.results.length === 0) {
          setShowNoDataMessage(true);
        }
        setLoading(false);
      }
    });
  }, []);

  React.useEffect(() => {
    getComponents();
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    setPage(0);
    getComponents();
  }, [selectedStatuses]);

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        <LinkRouter
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          to="/"
        >
          <Home fontSize="small" />
        </LinkRouter>
        <LinkRouter
          underline="hover"
          to="#"
          aria-current="page"
          fontSize="16px"
        >
          {t('sds_authoring:components')}
        </LinkRouter>
      </Breadcrumbs>
      <Typography variant="h4" my={1}>
        {t('header:our_components')}
      </Typography>
      {showNoDataMessage ? (
        <div className={classes.actionsWrapper}>
          <Button variant="contained" onClick={() => setOpenAddPopup(true)}>
            <Add />
            {t('our_components:add_component')}
          </Button>
        </div>
      ) : (
        <>
          <div className={classes.actionsWrapper}>
            <OurComponentsFilter
              selectedStatuses={selectedStatuses}
              handleStatusesChange={handleStatusesChange}
            />
            <Button variant="contained" onClick={() => setOpenAddPopup(true)}>
              <Add />
              {t('our_components:add_component')}
            </Button>
          </div>
          <OurComponentsTable
            setSelectedComponent={setSelectedComponent}
            setOpenEditClassificationsPopup={setOpenEditClassificationsPopup}
            setOpenArchivePopup={setOpenArchivePopup}
            setOpenUnArchivePopup={setOpenUnArchivePopup}
            componentsPaginatedData={componentsPaginatedData}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
      {openAddPopup && (
        <AddComponentToOurListPopup
          open={openAddPopup}
          onClose={() => {
            setOpenAddPopup(false);
          }}
          onSubmit={() => getComponents()}
        />
      )}
      {openArchivePopup && selectedComponent && (
        <ConfirmActionPopup
          title={t('popups:confirm_archive')}
          open={openArchivePopup}
          onClose={() => {
            setOpenArchivePopup(false);
            setSelectedComponent(null);
          }}
          onSubmit={() => {
            handleArchiveComponent(selectedComponent.id);
            setOpenArchivePopup(false);
            setSelectedComponent(null);
          }}
        />
      )}
      {openUnArchivePopup && selectedComponent && (
        <ConfirmActionPopup
          title={t('popups:get_back_from_archive')}
          open={openUnArchivePopup}
          onClose={() => {
            setOpenUnArchivePopup(false);
            setSelectedComponent(null);
          }}
          onSubmit={() => {
            handleUnArchiveComponent(selectedComponent.id);
            setOpenUnArchivePopup(false);
            setSelectedComponent(null);
          }}
        />
      )}
      {openEditClassificationsPopup && selectedComponent && (
        <EditComponentClassificationsPopup
          open={openEditClassificationsPopup}
          onClose={() => {
            setOpenEditClassificationsPopup(false);
            setSelectedComponent(null);
            dispatch(setComponentClassifications(null));
          }}
          onSubmit={(classifications: any) => {
            handleEditComponentClassifications(classifications);
          }}
        />
      )}
    </>
  );
};

export default OurComponents;
