import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popup: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minWidth: '95%',
    },
    '& .MuiDialogContent-root': {
      minWidth: '100%',
      padding: 0,
    },
    height: '100%',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '80%',
  },
  contentUserName: {
    marginTop: 20,
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  popupTitle: {
    textAlign: 'center',
    color: '#00324E',
    fontSize: '24px',
  },
  submitBtnWrapper: {
    width: 260,
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actionWrapper: {
    display: 'flex',
    background: '#fff',
    width: '100%',
    position: 'absolute',
    bottom: 5,
    flexDirection: 'column',
    height: '90px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  button: {
    width: 260,
  },
  rowItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
    padding: '0px 10px',
    boxSizing: 'border-box',
  },
  selectComponentsPopup: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    minWidth: '70%',
    height: '100%',
    padding: '10px 10px 0px 10px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '95%',
    },
    '& .MuiDialogContent-root': {
      minWidth: '100%',
      padding: 0,
    },
  },
  selectPPEPopup: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    minWidth: '40%',
    height: '80%',
    [theme.breakpoints.down('xs')]: {
      minWidth: '95%',
    },
    '& .MuiDialogContent-root': {
      minWidth: '100%',
      padding: 0,
    },
  },
  ppeItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    marginBottom: 10
  },
  ppeIcon: {
    width: '35px',
    height: '35px',
  },
  checkbox: {
    marginRight: '10px',
  },
  dataHeading: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  additionalDataWrapper: {
    width: '90%',
    display: 'flex',
  },
  additionalData: {
    display: 'block',
    marginBottom: '20px',
    borderBottom: '1px solid lightgray',
    width: '90%',
  },
  additionalDataValue: {
    whiteSpace: 'pre-wrap',
    padding: '10px 60px',
  },
  reviewDataRightSideContent: {
    width: '50%',
  },
  transportIconInfo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
  transportIcon: {
    width: '50px',
    height: '50px',
    margin: '10px',
  },
  confirmPopup: {
    height: '20%',
    width: '100%',
    maxWidth: 350,
  },
  selectInput: {
    width: '150px',
  },
}));

export default useStyles;
