import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

function SubmitButton({ handleSubmit }: { handleSubmit: () => void }) {
  const { t } = useTranslation(['actions']);

  return (
    <Button
      variant="contained"
      onClick={handleSubmit}
      sx={{ padding: '10px 35px', marginTop: '20px' }}
    >
      {t('actions:save')}
    </Button>
  );
}

export default SubmitButton;
