import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SectionFields } from '../utils';

export const getStep10Fields = (): Array<SectionFields> => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const { t } = useTranslation(['fields']);
  return [
    {
      name: 'reactivity',
      component: TextFieldInputV2,
      label: translations.sections.reactivity
        ? translations.sections.reactivity
        : t('fields:reactivity'),
      multiline: true,
      xs: 12,
    },
    {
      name: 'stability',
      component: TextFieldInputV2,
      label: translations.sections.stability
        ? translations.sections.stability
        : t('fields:stability'),
      multiline: true,
      xs: 12,
    },
    {
      name: 'possibility_of_hazardous_reactions',
      component: TextFieldInputV2,
      label: translations.sections.possibility_of_hazardous_reactions
        ? translations.sections.possibility_of_hazardous_reactions
        : t('fields:possibility_of_hazardous_reactions'),
      multiline: true,
      xs: 12,
    },
    {
      name: 'conditions_to_avoid',
      component: TextFieldInputV2,
      label: translations.sections.conditions_to_avoid
        ? translations.sections.conditions_to_avoid
        : t('fields:conditions_to_avoid'),
      multiline: true,
      xs: 12,
    },
    {
      name: 'incompatible_materials',
      component: TextFieldInputV2,
      label: translations.sections.incompatible_materials
        ? translations.sections.incompatible_materials
        : t('fields:incompatible_materials'),
      multiline: true,
      xs: 12,
    },
    {
      name: 'hazardous_decomposition_products',
      component: TextFieldInputV2,
      label: translations.sections.hazardous_decomposition_products
        ? translations.sections.hazardous_decomposition_products
        : t('fields:hazardous_decomposition_products'),
      multiline: true,
      xs: 12,
    },
  ];
};