import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const getSteps = () => {
  const { t } = useTranslation(['steps']);
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );

  return [
    {
      title: t('steps:section0'),
      subtitle: [],
    },
    {
      title: t('steps:section1'),
      subtitle: [
        translations.headers.header_1_1,
        translations.headers.header_1_2,
        translations.headers.header_1_3,
        translations.headers.header_1_4,
      ],
    },
    {
      title: t('steps:section2'),
      subtitle: [
        translations.headers['2_1_header_classification_of_the_substance'],
        translations.headers['2_2_header_label_elements'],
        translations.headers['2_3_header_other_hazards'],
      ],
    },
    {
      title: t('steps:section3'),
      subtitle: [],
    },
    {
      title: t('steps:section4'),
      subtitle: [
        translations.headers['4_1_header_description_first_aid_meadures'],
        translations.headers['4_2_header_most_important_symptoms'],
        translations.headers[
          '4_3_header_indication_of_any_immediate_medical_attn'
        ],
      ],
    },
    {
      title: t('steps:section5'),
      subtitle: [
        translations.headers['5_1_header_extinguishing_media'],
        translations.headers[
          '5_2_header_special_hazards_arising_from_substance'
        ],
        translations.headers['5_3_header_advice_for_firefighters'],
      ],
    },
    {
      title: t('steps:section6'),
      subtitle: [
        translations.headers['6_1_header_personal_precautions'],
        translations.headers['6_2_header_environmental_precautions'],
        translations.headers['6_3_header_methods_and_material_containment'],
        translations.headers['6_4_header_refference_other_sections'],
      ],
    },
    {
      title: t('steps:section7'),
      subtitle: [
        translations.headers['7_1_header_precautions_for_safe_handling'],
        translations.headers['7_2_header_conditions_safe_storage'],
        translations.headers['7_3_header_specific_end_uses'],
      ],
    },
    {
      title: t('steps:section8'),
      subtitle: [
        translations.headers['8_1_header_control_parameters'],
        translations.headers['8_2_header_exposure_controls'],
      ],
    },
    {
      title: t('steps:section9'),
      subtitle: [
        translations.headers['9_1_header_physical_and_chem_properties'],
        translations.headers['9_2_header_other_information'],
      ],
    },
    {
      title: t('steps:section10'),
      subtitle: [],
    },
    {
      title: t('steps:section11'),
      subtitle: [],
    },
    {
      title: t('steps:section12'),
      subtitle: [
        translations.headers['12_1_header_toxicity'],
        translations.headers['12_2_header_persistence_and_degradability'],
        translations.headers['12_3_header_bioaccumulative_potential'],
        translations.headers['12_4_header_mobility_in_soil'],
        translations.headers['12_5_header_result_of_pbt_vpvb_assessment'],
        translations.headers['12_6_header_other_adverse_effects'],
      ],
    },
    {
      title: t('steps:section13'),
      subtitle: [],
    },
    {
      title: t('steps:section14'),
      subtitle: [
        translations.sections.un_number ?? t('fields:un_number'),
        translations.sections.shipping_name ?? t('fields:shipping_name'),
        translations.sections.transport_hazard_classes ??
          t('fields:transport_hazard_classes'),
        translations.sections.packing_group ?? t('fields:packing_group'),
      ],
    },
    {
      title: t('steps:section15'),
      subtitle: [
        translations.headers['15_1_header_safety_healt_env'],
        translations.headers['15_2_header_chemical_safety_assessment'],
        translations.headers['15_3_header_aditional_information'],
      ],
    },
    {
      title: t('steps:section16'),
      subtitle: [],
    },
    {
      title: t('steps:section17'),
      subtitle: [],
    },
  ];
};
