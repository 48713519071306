import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ingredientWrapper: {
    marginBottom: '20px',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 5,
    },
  }
}));

export default useStyles;
