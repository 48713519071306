import { Button } from '@material-ui/core';
import DesktopDialog from 'components/dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { PopupProps } from './PopupsInterface';
import useStyles from './styles';

const ConfirmRemoveApprove = ({ onClose, open, onSubmit }: PopupProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['sds_authoring', 'actions']);

  if (!open) {
    return null;
  }

  return (
    <DesktopDialog
      classes={{ paper: classes.confirmPopup }}
      onClose={() => onClose()}
      DialogTitleProps={{
        style: {
          textAlign: 'center',
        },
      }}
      title={t('sds_authoring:confirm_remove_approve')}
      actions={
        <div style={{ paddingBottom: '10px' }}>
          <Button variant={'contained'} color={'primary'} onClick={onSubmit}>
            {t('actions:confirm')}
          </Button>
          <Button onClick={onClose} variant={'outlined'} color={'primary'}>
            {t('actions:cancel')}
          </Button>
        </div>
      }
    ></DesktopDialog>
  );
};

export default ConfirmRemoveApprove;
