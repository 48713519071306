import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StepFieldsProps } from '../utils';
export interface HazardCodeColumnsProps {
  id: string;
  title: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  width?: any;
}

export const getColumns = (): Array<HazardCodeColumnsProps> => {
  const { t } = useTranslation(['sds_authoring']);

  return [
    {
      id: 'pictogram_url',
      title: '',
      width: '5%',
      maxWidth: 40,
    },
    {
      id: 'name',
      title: t('sds_authoring:name'),
      minWidth: 150,
      align: 'left',
    },
    {
      id: 'signal_word',
      title: t('sds_authoring:signal_word'),
      width: '10%',
      maxWidth: 160,
      align: 'left',
    },
    {
      id: 'hazard_class',
      title: t('sds_authoring:hazard_class'),
      width: '10%',
      maxWidth: 160,
      align: 'left',
    },
    {
      id: 'category',
      title: t('sds_authoring:category'),
      width: '10%',
      maxWidth: 160,
      align: 'left',
    },
    {
      id: 'hazard_category_abbreviation',
      title: t('sds_authoring:category_abbr'),
      width: '10%',
      maxWidth: 160,
    },
    {
      id: 'prevention_statements',
      title: t('sds_authoring:prevention_statements'),
      width: '30%',
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'action',
      title: '',
      width: 30,
    },
  ];
};

export const getPrecautionColumns = (): Array<HazardCodeColumnsProps> => {
  const { t } = useTranslation(['sds_authoring']);

  return [
    {
      id: 'statement_code',
      title: t('sds_authoring:name'),
      width: '10%',
      maxWidth: 160,
    },
    {
      id: 'description',
      title: t('sds_authoring:description'),
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'prevention_statements',
      title: t('sds_authoring:prevention_statements'),
      width: '20%',
      maxWidth: 280,
      align: 'left',
    },
    {
      id: 'action',
      title: '',
      width: 30,
    },
  ];
};

export const getStep3Fields = (): Array<StepFieldsProps> => {

  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  
  return [
    {
      title: translations.headers['2_2_header_label_elements'],
      fields: [
        {
          name: 'hazard_components',
          component: TextFieldInputV2,
          label: translations.sections.hazard_components,
          multiline: true,
          xs: 12,
        },
        {
          name: 'contains',
          component: TextFieldInputV2,
          label: translations.sections.contains,
          multiline: true,
          xs: 12,
        },
        {
          name: 'supplemental_label_elements',
          component: TextFieldInputV2,
          label: translations.sections.supplemental_label_elements,
          multiline: true,
          xs: 12,
        },
        {
          name: 'annex_restrictions',
          component: TextFieldInputV2,
          label: translations.sections.annex_restrictions,
          multiline: true,
          xs: 12,
        },
        {
          name: 'special_packing_requirements',
          component: TextFieldInputV2,
          label: translations.sections.special_packing_requirements,
          multiline: true,
          xs: 12,
        },
        {
          name: 'touch_hazard_warning',
          component: TextFieldInputV2,
          label: translations.sections.touch_hazard_warning,
          multiline: true,
          xs: 12,
        },
        {
          name: 'hazards_additional_information',
          component: TextFieldInputV2,
          label: translations.sections.hazards_additional_information,
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['2_3_header_other_hazards'],
      fields: [
        {
          name: 'other_hazards',
          component: TextFieldInputV2,
          label: '',  
          multiline: true,
          xs: 12,
        },
        {
          name: 'hazards_results_of_pbt_and_vpvb_assessment',
          component: TextFieldInputV2,
          label: translations.sections.hazards_results_of_pbt_and_vpvb_assessment,
          multiline: true,
          xs: 12,
        },
        {
          name: 'hazards_potential_health_effects_accute',
          component: TextFieldInputV2,
          label: translations.sections.hazards_potential_health_effects_accute,
          multiline: true,
          xs: 12,
        },
        {
          name: 'hazards_potential_health_effects',
          component: TextFieldInputV2,
          label: translations.sections.hazards_potential_health_effects,
          multiline: true,
          xs: 12,
        },
        {
          name: 'long_term_hazards',
          component: TextFieldInputV2,
          label: translations.sections.long_term_hazards,
          multiline: true,
          xs: 12,
        },
        {
          name: 'primary_routes_of_exposure',
          component: TextFieldInputV2,
          label: translations.sections.primary_routes_of_exposure,
          multiline: true,
          xs: 12,
        },
        {
          name: 'hazards_outside_classification',
          component: TextFieldInputV2,
          label: translations.sections.hazards_outside_classification,
          multiline: true,
          xs: 12,
        },
      ],
    },
  ];
};
