import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StepFieldsProps } from '../utils';

export const getStep12Fields = (): Array<StepFieldsProps> => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const { t } = useTranslation(['fields']);
  return [
    {
      title: translations.headers['12_1_header_toxicity'],
      fields: [
        {
          name: 'ecotoxicity',
          component: TextFieldInputV2,
          label: translations.sections.ecotoxicity
            ? translations.sections.ecotoxicity
            : t('fields:ecotoxicity'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'aquatic_toxicity',
          component: TextFieldInputV2,
          label: translations.sections.aquatic_toxicity
            ? translations.sections.aquatic_toxicity
            : t('fields:aquatic_toxicity'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'algae_aquatic_toxicity',
          component: TextFieldInputV2,
          label: translations.sections.algae_aquatic_toxicity
            ? translations.sections.algae_aquatic_toxicity
            : t('fields:algae_aquatic_toxicity'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'fish_aquatic_toxicity',
          component: TextFieldInputV2,
          label: translations.sections.fish_aquatic_toxicity
            ? translations.sections.fish_aquatic_toxicity
            : t('fields:fish_aquatic_toxicity'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'm_factor_aquatic_toxicity',
          component: TextFieldInputV2,
          label: translations.sections.m_factor_aquatic_toxicity
            ? translations.sections.m_factor_aquatic_toxicity
            : t('fields:m_factor_aquatic_toxicity'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'microorganisms_toxicity',
          component: TextFieldInputV2,
          label: translations.sections.microorganisms_toxicity
            ? translations.sections.microorganisms_toxicity
            : t('fields:microorganisms_toxicity'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'bacteria_toxicity',
          component: TextFieldInputV2,
          label: translations.sections.bacteria_toxicity
            ? translations.sections.bacteria_toxicity
            : t('fields:bacteria_toxicity'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['12_2_header_persistence_and_degradability'],
      fields: [
        {
          name: 'biodegradability',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'biodegradation',
          component: TextFieldInputV2,
          label: translations.sections.biodegradation
            ? translations.sections.biodegradation
            : t('fields:biodegradation'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['12_3_header_bioaccumulative_potential'],
      fields: [],
    },
    {
      title: translations.headers['12_4_header_mobility_in_soil'],
      fields: [
        {
          name: 'mobility',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'koc',
          component: TextFieldInputV2,
          label: translations.sections.koc
            ? translations.sections.koc
            : t('fields:koc'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'mobility_additional_ecological_instructions',
          component: TextFieldInputV2,
          label: translations.sections
            .mobility_additional_ecological_instructions
            ? translations.sections.mobility_additional_ecological_instructions
            : t('fields:mobility_additional_ecological_instructions'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'mobility_general_notes',
          component: TextFieldInputV2,
          label: translations.sections.mobility_general_notes
            ? translations.sections.mobility_general_notes
            : t('fields:mobility_general_notes'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['12_5_header_result_of_pbt_vpvb_assessment'],
      fields: [
        {
          name: 'pbt',
          component: TextFieldInputV2,
          label: translations.sections.pbt
            ? translations.sections.pbt
            : t('fields:pbt'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'vpvb',
          component: TextFieldInputV2,
          label: translations.sections.vpvb
            ? translations.sections.vpvb
            : t('fields:vpvb'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['12_6_header_endocrine_disrupting_properties'],
      fields: [
        {
          name: 'persistent_organic_pollutant',
          component: TextFieldInputV2,
          label: translations.sections.persistent_organic_pollutant
            ? translations.sections.persistent_organic_pollutant
            : t('fields:persistent_organic_pollutant'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'ozone_depletion_potential',
          component: TextFieldInputV2,
          label: translations.sections.ozone_depletion_potential
            ? translations.sections.ozone_depletion_potential
            : t('fields:ozone_depletion_potential'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['12_7_header_other_adverse_effects'],
      fields: [
        {
          name: 'other_adverse_effects',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
      ]
    },
  ];
};
