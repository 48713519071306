import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Paper } from '@mui/material';
import { get, post, update } from 'api';
import { Loader } from 'components';
import ComponentEcologicalData from 'components/create-component-structured-data/components/component-ecological-data';
import ComponentExposureProtectionsFormData from 'components/create-component-structured-data/components/component-exposure-protection-data';
import ComponentToxicologicalData from 'components/create-component-structured-data/components/component-toxicological-data';
import {
  setComponentDetails,
  setComponentStructuredData,
  setSelectedComponentId,
} from 'reducers/pageAction';
import { renderSnackbar } from 'utils';
import ComponentDetailsForm from './ComponentInfoForm';
import useStyles from './styles';
import { Home, NavigateNext } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

const ComponentStructuredDataPage = ({
  componentStructuredDataForm,
}: {
  componentStructuredDataForm: any;
}) => {
  const classes = useStyles();
  const params: any = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation(['our_components']);

  const [loading, setLoading] = React.useState(false);
  const componentInfo = useSelector(
    (state: any) => state.pageReducer.componentDetails
  );
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const structuredData = useSelector(
    (state: any) => state.pageReducer.structuredComponentData
  );

  const getComponentDetails = () => {
    setLoading(true);
    const getCreatedComponentsRequest = get(
      `/sdsAuthoring/customerComponents/${params.id}/`
    );
    getCreatedComponentsRequest.then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedComponentId(params.id));
        dispatch(
          setComponentStructuredData({
            [params.id]: response.data.structured_data,
          })
        );
        dispatch(setComponentDetails(response.data));
        setLoading(false);
      }
    });
  };

  const updateStructuredData = () => {
    const updateComponentStructuredDataRequest = update(
      `/sdsAuthoring/customerComponents/${params.id}/componentStructuredData/`,
      componentStructuredDataForm.values
    );
    updateComponentStructuredDataRequest.then((response) => {
      if (response?.status === 200) {
        dispatch(
          setComponentStructuredData({
            [params.id]: response.data,
          })
        );
        renderSnackbar([t('our_components:successfully_updated')]);
      } else {
        renderSnackbar([t('our_components:update_failed')]);
      }
    });
  };

  const createStructuredDataForComponent = () => {
    const createComponentStructuredDataRequest = post(
      `/sdsAuthoring/customerComponents/${params.id}/componentStructuredData/`,
      componentStructuredDataForm.values
    );
    createComponentStructuredDataRequest.then((response) => {
      if (response?.status === 200) {
        dispatch(
          setComponentStructuredData({
            [params.id]: response.data,
          })
        );
        renderSnackbar([t('our_components:successfully_updated')]);
      } else {
        renderSnackbar([t('our_components:update_failed')]);
      }
    });
  };
  const handleUpdateComponentData = (data: any) => {
    const updateComponentRequest = update(
      `/sdsAuthoring/customerComponents/${componentInfo.id}/`,
      { ...data, force_add: true }
    );
    updateComponentRequest.then((response) => {
      if (response.status === 200) {
        getComponentDetails();
      }
    });
  };
  const handleApproveComponentData = () => {
    const approveComponentRequest = post(
      `/sdsAuthoring/customerComponents/${componentInfo.id}/approve/`
    );
    approveComponentRequest.then((response) => {
      if (response.status === 200) {
        getComponentDetails();
      }
    });
  };

  React.useEffect(() => {
    getComponentDetails();
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(setSelectedComponentId(null));
      dispatch(setComponentStructuredData({}));
      dispatch(setComponentDetails(null));
    };
  }, []);

  return (
    <>
      {loading || !componentInfo || !translations ? (
        <Loader />
      ) : (
        <>
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <LinkRouter
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
            >
              <Home fontSize="small" />
            </LinkRouter>
            <LinkRouter
              underline="hover"
              to="/our-components"
              aria-current="page"
              fontSize="16px"
            >
              {t('sds_authoring:components')}
            </LinkRouter>
            <LinkRouter
              underline="hover"
              to="#"
              aria-current="page"
              fontSize="16px"
            >
              {componentInfo.component_name}
            </LinkRouter>
          </Breadcrumbs>
          <Box className={classes.container}>
            <Paper elevation={4} className={classes.componentDetails}>
              {componentInfo.status === 'draft' && (
                <Button
                  variant="outlined"
                  sx={{ mb: 2 }}
                  onClick={handleApproveComponentData}
                >
                  {t('actions:approve')}
                </Button>
              )}
              <ComponentDetailsForm
                onSubmit={(data: any) => handleUpdateComponentData(data)}
              />
            </Paper>

            <Box sx={{ mt: 3 }}>
              <ComponentExposureProtectionsFormData />
              <ComponentToxicologicalData />
              <ComponentEcologicalData />
            </Box>

            <Button
              onClick={() => {
                if (structuredData && structuredData[componentInfo.id]) {
                  updateStructuredData();
                } else {
                  createStructuredDataForComponent();
                }
              }}
              color={'primary'}
              variant={'contained'}
            >
              {t('actions:save_structured_data')}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    componentStructuredDataForm: state.form.componentStructuredData,
  };
};

export default connect(mapStateToProps)(ComponentStructuredDataPage);
