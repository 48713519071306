import { reducer as formReducer } from 'redux-form';
import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import pageReducer from './pageReducer';

let reducers = combineReducers({
  pageReducer: pageReducer,
  form: formReducer,

});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

window.store = store;

export default store;
