import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';

type Props = {
  label: string;
  input: any;
  meta: any;
  options: any;
  classes?: any;
  wrapperClassName?: any;
  valueField?: string;
  labelField?: string;
  style?: any;
  required?: boolean;
};

const SelectInput = ({
  input,
  label,
  meta: { touched, error },
  options,
  style,
  required,
  ...custom
}: Props) => {
  const { classes, valueField, labelField } = custom;

  return (
    <FormControl
      classes={classes}
      error={touched && error}
      variant="filled"
      style={style}
    >
      <InputLabel>
        {label}
        {required ? '*' : ''}
      </InputLabel>
      <Select
        {...input}
        inputProps={{
          name: input.name,
        }}
      >
        <MenuItem value="">-</MenuItem>
        {options.map((item: any, index: number) => {
          return (
            <MenuItem value={valueField ? item[valueField] : item} key={index}>
              {labelField ? item[labelField] : item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
