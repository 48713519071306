import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  selectsBlock: {
    width: '40%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 0 0 3%',
  },
  dialog: {
    position: 'relative',
    overflow: 'hidden',
    height: '90vh',
    '& .MuiDialogContent-root': {
      padding: '0px 20px 20px 20px'
    },
  },
  content: {
    minHeight: '60vh',
  },
  addActionWrapper: {
    width: '100%',
    display: 'flex',
    height: '52px',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '0px',
  },
  addBtn: {
    width: '60%',
    background: '#00324E',
    color: '#fff',
    marginBottom: '10px',
  },
  searchInputsWrapper: {
    width: '80%',
    display: 'flex',
    padding: '0px 10px',
    gap: 20,
    alignItems: 'center',
  },
  inputRoot: {
    width: '60%',
  },
  sdsSearchInput: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '1vw',
    fontWeight: 'bold',
    height: '38px',
    padding: '0 16px',
    border: '1px solid rgba(0, 0, 0, 0.38)',
    borderRadius: '0px',
    background: 'rgba(0, 0, 0, 0.04)',
  },
  selectInput: {
    width: '150px',
  },
  selectMenu: {
    height: '350px',
  },
  infoBlockName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0',
  },
  infoBlock: {
    margin: '7px 0 7px 0',
  },
  valuesColor: {
    color: 'rgb(0, 27, 203)',
  },
  closeIcon: {
    width: '5%',
    left: '95%',
  },
  checkboxItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  tooltip: {
    fontSize: '1rem',
  },
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '15px 0',
    bottom: 0,
  },
}));

export default useStyles;
