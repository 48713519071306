import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  componentDetails: {
    padding: '10px',
    marginBottom: 20,
  },
  container: {
    width: '80%',
    margin: '20px auto 0px auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  }
}));

export default useStyles;
