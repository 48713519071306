import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        position: 'relative'
    },
    stepper: {
        zIndex: 1,
        position: 'fixed',
        background: '#fff',
        height: 'calc(100% - 140px)',
        width: 310,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    navigator: {
        display: 'flex',
        flexDirection: 'row',
        position: 'fixed',
        bottom: 20,
        width: 310,
        backgroundColor: '#fff',
        zIndex: 2
    }
}));

export default useStyles;