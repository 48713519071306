import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataTableCell } from './data-table-cell';
import { DataTableHeader } from './data-table-header';
import useStyles from './styles';

const DataTable = ({
  formName,
  fields,
  fieldsNames,
  fieldsStyles,
  fieldsProps,
  translations,
}: {
  formName: any;
  fields: any;
  fieldsNames: any;
  fieldsStyles: any;
  fieldsProps: any;
  translations: any;
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['actions']);
  return (
    <Box sx={{ mt: 1 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {fieldsNames.map((el: any, index: number) => (
                <DataTableHeader
                  translations={translations}
                  key={index}
                  fieldsStyles={fieldsStyles}
                  fieldsName={el}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.getAll()?.map((_: any, index: number) => {
              return (
                <TableRow key={index}>
                  {fieldsNames.map((el: any, idx: number) => (
                    <DataTableCell
                      key={idx}
                      index={index}
                      style={fieldsStyles[el]}
                      fieldsName={el}
                      formName={formName}
                      fieldsProps={fieldsProps}
                      onDelete={() => fields.remove(index)}
                    />
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.addMoreWrapper}>
        <Button variant="outlined" onClick={() => fields.insert(0, {})}>
          <Add />
          {t('actions:add')}
        </Button>
      </div>
    </Box>
  );
};

export default DataTable;
