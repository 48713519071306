import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import {
  Box,
  Button,
  Step,
  StepButton,
  StepContent,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getSteps } from '../helper';
import useStyles from './styles';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CustomStepper({
  activeStep,
  setActiveStep,
}: {
  activeStep: number;
  setActiveStep: (step: number) => void;
}) {
  const classes = useStyles();
  const params: any = useParams();
  const { t } = useTranslation(['sds_authoring']);
  const steps = getSteps();
  const completedSteps = useSelector(
    (state: any) => state.pageReducer.completedAuthoringStep
  );
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    setShowDetails(true);
  }, [activeStep]);

  return (
    <div className={classes.root}>
      <SimpleBar className={classes.stepper}>
        <Stepper
          nonLinear
          activeStep={activeStep}
          orientation="vertical"
          style={{ padding: 0 }}
        >
          {steps.map((step, index) => (
            <Step key={index} completed={completedSteps.includes(index)}>
              {index !== 0 && index !== 1 && !params.sdsId ? (
                <Tooltip title={t('sds_authoring:disabled_until_save')}>
                  <StepButton
                    disabled={index !== 0 && index !== 1 && !params.sdsId}
                    sx={{
                      '& .MuiStepLabel-root .Mui-completed': {
                        color: 'green',
                      },
                      '&.Mui-disabled': {
                        pointerEvents: 'all',
                        cursor: 'not-allowed',
                      },
                    }}
                    icon={index.toString()}
                    onClick={() => {
                      setActiveStep(index);
                      setShowDetails((detail) => !detail);
                    }}
                  >
                    <Typography variant="caption">{step.title}</Typography>
                  </StepButton>
                </Tooltip>
              ) : (
                <StepButton
                  disabled={index !== 0 && index !== 1 && !params.sdsId}
                  sx={{
                    '& .MuiStepLabel-root .Mui-completed': {
                      color: 'green',
                    },
                  }}
                  icon={index.toString()}
                  onClick={() => {
                    setActiveStep(index);
                    setShowDetails((detail) => !detail);
                  }}
                >
                  <Typography variant="caption">{step.title}</Typography>
                </StepButton>
              )}

              <StepContent
                TransitionProps={{ in: activeStep === index && showDetails }}
              >
                {step.subtitle.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      pt: 2,
                    }}
                  >
                    {step.subtitle.map((content, index) => (
                      <Typography key={index} variant="subtitle2">
                        {content}
                      </Typography>
                    ))}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </SimpleBar>
      <Box className={classes.navigator}>
        {activeStep !== 0 && (
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={() => setActiveStep(activeStep - 1)}
            sx={{ mr: 1 }}
          >
            <NavigateBefore />
            <Typography variant="caption">
              {steps[activeStep - 1].title}
            </Typography>
          </Button>
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep !== steps.length - 1 && (
          <Button onClick={() => setActiveStep(activeStep + 1)}>
            <Typography color="primary" variant="caption">
              {steps[activeStep + 1].title}
            </Typography>
            <NavigateNext />
          </Button>
        )}
      </Box>
    </div>
  );
}

export default CustomStepper;
