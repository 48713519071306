import { Checkbox, Typography } from '@mui/material';
import DataTable from '../data-table';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import InputField from '../input-field';
import useBaseStyles from '../styles';
import OutlinedSection from '../outlined-section';
import React, { useState } from 'react';

interface ComponentToxicologicalDataProps {
  htmlSection?: any;
}

const ComponentToxicologicalData = (
  props: ComponentToxicologicalDataProps &
    InjectedFormProps<{}, ComponentToxicologicalDataProps>
) => {
  const classes = useBaseStyles();
  const { t } = useTranslation(['create_component_structured_data']);
  const { htmlSection } = props;
  const [showSectionData, setShowSectionData] = useState(false);
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  return (
    <form className={classes.form}>
      <OutlinedSection
        title={translations.headers['11_header_toxological_info']}
        variant="h5"
      >
        <div style={{}}>
          <div className={classes.checkboxWrapper}>
            <Checkbox
              color={'primary'}
              onClick={() => setShowSectionData(!showSectionData)}
              checked={showSectionData}
            />
            <Typography>{t('sds_authoring:show_imported_sds_data')}</Typography>
          </div>
          {showSectionData && htmlSection && (
            <div
              className={classes.reviewDataRightSideContent}
              dangerouslySetInnerHTML={{
                __html: htmlSection,
              }}
            />
          )}
        </div>
        <Typography variant="h6">
          {translations.sections.acute_toxicity}
        </Typography>
        <Field
          name="acute_toxicity_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="acute_toxicity"
          formName="acute_toxicity"
          translations={translations}
          component={DataTable}
          fieldsNames={['result', 'species', 'dose', 'exposure', 'action']}
          fieldsStyles={{
            result: { width: '40%' },
            species: { width: '20%' },
            dose: { width: '20%' },
            exposure: { width: '20%' },
          }}
        />
        <Typography variant="h6">
          {translations.sections.skin_corrosion}
        </Typography>
        <Field
          name="skin_corrosion_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="skin_corrosion"
          formName="skin_corrosion"
          component={DataTable}
          translations={translations}
          fieldsNames={[
            'result',
            'species',
            'score',
            'exposure',
            'observation',
            'action',
          ]}
          fieldsStyles={{
            result: { width: '30%' },
            species: { width: '20%' },
            score: { width: '10%' },
            exposure: { width: '20%' },
            observation: { width: '20%' },
          }}
          fieldsProps={{
            result: {
              type: 'select',
              options: [
                {
                  value: 'ld_oral',
                  label: t('create_component_structured_data:ld_oral'),
                },
                {
                  value: 'ld_dermal',
                  label: t('create_component_structured_data:ld_dermal'),
                },
                {
                  value: 'lc_inhalation',
                  label: t('create_component_structured_data:lc_inhalation'),
                },
              ],
            },
          }}
        />
        <Typography variant="h6">
          {translations.sections.sensitization}
        </Typography>
        <Field
          name="sensitization_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="sensitization"
          formName="sensitization"
          component={DataTable}
          translations={translations}
          fieldsNames={['route_of_exposure', 'species', 'result', 'action']}
          fieldsStyles={{
            route_of_exposure: { width: '25%' },
            species: { width: '35%' },
            result: { width: '40%' },
          }}
          fieldsProps={{
            route_of_exposure: {
              type: 'select',
              options: [
                {
                  value: 'inhalation',
                  label: t('create_component_structured_data:inhalation'),
                },
                {
                  value: 'skin',
                  label: t('create_component_structured_data:skin'),
                },
                {
                  value: 'eyes',
                  label: t('create_component_structured_data:eyes'),
                },
                {
                  value: 'swallowing',
                  label: t('create_component_structured_data:swallowing'),
                },
                {
                  value: 'injection',
                  label: t('create_component_structured_data:injection'),
                },
              ],
            },
          }}
        />
        <Typography variant="h6">
          {translations.sections.mutagenicity}
        </Typography>
        <Field
          name="mutagenicity_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="mutagenicity"
          formName="mutagenicity"
          component={DataTable}
          translations={translations}
          fieldsNames={['test', 'experiment', 'result', 'action']}
          fieldsStyles={{
            test: { width: '30%' },
            experiment: { width: '40%' },
            result: { width: '30%' },
          }}
        />
        <Typography variant="h6">
          {translations.sections.organ_toxicity_single_exposure}
        </Typography>
        <Field
          name="specific_target_organ_toxicity_single_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="specific_target_organ_toxicity_single"
          formName="specific_target_organ_toxicity_single"
          component={DataTable}
          translations={translations}
          fieldsNames={[
            'category',
            'route_of_exposure',
            'target_organs',
            'action',
          ]}
          fieldsStyles={{
            category: { width: '35%' },
            route_of_exposure: { width: '35%' },
            target_organs: { width: '30%' },
          }}
          fieldsProps={{
            route_of_exposure: {
              type: 'select',
              options: [
                {
                  value: 'inhalation',
                  label: t('create_component_structured_data:inhalation'),
                },
                {
                  value: 'skin',
                  label: t('create_component_structured_data:skin'),
                },
                {
                  value: 'eyes',
                  label: t('create_component_structured_data:eyes'),
                },
                {
                  value: 'swallowing',
                  label: t('create_component_structured_data:swallowing'),
                },
                {
                  value: 'injection',
                  label: t('create_component_structured_data:injection'),
                },
              ],
            },
            category: {
              type: 'select',
              options: [
                {
                  value: 'category_1',
                  label: `${translations.layout.category} 1`,
                },
                {
                  value: 'category_2',
                  label: `${translations.layout.category} 2`,
                },
                {
                  value: 'category_3',
                  label: `${translations.layout.category} 3`,
                },
              ],
            },
          }}
        />
        <Typography variant="h6">
          {translations.sections.organ_toxicity_repeated_exposure}
        </Typography>
        <Field
          name="specific_target_organ_toxicity_repeated_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="specific_target_organ_toxicity_repeated"
          formName="specific_target_organ_toxicity_repeated"
          component={DataTable}
          translations={translations}
          fieldsNames={[
            'category',
            'route_of_exposure',
            'target_organs',
            'action',
          ]}
          fieldsStyles={{
            category: { width: '35%' },
            route_of_exposure: { width: '35%' },
            target_organs: { width: '30%' },
          }}
          fieldsProps={{
            route_of_exposure: {
              type: 'select',
              options: [
                {
                  value: 'inhalation',
                  label: t('create_component_structured_data:inhalation'),
                },
                {
                  value: 'skin',
                  label: t('create_component_structured_data:skin'),
                },
                {
                  value: 'eyes',
                  label: t('create_component_structured_data:eyes'),
                },
                {
                  value: 'swallowing',
                  label: t('create_component_structured_data:swallowing'),
                },
                {
                  value: 'injection',
                  label: t('create_component_structured_data:injection'),
                },
              ],
            },
            category: {
              type: 'select',
              options: [
                {
                  value: 'category_1',
                  label: `${translations.layout.category} 1`,
                },
                {
                  value: 'category_2',
                  label: `${translations.layout.category} 2`,
                },
                {
                  value: 'category_3',
                  label: `${translations.layout.category} 3`,
                },
              ],
            },
          }}
        />
        <Typography variant="h6">{translations.sections.eye_damage}</Typography>
        <Field
          name="eye_damage_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="eye_damage"
          formName="eye_damage"
          component={DataTable}
          translations={translations}
          fieldsNames={['route_of_exposure', 'species', 'result', 'action']}
          fieldsStyles={{
            route_of_exposure: { width: '25%' },
            species: { width: '35%' },
            result: { width: '40%' },
          }}
          fieldsProps={{
            route_of_exposure: {
              type: 'select',
              options: [
                {
                  value: 'inhalation',
                  label: t('create_component_structured_data:inhalation'),
                },
                {
                  value: 'skin',
                  label: t('create_component_structured_data:skin'),
                },
                {
                  value: 'eyes',
                  label: t('create_component_structured_data:eyes'),
                },
                {
                  value: 'swallowing',
                  label: t('create_component_structured_data:swallowing'),
                },
                {
                  value: 'injection',
                  label: t('create_component_structured_data:injection'),
                },
              ],
            },
          }}
        />
        <Typography variant="h6">
          {translations.sections.carcinogenicity}
        </Typography>
        <Field
          name="carcinogenicity_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="carcinogenicity"
          formName="carcinogenicity"
          component={DataTable}
          translations={translations}
          fieldsNames={['result', 'species', 'dose', 'exposure', 'action']}
          fieldsStyles={{
            result: { width: '30%' },
            species: { width: '25%' },
            dose: { width: '20%' },
            exposure: { width: '25%' },
          }}
        />
        <Typography variant="h6">
          {translations.sections.reproductive_toxicity}
        </Typography>
        <Field
          name="reproductive_toxicity_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="reproductive_toxicity"
          formName="reproductive_toxicity"
          component={DataTable}
          translations={translations}
          fieldsNames={[
            'maternal_toxicity',
            'fertility',
            'development_toxin',
            'species',
            'dose',
            'exposure',
            'action',
          ]}
          fieldsStyles={{
            maternal_toxicity: { width: '15%' },
            fertility: { width: '15%' },
            development_toxin: { width: '15%' },
            species: { width: '25%' },
            dose: { width: '15%' },
            exposure: { width: '15%' },
          }}
        />
        <Typography variant="h6">
          {translations.sections.teratogenicity}
        </Typography>
        <Field
          name="teratogenicity_desc"
          component={InputField}
          multiline
          fullWidth
        />
        <FieldArray
          name="teratogenicity"
          formName="teratogenicity"
          component={DataTable}
          translations={translations}
          fieldsNames={['result', 'species', 'dose', 'exposure', 'action']}
          fieldsStyles={{
            result: { width: '30%' },
            species: { width: '25%' },
            dose: { width: '20%' },
            exposure: { width: '25%' },
          }}
        />
      </OutlinedSection>
    </form>
  );
};

const mapStateToProps = (state: any) => {
  return {
    htmlSection: state.pageReducer.componentDetails?.html_sections[11],
  };
};
export default connect(mapStateToProps)(
  reduxForm({
    form: 'componentStructuredData',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(ComponentToxicologicalData)
);
