import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  formWrapper: {
    width: '80%',
    padding: '20px',
  },
  formColumnsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  formTitle: {
    color: '#fff',
    fontWeight: 600,
    marginBottom: '10px',
    marginTop: '10px',
    padding: '5px',
    backgroundColor: '#0d324f',
  },
  subheading: {
    color: '#fff',
    marginBottom: '10px',
    padding: '5px',
    backgroundColor: '#0d324f',
  },
  formRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  form: {
    marginBottom: '30px',
  },
  inputWrapper: {
    width: '45%',
  },
  formColumn: {
    width: '45%',
  },
  textInput: {
    width: '100%',
  },
  submitButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  submitButton: {
    width: '50%',
    padding: '5px',
    background: '#2f2e2e',
    color: '#fff',
    fontSize: '21px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: 'none',
    marginTop: '20px',
  },
  errorText: {
    fontSize: '13px',
    color: '#f21212',
  },
  errorsRow: {
    width: '100%',
  },
  checkboxesWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '40px',
    maxHeight: '50%',
  },
  addMoreWrapper: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  checkboxesInput: {
    maxHeight: '400px',
    width: '80%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2f2e2e',
    },
  },
  selectInput: {
    minWidth: '10%',
  },
  hazardBlockWrapper: {
    width: '90%',
  },
  hazardBlockSummary: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  hazardCodeTitleBlock: {
    width: '70%',
  },
  additionalDataBtnWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ingredientWrapper: {
    marginBottom: '20px',
    padding: '10px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  ingredientForm: {
    width: '90%',
  },
  statementsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  pictogramsWrapper: {
    display: 'flex',
    marginBottom: '20px',
  },
  pictogram: {
    width: '80px',
    height: '80px',
    marginRight: '10px',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default useStyles;
