import { TextField } from '@mui/material';

const InputField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}: {
  label?: string;
  input?: any;
  meta?: any;
  custom: any;
  classes?: any;
  multiline?: any;
}) => {
  const { classes, multiline } = custom;
  return (
    <TextField
      hiddenLabel
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      classes={classes}
      disableUnderline
      variant="filled"
      size="small"
      {...input}
      {...custom}
    />
  );
};

export default InputField;
