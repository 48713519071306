import React, { useMemo } from 'react';
import { Field, reduxForm, FieldArray, change, InjectedFormProps } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import countryList from 'react-select-country-list';
import { Box, Button, Chip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { CheckboxInput, SelectRegulationsPopup } from 'components';
import TextFieldInputV2 from 'components/text-field';
import SelectInput from 'components/select-input';
import { required } from '../validators';
import { getRegulatories } from 'api';
import { availableLanguages } from '../../../constants';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import DatePicker from '../date-picker';
import { CustomFormProps } from '../utils';
import SaveAsButton from 'components/save-as-btn';
import MultipleSelectInput from 'components/chip';

interface Section0FormProps extends CustomFormProps {
  countriesSettings?: any;
}

const RegulatoryFields = ({ fields }: { fields: any }) => {
  const classes = useStyles();
  const { t } = useTranslation(['sds_authoring']);
  const [openAddPopup, setOpenAddPopup] = React.useState(false);
  const [regulatories, setRegulatories] = React.useState([]);
  const languageCode = useSelector(
    (state: any) => state.form.section0Form?.values?.language
  );
  const countryCode = useSelector(
    (state: any) => state.form.section0Form?.values?.sds_country
  );

  const handleSelectRegulatories = (addedRegulatories: any) => {
    addedRegulatories.map((id: any) => {
      const element: any = regulatories.find((el: any) => el.id === id);
      if (element) {
        fields.push({
          id: id,
          literal: element.literal,
          name: element.name,
          regulating_entity: element.regulating_entity,
          regulation_area: element.regulation_area,
        });
      }
    });
  };

  React.useEffect(() => {
    const getRegulationsRequest = getRegulatories({
      language_code: languageCode,
      country_code: countryCode,
    });
    getRegulationsRequest.then((response) => {
      if (response.status === 200) {
        setRegulatories(response.data);
      }
    });
  }, [countryCode]);

  const selectRegulationsPopupProps = {
    open: openAddPopup,
    choices: regulatories,
    onSubmit: (selectedItems: any) => {
      handleSelectRegulatories(selectedItems);
      setOpenAddPopup(false);
    },
    onClose: () => setOpenAddPopup(false),
    selected: fields.map((el: any) => el.id),
  };

  return (
    <div className={classes.regWrapper}>
      <Box className={classes.chipsWrapper}>
        {fields.getAll()?.map((regulationItem: any, index: number) => {
          if (regulationItem.literal) {
            return (
              <Chip
                label={regulationItem.literal}
                onDelete={() => fields.remove(index)}
                deleteIcon={<DeleteIcon />}
                variant="outlined"
                color="primary"
              />
            );
          }
        })}
        <Button
          onClick={() => setOpenAddPopup(true)}
          sx={{ color: 'colors.text', pl: 0 }}
        >
          <AddIcon />
          {t('sds_authoring:add_regulations')}
        </Button>
      </Box>

      {openAddPopup && (
        <SelectRegulationsPopup {...selectRegulationsPopupProps} />
      )}
    </div>
  );
};

const ZeroSectionForm = (props: Section0FormProps & InjectedFormProps<{}, Section0FormProps>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['sds_authoring', 'section_title']);
  const { handleNext, dirty, countriesSettings, handleCopy } = props;
  const translations = useSelector((state: any) => state.pageReducer.translations);
  const form = useSelector((state: any) => state.form.section0Form);
  const productDescriptionForm = useSelector((state: any) => state.form.productDescriptionForm);
  const sdsInfo = useSelector((state: any) => state.pageReducer.sdsInfo);
  const userInfo = useSelector((state: any) => state.pageReducer.userInfo);
  const countryCode = useSelector(
    (state: any) => state.form.section0Form?.values?.sds_country
  );

  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((el: any) => {
          return { value: el.value.toLowerCase(), label: el.label };
        }),
    []
  );

  const handleSubmit = () => {
    if (dirty) {
      const regulatoryIds = form?.values?.regulations?.map((el: any) => el.id);
      handleNext({
        ...form.values,
        regulations_input: regulatoryIds
          ? regulatoryIds.filter((el: any) => !!el)
          : sdsInfo?.regulations.map((el: any) => el.id),
      });
    } else {
      handleNext(null);
    }
  };
  React.useEffect(() => {
    if (sdsInfo) {
      return;
    }
    

    if (!countryCode) {
      dispatch(
        change('section0Form', 'sds_country', userInfo?.customer?.country?.short_name)
      );
    }

    if (!form?.sds_pdf_country) {
      dispatch(
        change('section0Form', 'sds_pdf_country', [userInfo?.customer?.country?.short_name])
      );
    }

    if (!form?.language) {
      dispatch(change('section0Form', 'language', userInfo?.language_code));
    }
  }, [userInfo]);

  React.useEffect(() => {
    if (sdsInfo || !countryCode) {
      return;
    }
    const selectedCountrySetting = countriesSettings[countryCode]
    if (selectedCountrySetting) {
      if (selectedCountrySetting.regulation) {
        dispatch(
          change('section0Form', 'regulations', [
            countriesSettings[countryCode]?.regulation,
          ])
        );
      }
      if (productDescriptionForm) {
        dispatch(
          change(
            'productDescriptionForm', 
            'emergency_provider',
            selectedCountrySetting.emergency_provider,
          )
        );
        dispatch(
          change(
            'productDescriptionForm', 
            'emergency_telephone',
            selectedCountrySetting.emergency_phone_number,
          )
        );
      }
    }
  }, [countryCode, countriesSettings]);

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">{t('section_title:default')}</Typography>
      <form className={classes.form}>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={4}>
            <Field
              validate={[required]}
              name="version"
              component={TextFieldInputV2}
              label={
                translations.sections.version
                  ? translations.sections.version
                  : t('fields:version')
              }
              classes={{ root: classes.textInput }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={4}>
            <Field
              validate={[required]}
              name="issued_date"
              component={DatePicker}
              label={
                translations.sections.issued_date
                  ? translations.sections.issued_date
                  : t('fields:issued_date')
              }
              classes={{ root: classes.textInput }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              name="revision_date"
              component={DatePicker}
              label={
                translations.sections.revision_date
                  ? translations.sections.revision_date
                  : t('fields:revision_date')
              }
              classes={{ root: classes.textInput }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={4}>
            <Field
              validate={[required]}
              name="sds_country"
              component={SelectInput}
              valueField="value"
              labelField="label"
              label={t('sds_authoring:sds_country')}
              classes={{ root: classes.textInput }}
              options={countryOptions}
            />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <Field
              validate={[required]}
              name="sds_pdf_country"
              component={MultipleSelectInput}
              valueField="value"
              labelField="label"
              label={t('sds_authoring:sds_country')}
              classes={{ root: classes.textInput }}
              options={countryOptions}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              validate={[required]}
              name="language"
              component={SelectInput}
              valueField="value"
              labelField="label"
              label={t('sds_authoring:sds_language')}
              classes={{ root: classes.textInput }}
              options={availableLanguages}
            />
          </Grid>
          <Grid item xs={12}>
          <Field
            name="is_psis"
            component={CheckboxInput}
            label={t('sds_authoring:product_safety_information_sheet')}
          />
        </Grid>
        </Grid>
        <FieldArray name="regulations" component={RegulatoryFields} />
      </form>
      <SubmitButton handleSubmit={handleSubmit} />
      <SaveAsButton handleSubmit={handleCopy} />

    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.section0Form?.values) {
    const sdsPdfCountries = state.pageReducer.sdsInfo?.sds_pdf_countries
    ? state.pageReducer.sdsInfo.sds_pdf_countries
    : [state.pageReducer.userInfo?.customer?.country?.short_name];
    return {
      initialValues: {
        sds_country: sdsPdfCountries.length > 0
          ? sdsPdfCountries[0]
          : state.pageReducer.userInfo?.customer?.country?.short_name,
          sds_pdf_country: sdsPdfCountries,
        language: state.pageReducer.sdsInfo?.language_code
          ? state.pageReducer.sdsInfo.language_code
          : state.pageReducer.userInfo.language_code,
        version: state.pageReducer.sdsInfo.version,
        revision_date: state.pageReducer.sdsInfo.revision_date,
        issued_date: state.pageReducer.sdsInfo.issued_date,
        regulations: state.pageReducer.sdsInfo?.regulations
          ? state.pageReducer.sdsInfo.regulations
          : [],
        is_psis: state.pageReducer.sdsInfo?.is_psis,
      },
      countriesSettings: state.pageReducer.countriesSettings,
    };
  }
  return { countriesSettings: state.pageReducer.countriesSettings };
}

export default connect(mapStateToProps)(
  reduxForm<{}, Section0FormProps>({
    form: 'section0Form',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(ZeroSectionForm)
);
