import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  content: {
    height: '100%',
    padding: '20px',
    marginTop: '58px',
  },
}));
export default useStyles;
