import axios from 'axios';
import { renderSnackbar, validateToken, getEnv } from '../utils';

const workingEnv = getEnv();

export const BACKEND_URL =
  workingEnv === 'production'
    ? 'https://admin55.sdsmanager.com'
    : workingEnv == 'rc'
    ? 'https://rc-admin55.sdsmanager.com'
    : workingEnv == 'staging'
    ? 'https://staging-admin55.sdsmanager.com'
    : 'http://localhost:8000';

export const BASE_API_URL = `${BACKEND_URL}/api`;

const axiosAuthoringInstance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 50000,
  headers: {
    Authorization: localStorage.getItem('access_token')
      ? 'JWT ' + localStorage.getItem('access_token')
      : null,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

axiosAuthoringInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    let pathName = window.location.pathname;

    let redirectUrl =
      pathName === '/' ? `/login/` : `/login/?redirectPath=${pathName}`;

    // Prevent infinite loops
    if (originalRequest.url.includes('/auth/refresh-token')) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');

      window.location.href = redirectUrl;

      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken && validateToken(refreshToken)) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp !== undefined && tokenParts.exp > now) {
          return axiosAuthoringInstance
            .post('/auth/refresh-token/', { token: refreshToken })
            .then((response) => {
              localStorage.setItem('access_token', response.data.token);
              localStorage.setItem('refresh_token', refreshToken);

              axiosAuthoringInstance.defaults.headers['Authorization'] =
                'JWT ' + response.data.token;
              originalRequest.headers['Authorization'] =
                'JWT ' + response.data.token;

              return axiosAuthoringInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (!pathName.includes('/login')) window.location.href = redirectUrl;
      }

      if (!pathName.includes('/login')) window.location.href = redirectUrl;

      return Promise.reject(error);
    }

    if (error.response.status === 403 && pathName.includes('/login')) {
      return Promise.reject(error);
    }

    if (error.response?.data?.error_code !== undefined) {
      if (
        typeof error.response?.data?.error_message == 'string' &&
        error.response?.data?.error_code !==
          'COMPONENT_WITH_SAME_CAS_NO_ALREADY_EXISTS'
      ) {
        renderSnackbar([error.response.data.error_message]);
      }

      if (typeof error.response?.data?.error_message == 'object') {
        const res = [];
        Object.entries(error.response.data.error_message).map(
          ([key, value]) => {
            res.push(
              `<span style="text-transform: capitalize">${key}</span>: ${value.join(
                ', '
              )}`
            );
          }
        );
        renderSnackbar(res);
      }

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export const get = (path, params) => {
  return axiosAuthoringInstance
    .get(path, { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const post = (path, data) => {
  return axiosAuthoringInstance
    .post(path, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const deleteRequest = (path) => {
  return axiosAuthoringInstance
    .delete(path)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const update = (path, data) => {
  return axiosAuthoringInstance
    .patch(path, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
export default axiosAuthoringInstance;
