import { EditComponentClassificationsForm } from 'components';
import DesktopDialog from 'components/dialog/Dialog';
import { useTranslation } from 'react-i18next';

import { PopupProps } from './PopupsInterface';
import useStyles from './styles';

const EditComponentClassificationsPopup = ({ open, onClose, onSubmit }: PopupProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['actions']);

  if (!open) {
    return null;
  }

  return (
    <DesktopDialog
      onClose={() => {
        onClose();
      }}
      maxWidth={'sm'}
      title={t('tooltips:edit_classifications')}
      fullWidth={true}
    >
      <EditComponentClassificationsForm onSubmit={onSubmit} />
    </DesktopDialog>
  );
};

export default EditComponentClassificationsPopup;
