import axiosAuthoringInstance from './constants';

export const updateAuthoringCustomerLogo = (
  customerId: number | string,
  data: FormData
) => {
  return axiosAuthoringInstance
    .patch(`/customer/${customerId}/`, data)
    
};

export const updateLogoPosition = (
  sdsId: string | number,
  preSelectPosition: string
) => {
  return axiosAuthoringInstance
    .patch(`/sdsAuthoring/files/${sdsId}/`, {
      logo_position: preSelectPosition
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
