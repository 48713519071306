import TextFieldInputV2 from 'components/text-field';
import { useSelector } from 'react-redux';
import { StepFieldsProps } from '../utils';

export const getStep15Fields = (): Array<StepFieldsProps> => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  
  return [
    {
      title: translations.headers['15_1_header_safety_healt_env'],
      fields: [
        {
          name: 'safety_env_and_health_regulations_info',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'eu_regulatory_info',
          component: TextFieldInputV2,
          label: translations.sections.eu_regulatory_info,
          multiline: true,
          xs: 12,
        },
        {
          name: 'other_eu_regulations',
          component: TextFieldInputV2,
          label: translations.sections.other_eu_regulations,
          multiline: true,
          xs: 12,
        },
        {
          name: 'international_regulations',
          component: TextFieldInputV2,
          label: translations.sections.international_regulations,
          multiline: true,
          xs: 12,
        },
        {
          name: 'other_regulations',
          component: TextFieldInputV2,
          label: translations.sections.other_regulations,
          multiline: true,
          xs: 12,
        },
        {
          name: 'national_regulatory_info',
          component: TextFieldInputV2,
          label: translations.sections.national_regulatory_info,
          multiline: true,
          xs: 12,
        },
        {
          name: 'us_federal_regulations',
          component: TextFieldInputV2,
          label: translations.sections.us_federal_regulations,
          multiline: true,
          xs: 12,
        },
        {
          name: 'canadian_regulations',
          component: TextFieldInputV2,
          label: translations.sections.canadian_regulations,
          multiline: true,
          xs: 12,
        },
        {
          name: 'uk_regulatory_info',
          component: TextFieldInputV2,
          label: translations.sections.uk_regulatory_info,
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['15_2_header_chemical_safety_assessment'],
      fields: [
        {
          name: 'chemical_safety_assessment',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['15_3_header_aditional_information'],
      fields: [
        {
          name: 'additional_regulations_info',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
      ],
    },
  ];
};
