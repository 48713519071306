import React from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

const CheckboxInput = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  const { classes } = custom;
  return (
    <FormControl classes={classes} error={touched && error}>
      <FormControlLabel
        control={<Checkbox checked={input?.value} color="primary" {...input} />}
        label={label}
      />
    </FormControl>
  );
};

export default CheckboxInput;
