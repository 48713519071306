import { CustomSnackbar } from 'components';
import * as ReactDOM from 'react-dom';
import { ThemeProvider as ThemeProviderCore } from '@material-ui/core/styles';
import { ThemeProvider as ThemeProviderMui } from '@mui/material/styles';
import { theme, themeMui } from './AppThemeProvider';
export function renderSnackbar(text: Array<string>) {
  const sNew = document.createElement('div');
  document.getElementsByTagName('body')[0].appendChild(sNew);
  ReactDOM.render(
    <ThemeProviderCore theme={theme}>
      <ThemeProviderMui theme={themeMui}>
        <CustomSnackbar
          onClose={() => {
            sNew.remove();
          }}
          text={text}
          onClick={() => {
            sNew.remove();
          }}
          actionLabel={'Close'}
        />
      </ThemeProviderMui>
    </ThemeProviderCore>,
    sNew
  );
}
