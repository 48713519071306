import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { getColumns } from '../helper';
import { ProtectionTableCell } from '../protection-table-cell';
import ProtectionTableHeader from '../protection-table-header';

export const ProtectionTable = ({ fields }: { fields: any }) => {
  const columns = getColumns();
  return (
    <>
      <Paper elevation={3} sx={{ marginBottom: '20px'}}>
        <TableContainer>
          <Table size="small" aria-label="a dense table" stickyHeader>
            <TableHead>
              <TableRow>
                <ProtectionTableHeader columns={columns} />
              </TableRow>
            </TableHead>

            <TableBody>
              {fields?.getAll()?.map((ppeItem: any, index: any) => {
                return (
                  <TableRow key={index}>
                    {columns.map((column, idx) => {
                      return (
                        <ProtectionTableCell
                          key={column.id + index}
                          index={column.id + index}
                          column={column}
                          row={ppeItem}
                          onDelete={() => fields.remove(index)}
                        />
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
