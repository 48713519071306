import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';

import { Button, Grid } from '@mui/material';
import TextFieldInputV2 from 'components/text-field';
import useStyles from './styles';

const ComponentDetailsForm = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation(['actions']);
  const { onSubmit, dirty } = props;
  const form = useSelector((state: any) => state.form.componentDetailsForm);

  const handleSubmit = () => {
    onSubmit(form.values);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container rowSpacing={2} columnSpacing={5}>
        <Grid item xs={12}>
          <Field
            name="component_name"
            component={TextFieldInputV2}
            label={t('sds_authoring:component_name')}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="supplier_name"
            component={TextFieldInputV2}
            label={t('sds_authoring:supplier_name')}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="cas_no"
            component={TextFieldInputV2}
            label={t('sds_authoring:cas_number')}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="ec_no"
            component={TextFieldInputV2}
            label={t('sds_authoring:ec_number')}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="reach_no"
            component={TextFieldInputV2}
            label={t('sds_authoring:reach_number')}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="signal_word"
            component={TextFieldInputV2}
            label={t('sds_authoring:signal_word')}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleSubmit} disabled={!dirty}>
            {t('actions:update')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

function mapStateToProps(state: any) {
  if (
    state.pageReducer.componentDetails &&
    !state.form.componentDetails?.values
  ) {
    const componentDetails = state.pageReducer.componentDetails;
    return {
      initialValues: {
        component_name: componentDetails.component_name,
        supplier_name: componentDetails.supplier_name,
        ec_no: componentDetails.ec_no,
        cas_no: componentDetails.cas_no,
        reach_no: componentDetails.reach_no,
        signal_word: componentDetails.signal_word,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'componentDetailsForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(ComponentDetailsForm)
);
