import TextField from '@mui/material/TextField';

type Props = {
    label?: string,
    input?: any,
    meta?: any,
    classes?: any,
    type?: string,
    wrapperClassName?: any,
}

const TextFieldInputV2 = ({
  type,
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}: Props) => {
  const { classes, wrapperClassName } = custom;
  return (
    <div className={wrapperClassName}>
      <TextField
        label={label}
        error={touched && invalid}
        helperText={touched && error}
        classes={classes}
        style={{ background: input?.value ? '#edf5fa' : '' }}
        variant="filled"
        type={type}
        {...input}
        {...custom}
      />
    </div>
  );
};

export default TextFieldInputV2;
