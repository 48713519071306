import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import HazardTableHeader from '../hazard-table-header';
import { getColumns, getPrecautionColumns } from '../helper';
import { HazardTableCell } from '../hazard-table-cell';

function HazardCodeTable({ fields, pCodes }: { fields: any; pCodes?: any }) {
  const columns = pCodes ? getPrecautionColumns() : getColumns();

  return (
    <>
      <Paper elevation={3} sx={{ mt: 2 }}>
        <TableContainer>
          <Table size="small" aria-label="a dense table" stickyHeader>
            <TableHead>
              <TableRow>
                <HazardTableHeader columns={columns} />
              </TableRow>
            </TableHead>

            <TableBody className="">
              {fields.getAll()?.map((hazard: any, index: number) => {
                return (
                  <TableRow key={index}>
                    {columns.map((column, idx) => {
                      return (
                        <HazardTableCell
                          key={column.id + index}
                          index={column.id + index}
                          column={column}
                          row={hazard}
                          onDelete={() => fields.remove(index)}
                        />
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default HazardCodeTable;
