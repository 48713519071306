import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Add, Home, NavigateNext } from '@mui/icons-material';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { ConfirmActionPopup, SelectSDSToImportPopup } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';

import SDSTable from 'components/home/sds-table';
import useStyles from './styles';
import { OurSDSFilter } from 'components/home/filter';
import { OUR_SDS_STATUS } from 'enums/our-sds-status.enum';
import { getCreatedPDFs } from 'api';
import {
  archivePDF,
  createNewRevision,
  deletePDF,
  unArchivePDF,
} from 'components/home/helper';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

const OurSDS = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation([
    'sds_list',
    'tooltips',
    'status_choices',
    'languages',
    'header',
  ]);
  const [openImportPopup, setOpenImportPopup] = React.useState(false);
  const [openArchivePopup, setOpenArchivePopup] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [openUnArchivePopup, setOpenUnArchivePopup] = React.useState(false);
  const [openCreateRevisionPopup, setOpenCreateRevisionPopup] =
    React.useState(false);
  const [selectedSDS, setSelectedSDS] = React.useState<any>(null);
  const [showNoDataMessage, setShowNoDataMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sdsPaginatedData, setSdsPaginatedData] = React.useState({
    results: [],
    count: 10,
  });

  const [selectedStatuses, setSelectedStatuses] = React.useState<
    Array<OUR_SDS_STATUS>
  >([
    OUR_SDS_STATUS.DRAFT,
    OUR_SDS_STATUS.APPROVED,
    OUR_SDS_STATUS.READY_FOR_APPROVAL,
  ]);

  const handleStatusesChange = (value: Array<OUR_SDS_STATUS>) => {
    setSelectedStatuses(value);
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleArchiveSDS = () => {
    setOpenArchivePopup(false);
    setSelectedSDS(null);
    archivePDF(selectedSDS.id, getPDFs);
  };

  const handleUnArchiveSDS = () => {
    setOpenUnArchivePopup(false);
    setSelectedSDS(null);
    unArchivePDF(selectedSDS.id, getPDFs);
  };

  const handleDeleteSDS = () => {
    setOpenDeletePopup(false);
    setSelectedSDS(null);
    deletePDF(selectedSDS.id, getPDFs);
  };

  const handleCreateNewRevision = () => {
    setOpenCreateRevisionPopup(false);
    setSelectedSDS(null);
    createNewRevision(selectedSDS.id, getPDFs);
  };

  const getPDFs = () => {
    setLoading(true);
    const getCreatedPDFsRequest = getCreatedPDFs(
      page + 1,
      rowsPerPage,
      selectedStatuses
    );

    getCreatedPDFsRequest.then((response) => {
      if (response.status === 200) {
        setLoading(false);
        setSdsPaginatedData(response.data);
      }
    });
  };

  React.useEffect(() => {
    setLoading(true);
    const getCreatedPDFsRequest = getCreatedPDFs(1, 10, []);

    getCreatedPDFsRequest.then((response) => {
      if (response.status === 200) {
        if (response.data.results.length === 0) {
          setShowNoDataMessage(true);
        }
        setLoading(false);
      }
    });
  }, []);

  React.useEffect(() => {
    getPDFs();
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    setPage(0);
    getPDFs();
  }, [selectedStatuses]);

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        <LinkRouter
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          to="/"
        >
          <Home fontSize="small" />
        </LinkRouter>
        <LinkRouter
          underline="hover"
          to="#"
          aria-current="page"
          fontSize="16px"
        >
          SDSs
        </LinkRouter>
      </Breadcrumbs>
      <Typography variant="h4" my={1}>
        {t('header:our_sds')}
      </Typography>
      {showNoDataMessage ? (
        <div className={classes.createBtnWrapper}>
          <Button
            variant="contained"
            onClick={() => history.push('/sds-authoring/')}
          >
            <Add />
            {t('sds_list:create_new')}
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenImportPopup(true)}
            title={t('sds_list:import_tooltip')}
          >
            <Add />
            {t('sds_list:import_sds')}
          </Button>
        </div>
      ) : (
        <>
          <Box className={classes.actionsWrapper}>
            <OurSDSFilter
              selectedStatuses={selectedStatuses}
              handleStatusesChange={handleStatusesChange}
            />
            <div className={classes.createBtnWrapper}>
              <Button
                variant="contained"
                onClick={() => history.push('/sds-authoring/')}
              >
                <Add />
                {t('sds_list:create_new')}
              </Button>
              <Button
                variant="contained"
                onClick={() => setOpenImportPopup(true)}
                title={t('sds_list:import_tooltip')}
              >
                <Add />
                {t('sds_list:import_sds')}
              </Button>
            </div>
          </Box>

          <SDSTable
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            sdsPaginatedData={sdsPaginatedData}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            setSelectedSDS={setSelectedSDS}
            setOpenArchivePopup={setOpenArchivePopup}
            setOpenDeletePopup={setOpenDeletePopup}
            setOpenCreateRevisionPopup={setOpenCreateRevisionPopup}
            setOpenUnArchivePopup={setOpenUnArchivePopup}
          />
        </>
      )}

      {openImportPopup && (
        <SelectSDSToImportPopup
          onClose={() => setOpenImportPopup(false)}
          open={openImportPopup}
        />
      )}

      {openArchivePopup && selectedSDS && (
        <ConfirmActionPopup
          title={t('popups:confirm_archive')}
          open={openArchivePopup}
          onClose={() => {
            setOpenArchivePopup(false);
            setSelectedSDS(null);
          }}
          onSubmit={handleArchiveSDS}
        />
      )}

      {openDeletePopup && selectedSDS && (
        <ConfirmActionPopup
          title={t('popups:confirm_delete')}
          open={openDeletePopup}
          onClose={() => {
            setOpenDeletePopup(false);
            setSelectedSDS(null);
          }}
          onSubmit={handleDeleteSDS}
        />
      )}
      {openUnArchivePopup && selectedSDS && (
        <ConfirmActionPopup
          title={t('popups:get_back_from_archive')}
          open={openUnArchivePopup}
          onClose={() => {
            setOpenUnArchivePopup(false);
            setSelectedSDS(null);
          }}
          onSubmit={handleUnArchiveSDS}
        />
      )}
      {openCreateRevisionPopup && selectedSDS && (
        <ConfirmActionPopup
          title={t('popups:confirm_create_new_revision')}
          open={openCreateRevisionPopup}
          onClose={() => {
            setOpenCreateRevisionPopup(false);
            setSelectedSDS(null);
          }}
          onSubmit={handleCreateNewRevision}
        />
      )}
    </>
  );
};

export default OurSDS;
