import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    position: 'relative',
    marginLeft: 310,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    },
  },
  stepFormWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  reviewDataRightSideContent: {
    width: '100%',
    fontSize: '18px',
  },
  linkWrapper: {
    marginLeft: 10
  },
  navigator: {
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
  }
}));

export default useStyles;
