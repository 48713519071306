import { Add, Clear } from '@mui/icons-material';
import { Box, Button, Chip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddFilterPopup } from '../add-filter-popup';
import useStyles from './style';
import { OUR_SDS_STATUS } from 'enums/our-sds-status.enum';

export const OurSDSFilter = ({
  selectedStatuses,
  handleStatusesChange,
}: {
  selectedStatuses: Array<OUR_SDS_STATUS>;
  handleStatusesChange: (value: Array<OUR_SDS_STATUS>) => void;
}) => {
  const classes = useStyles();
  const [openAddPopup, setOpenAddPopup] = React.useState(false);
  const { t } = useTranslation(['sds_authoring', 'sds_list']);

  const getChipColor = (status: OUR_SDS_STATUS): string => {
    if (status === OUR_SDS_STATUS.DRAFT) {
      return 'secondary';
    }
    if (status === OUR_SDS_STATUS.ARCHIVED) {
      return 'amber';
    }
    if (status === OUR_SDS_STATUS.APPROVED) {
      return 'success';
    }
    if (status === OUR_SDS_STATUS.DELETED) {
      return 'red';
    }
    if (status === OUR_SDS_STATUS.DEPRECATED_VERSION) {
      return 'purple';
    }
    return 'primary';
  };
  
  const handleOnDelete = (del: OUR_SDS_STATUS) => {
    const deleted = selectedStatuses.filter((status) => status !== del);
    handleStatusesChange(deleted);
  };

  return (
    <>
      <Box className={classes.chipsWrapper}>
        {selectedStatuses.map((status: OUR_SDS_STATUS, index: number) => {
          var chipColor = getChipColor(status);

          return (
            <Chip
              key={index}
              label={t(`status_choices:${status}`)}
              deleteIcon={<Clear />}
              onDelete={() => handleOnDelete(status)}
              variant={'outlined'}
              sx={{
                color: `${chipColor}.main`,
                borderColor: `${chipColor}.main`,
                '& .MuiChip-deleteIcon': {
                  color: `${chipColor}.main`,
                },
              }}
            />
          );
        })}
        <Button
          onClick={() => setOpenAddPopup(true)}
          sx={{ color: 'colors.text', pl: 0 }}
        >
          <Add />
          {t('sds_list:add_filter')}
        </Button>
      </Box>
      {openAddPopup && (
        <AddFilterPopup
          open={openAddPopup}
          onSubmit={(selectedItems: Array<OUR_SDS_STATUS>) => {
            handleStatusesChange(selectedItems);
            setOpenAddPopup(false);
          }}
          onClose={() => setOpenAddPopup(false)}
          selected={selectedStatuses}
        />
      )}
    </>
  );
};
