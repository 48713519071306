import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import CustomLoader from 'components/loader/Loader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getColumnList } from '../helper';
import { OurComponentsCell } from '../our-components-cell';
import OurComponentsHeader from '../our-components-header';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

export const OurComponentsTable = ({
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  loading,
  setSelectedComponent,
  setOpenEditClassificationsPopup,
  setOpenArchivePopup,
  componentsPaginatedData,
  setOpenUnArchivePopup,
}: {
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: any, newPage: number) => any;
  handleChangeRowsPerPage: (event: any) => any;
  loading: boolean;
  setSelectedComponent: (value: boolean) => void;
  setOpenEditClassificationsPopup: (value: boolean) => void;
  setOpenArchivePopup: (value: boolean) => void;
  componentsPaginatedData: any;
  setOpenUnArchivePopup: (value: boolean) => void;
}) => {
  const history = useHistory();
  const columns = getColumnList();
  const { t } = useTranslation(['sds_authoring']);

  const getMissedClassificationsForComponent = (component: any) => {
    const codeWithMissedClassification: Array<any> = [];
    component.classifications.map((el: any) => {
      if (!el.abbreviation) {
        codeWithMissedClassification.push(el.code);
      }
    });
    return codeWithMissedClassification;
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Paper elevation={3} sx={{ mt: 2 }}>
          <TableContainer>
            <SimpleBar style={{ maxHeight: '72vh' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <OurComponentsHeader columns={columns} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {componentsPaginatedData.results.map((row: any) => {
                    const codesWithMissedClassifications =
                      getMissedClassificationsForComponent(row);
                    return (
                      <TableRow
                        key={row.id}
                        sx={{ cursor: 'pointer' }}
                        hover
                        onClick={() => {
                          history.push(`/our-components/${row.id}`);
                        }}
                      >
                        {columns.map((column, index) => (
                          <OurComponentsCell
                            key={index}
                            index={index}
                            column={column}
                            row={row}
                            codesWithMissedClassifications={
                              codesWithMissedClassifications
                            }
                            setSelectedComponent={setSelectedComponent}
                            setOpenEditClassificationsPopup={
                              setOpenEditClassificationsPopup
                            }
                            setOpenArchivePopup={setOpenArchivePopup}
                            setOpenUnArchivePopup={setOpenUnArchivePopup}
                          />
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      count={componentsPaginatedData.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={t('sds_authoring:rows_per_page')}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </SimpleBar>
          </TableContainer>
        </Paper>
      )}
    </>
  );
};
