import axiosAuthoringInstance, { BACKEND_URL } from './constants';

export const getRegulatories = (params) => {
  return axiosAuthoringInstance
    .get('/sdsAuthoring/utils/regulations/', { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getPDFFile = (sdsId, logoPosition) => {
  return axiosAuthoringInstance
    .get(`/sdsAuthoring/files/${sdsId}/pdf/`, {
      params: {
        logo_position: logoPosition
      }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getPPEItems = (categories, languageCode) => {
  const newParams = new URLSearchParams();
  categories?.map((el) => newParams.append('category', el));
  newParams.append('language', languageCode);
  return axiosAuthoringInstance
    .get('sdsAuthoring/utils/ppe/', { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAllPdfs = (params) => {
  return axiosAuthoringInstance
    .get('/pdfs/', { params: params })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getCreatedPDFs = (page, pageSize, statuses) => {
  const newParams = new URLSearchParams();
  statuses?.map((el) => newParams.append('status', el));
  newParams.append('page', page);
  newParams.append('page_size', pageSize);

  return axiosAuthoringInstance
    .get('sdsAuthoring/files/', { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getCreatedComponents = (page, pageSize, statuses) => {
  const newParams = new URLSearchParams();
  newParams.append('page', page);
  newParams.append('page_size', pageSize);
  statuses?.map((el) => newParams.append('status', el));

  return axiosAuthoringInstance
    .get('sdsAuthoring/customerComponents/', { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getUserAvatar = (url) => {
  if (url.includes(BACKEND_URL)) return url;
  return BACKEND_URL + url;
};