import {
  Typography,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';

export const ComponentsStructuredDataPreview = ({
  structuredDataPerComponent,
  fieldsNames,
  fieldsStyles,
  translations,
}: {
  structuredDataPerComponent: any;
  fieldsNames: any;
  fieldsStyles: any;
  translations: any;
}) => {
  return (
    <>
      {Object.keys(structuredDataPerComponent).map((el) => (
        <>
          <Typography variant="body1" mb={2}>
            {structuredDataPerComponent[el].componentName}
          </Typography>
          <Typography variant="body1" mb={2}>
            {structuredDataPerComponent[el].description}
          </Typography>
          <Paper elevation={3}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {fieldsNames?.map((fieldName: any) => (
                      <TableCell style={fieldsStyles[fieldName]}>
                        {translations?.layout[`${fieldName}`]
                          ? translations?.layout[`${fieldName}`]
                          : fieldName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {structuredDataPerComponent[el].rows.map(
                    (row: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          {fieldsNames.map((fieldName: any) => (
                            <TableCell style={fieldsStyles[fieldName]}>
                              {translations?.layout[`${row[fieldName]}`]
                                ? translations?.layout[`${row[fieldName]}`]
                                : row[fieldName]}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      ))}
    </>
  );
};