import {
  Box,
  Button,
  Step,
  StepButton,
  StepContent,
  Stepper,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getSteps } from '../helper';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useStyles } from './styles';

function MobileStepper({
  activeStep,
  setActiveStep,
}: {
  activeStep: number;
  setActiveStep: (step: number) => void;
}) {
  const classes = useStyles();
  const steps = getSteps();
  const completedSteps = useSelector(
    (state: any) => state.pageReducer.completedAuthoringStep
  );

  return (
    <div className={classes.root}>
      <SimpleBar className={classes.stepper}>
        <Stepper
          nonLinear
          alternativeLabel
          activeStep={activeStep}
        >
          {steps.map((step, index) => (
            <Step key={index} sx={{minWidth: '75px'}} completed={completedSteps.includes(index)}>
              <StepButton
                icon={index.toString()}
                onClick={() => setActiveStep(index)}
              >
                <Typography variant="caption">{step.title}</Typography>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </SimpleBar>
    </div>
  );
}

export default MobileStepper;
