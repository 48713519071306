import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import TextFieldInputV2 from 'components/text-field';
import useStyles from './styles';
import { Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface Props {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  errors: any;
}

const LoginFormV2: React.FC<Props & InjectedFormProps<{}, Props>> = (
  props: any
) => {
  const classes = useStyles();
  const { handleSubmit, errors } = props;
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { t } = useTranslation(['sds_authoring', 'header']);

  return (
    <div className={classes.formWrapper}>
      <form id="loginForm" name="loginForm" onSubmit={handleSubmit}>
        <div className={classes.formRow}>
          <Field
            name="email"
            component={TextFieldInputV2}
            label={t('sds_authoring:email')}
            classes={{ root: classes.textInput }}
            type="email"
          />
        </div>
        <div className={classes.formRow}>
          <Field
            name="password"
            component={TextFieldInputV2}
            label={t('sds_authoring:password')}
            classes={{ root: classes.textInput }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={(e: any) => {
                      e.preventDefault();
                    }}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={classes.errorsRow}>
          {errors.map((el: string, index: number) => {
            return <p className={classes.errorText}>{el}</p>;
          })}
        </div>
        <div className={classes.submitButtonWrapper}>
          <Button
            variant="contained"
            form="loginForm"
            type="submit"
            color="primary"
            sx={{
              padding: '12px 0px',
              width: '100%',
              fontSize: 14,
              borderRadius: '5px',
              marginBottom: '20px',
            }}
          >
            {t('header:login')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default reduxForm<{}, Props>({
  form: 'loginForm',
})(LoginFormV2);
