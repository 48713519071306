export const setSDSId = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'pdfId',
      payload: payload,
    });
  };
};

export const setTranslations = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'translations',
      payload: payload,
    });
  };
};

export const setSDSInfo = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'sdsInfo',
      payload: payload,
    });
  };
};

export const setUserInfo = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'userInfo',
      payload: payload,
    });
  };
};

export const setLanguageCode = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'languageCode',
      payload: payload,
    });
  };
};

export const setCountriesSettings = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'countriesSettings',
      payload: payload,
    });
  };
};

export const setOverwrittenHazardStatements = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'overwrittenHazardStatements',
      payload: payload,
    });
  };
};

export const setPhysChemProperties = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'physChemProperties',
      payload: payload,
    });
  };
};

export const addCompletedAuthoringStep = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'addCompletedStep',
      payload: payload,
    });
  };
};

export const clearCompletedSteps = () => {
  return (dispatch) => {
    dispatch({
      type: 'clearCompletedSteps',
      payload: [],
    });
  };
};

export const setShowConfirmBackDialog = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'showConfirmBackDialog',
      payload: payload,
    });
  };
};

export const setDefaultSupplierInfo = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'defaultSupplierInfo',
      payload: payload,
    });
  };
};

export const setComponentStructuredData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'structuredComponentData',
      payload: payload,
    });
  };
};

export const setSelectedComponentId = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'selectedComponentId',
      payload: payload,
    });
  };
};

export const setStructuredDataEdited = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'structuredDataEdited',
      payload: payload,
    });
  };
};

export const setComponentClassifications = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'componentClassifications',
      payload: payload,
    });
  };
};

export const setComponentDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'componentDetails',
      payload: payload,
    });
  };
};

export const setDoNotIncludeStructuredDataFromComponents = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'doNotIncludeStructuredDataFromComponents',
      payload: payload,
    });
  };
};

export const setFormEdited = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'formEdited',
      payload: payload,
    });
  };
};

export const setOverwrittenPPEDescriptions = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'overwrittenPPEDescriptions',
      payload: payload,
    });
  };
};

export const setAdditionalPhysChemProperties = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'additionalPhysChemProperties',
      payload: payload,
    });
  };
};

export const setIsSubstanceSDS = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'isSubstanceSDS',
      payload: payload,
    });
  };
};
