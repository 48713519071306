import { Button, Checkbox, Typography } from '@mui/material';
import DesktopDialog from 'components/dialog/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import HazardCodePanel from '../sds-authoring-forms-v2/hazards-codes-form/hazard-codes-panel';
import { PopupProps } from './PopupsInterface';
import useStyles from './styles';
import { SearchBar } from '../index';

interface SelectHazardCodesPopupProps extends PopupProps {
  choices: any;
  defaultSelectedCodes: any;
}

const SelectHazardCodesPopup = React.memo(
  ({
    choices,
    defaultSelectedCodes,
    onClose,
    open,
    onSubmit,
  }: SelectHazardCodesPopupProps) => {
    const classes = useStyles();
    const { t } = useTranslation(['actions', 'popups']);
    const [selectedCodes, setSelectedCodes] =
      React.useState(defaultSelectedCodes);
    const [codes, setCodes] = React.useState(choices);

    const [importPrecautionsCodes, setImportPrecautionsCodes] =
      React.useState(true);
    const [searched, setSearched] = React.useState('');
    const requestSearch = (searchedVal: string) => {
      const filteredCodes = choices.filter((row: any) => {
        return row.statement_code
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      });
      console.log(filteredCodes);
      setCodes(filteredCodes);
    };

    const handleSelect = (value: any) => {
      if (selectedCodes.includes(value)) {
        setSelectedCodes(selectedCodes.filter((item: any) => item !== value));
      } else {
        setSelectedCodes([value, ...selectedCodes]);
      }
    };

    if (!open) {
      return null;
    }

    return (
      <DesktopDialog
        classes={{ paper: classes.popup }}
        onClose={onClose}
        title={t('popups:select_hazard_codes')}
        disableEnforceFocus
        maxWidth={'md'}
      >
        <div className={classes.content}>
          <div style={{ width: '80%', marginBottom: '20px' }}>
            <SearchBar
              id={'search-h-codes'}
              onChange={requestSearch}
              onSearch={requestSearch}
              onClear={() => {
                setSearched('');
                setCodes(choices);
              }}
            />
          </div>
          <Virtuoso
            style={{ height: '650px', width: '100%' }}
            totalCount={codes.length}
            itemContent={(index, el) => (
              <div
                key={index}
                style={{ justifyContent: 'center' }}
                className={classes.rowItem}
              >
                {codes?.[index] && (
                  <>
                    <Checkbox
                      color={'primary'}
                      onClick={() => handleSelect(codes[index].id)}
                      checked={selectedCodes.includes(codes[index].id)}
                    />
                    <HazardCodePanel hazard={codes[index]} />
                  </>
                )}
              </div>
            )}
          />
        </div>
        <div style={{ height: '140px' }} className={classes.actionWrapper}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={importPrecautionsCodes}
              color={'primary'}
              onChange={() =>
                setImportPrecautionsCodes(!importPrecautionsCodes)
              }
            />
            <Typography>{t('popups:add_p_statements')}</Typography>
          </div>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              onSubmit(selectedCodes, importPrecautionsCodes);
            }}
            className={classes.button}
          >
            {t('actions:add')}
          </Button>
          <Button
            onClick={onClose}
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
          >
            {t('actions:cancel')}
          </Button>
        </div>
      </DesktopDialog>
    );
  }
);

export default SelectHazardCodesPopup;
