import React from 'react';

import useStyles from './styles';
import { Typography } from '@material-ui/core';

const PermissionsDeniedPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography>You don't have permissions to access this page</Typography>
      </div>
    </div>
  );
};

export default PermissionsDeniedPage;
