import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isMobile } from 'utils/helper';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

type Props = {
  label?: string;
  input?: any;
  meta?: any;
  classes?: any;
  type?: string;
  wrapperClassName?: any;
};

const DatePicker = ({ type, label, input, ...custom }: Props) => {

  const handleOnChange = (date: string | null) => {
    if (!date) {
      return input.onChange(moment(new Date()).format('YYYY-MM-DD'));
    }
    input.onChange(moment(new Date(date)).format('YYYY-MM-DD'));
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isMobile() ? (
        <MobileDatePicker
          label={label}
          inputFormat="YYYY-MM-DD"
          value={input.value ? input.value : null}
          onChange={handleOnChange}
          renderInput={(params) => (
            <TextField variant="filled" {...params} {...input} {...custom} />
          )}
        />
      ) : (
        <DesktopDatePicker
          label={label}
          inputFormat="YYYY-MM-DD"
          value={input.value ? input.value : null}
          onChange={handleOnChange}
          renderInput={(params) => (
            <TextField variant="filled" {...params} {...input} {...custom} />
          )}
        />
      )}
    </LocalizationProvider>
  );
};

export default DatePicker;