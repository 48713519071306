import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  paginationButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  previewWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  approveBlockWrapper: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
  },
  tableHeading: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0,6)',
    border: 'none',
    marginBottom: '10px',
    minWidth: 100,
  },
}));
