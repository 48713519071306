import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => ({
  header: {
    display: 'flex',
    backgroundColor: theme.palette.headerColor.main,
    color: theme.palette.colors.light,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '58px',
    width: '100%',
    position: 'fixed',
    zIndex: 10,
  },
  headerNav: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  linksWrapper: {
    display: 'flex',
    width: '80%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  headerLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    color: '#ffffff',
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.colors.text,
    },
  },
  activeHeaderLink: {
    color: '#bbbbbb',
    fontWeight: 'bolder',
  },
  menuLink: {
    color: theme.palette.colors.text,
    textDecoration: 'none',
    fontSize: '16px',
  },
  optionInput: {
    color: '#000',
  },
  logoImg: {
    height: '30px',
    marginRight: 40,
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileLogo: {
    height: '30px',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      gap: 10,
      alignItems: 'center',
    },
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    width: '260px',
  },
  popoverIcon: {
    paddingLeft: 10
  }
}));

export default useStyles;
