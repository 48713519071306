import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StepFieldsProps } from '../utils';

export const getStep7Fields = (): Array<StepFieldsProps> => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const { t } = useTranslation(['fields']);
  return [
    {
      title: translations.headers['7_1_header_precautions_for_safe_handling'],
      fields: [
        {
          name: 'precautions_for_safe_handling',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'advice_safe_handling',
          component: TextFieldInputV2,
          label: translations.sections.advice_safe_handling
            ? translations.sections.advice_safe_handling
            : t('fields:advice_safe_handling'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_additional_hazards',
          component: TextFieldInputV2,
          label: translations.sections.storage_additional_hazards,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_ventilation',
          component: TextFieldInputV2,
          label: translations.sections.storage_ventilation,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_precautions',
          component: TextFieldInputV2,
          label: translations.sections.storage_precautions,
          multiline: true,
          xs: 12,
        },
        {
          name: 'technical_measures',
          component: TextFieldInputV2,
          label: translations.sections.technical_measures,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_recommended_equipment_and_procedures',
          component: TextFieldInputV2,
          label:
            translations.sections.storage_recommended_equipment_and_procedures,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_tech_env_ergo_risk',
          component: TextFieldInputV2,
          label: translations.sections.storage_tech_env_ergo_risk,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_tech_env_risk',
          component: TextFieldInputV2,
          label: translations.sections.storage_tech_env_risk,
          multiline: true,
          xs: 12,
        },
        {
          name: 'protective_measures',
          component: TextFieldInputV2,
          label: translations.sections.protective_measures
            ? translations.sections.protective_measures
            : t('fields:protective_measures'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'general_occupational_hygiene',
          component: TextFieldInputV2,
          label: translations.sections.general_occupational_hygiene
            ? translations.sections.general_occupational_hygiene
            : t('fields:general_occupational_hygiene'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_incompatibility',
          component: TextFieldInputV2,
          label: translations.sections.storage_incompatibility,
          multiline: true,
          xs: 12,
        },
        {
          name: 'info_about_fire',
          component: TextFieldInputV2,
          label: translations.sections.info_about_fire
            ? translations.sections.info_about_fire
            : t('fields:info_about_fire'),
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['7_2_header_conditions_safe_storage'],
      fields: [
        {
          name: 'conditions_for_safe_storage',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_area',
          component: TextFieldInputV2,
          label: translations.sections.storage_area,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_requirements',
          component: TextFieldInputV2,
          label: translations.sections.storage_requirements,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_compability',
          component: TextFieldInputV2,
          label: translations.sections.storage_compability,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_conditions',
          component: TextFieldInputV2,
          label: translations.sections.storage_conditions,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_additional_conditions',
          component: TextFieldInputV2,
          label: translations.sections.storage_additional_conditions,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_class',
          component: TextFieldInputV2,
          label: translations.sections.storage_class,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_incompatible_products',
          component: TextFieldInputV2,
          label: translations.sections.storage_incompatible_products,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_heat_and_ignition_sources',
          component: TextFieldInputV2,
          label: translations.sections.storage_heat_and_ignition_sources,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_technical_conditions',
          component: TextFieldInputV2,
          label: translations.sections.storage_technical_conditions,
          multiline: true,
          xs: 12,
        },

        {
          name: 'storage_temperature_recommended',
          component: TextFieldInputV2,
          label: translations.sections.storage_temperature_recommended,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_packing_material',
          component: TextFieldInputV2,
          label: translations.sections.storage_packing_material,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_packing_material_to_avoid',
          component: TextFieldInputV2,
          label: translations.sections.storage_packing_material_to_avoid,
          multiline: true,
          xs: 12,
        },
        {
          name: 'further_info_storage_conditions',
          component: TextFieldInputV2,
          label: translations.sections.further_info_storage_conditions,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_and_containers_requirements',
          component: TextFieldInputV2,
          label: translations.sections.storage_and_containers_requirements,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_stability',
          component: TextFieldInputV2,
          label: translations.sections.storage_stability,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_other_precautions',
          component: TextFieldInputV2,
          label: translations.sections.storage_other_precautions,
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['7_3_header_specific_end_uses'],
      fields: [
        {
          name: 'specific_end_use',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_industrial_solutions',
          component: TextFieldInputV2,
          label: translations.sections.storage_industrial_solutions,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_rmm',
          component: TextFieldInputV2,
          label: translations.sections.storage_rmm,
          multiline: true,
          xs: 12,
        },
        {
          name: 'storage_usage_precautions',
          component: TextFieldInputV2,
          label: translations.sections.storage_usage_precautions,
          multiline: true,
          xs: 12,
        },
      ],
    },
  ];
};
