import { AddCircle } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { arrayPush } from 'redux-form';
import useStyles from '../../styles';
import HazardCodeTable from '../hazard-table';

import { Box, IconButton, Typography } from '@mui/material';
import { SelectHazardCodesPopup } from 'components';

const HazardCodes = ({
  fields,
  statements,
}: {
  fields: any;
  statements: any;
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['actions', 'sds_authoring']);
  const dispatch = useDispatch();
  const [openAddPopup, setOpenAddPopup] = React.useState(false);
  const handleSelectCodes = (statementIds: any, importPrecautions: any) => {
    const addedPrecautions: Array<any> = [];
    statementIds.map((id: any) => {
      const element = statements.find((el: any) => el.id === id);
      if (element) {
        fields.push({
          id: element.id,
          pictogram_url: element.pictogram_url,
          statement_code: element.statement_code,
          statements: element.statements,
          hazard_class: element.hazard_class,
          category: element.category,
          hazard_category_abbreviation: element.hazard_category_abbreviation,
          pictogram_code: element.pictogram_code,
          signal_word: element.signal_word,
          prevention: element.prevention,
          response: element.response,
          storage: element.storage,
          disposal: element.disposal,
          precautions_statements: element.precautions_statements,
        });
        if (importPrecautions) {
          element.precautions_statements?.map((statement: any) => {
            if (!addedPrecautions?.find((el) => el.id === statement.id)) {
              dispatch(
                arrayPush('hazardsCodesForm', 'precautionsCodes', statement)
              );            }
          });
        }
      }
    });
  };
  const selectHazardCodesProps = {
    open: openAddPopup,
    choices: statements,
    onSubmit: (selectedItems: any, importPrecautions: any) => {
      handleSelectCodes(selectedItems, importPrecautions);
      setOpenAddPopup(false);
    },
    onClose: () => setOpenAddPopup(false),
    defaultSelectedCodes: fields.map((el: any) => el.statement_code),
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 500 }}>
          {t('sds_authoring:ghs_hazards_statements')}
        </Typography>
        <IconButton color="primary" onClick={() => setOpenAddPopup(true)}>
          <AddCircle />
        </IconButton>
      </Box>

      {fields.getAll() && !!fields.getAll()?.length && (
        <HazardCodeTable fields={fields} />
      )}

      {openAddPopup && <SelectHazardCodesPopup {...selectHazardCodesProps} />}
    </div>
  );
};

export default HazardCodes;
