import { Delete, Save } from '@mui/icons-material';
import Edit from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  FilledInput,
  IconButton,
  InputAdornment,
  TableCell,
  Typography,
} from '@mui/material';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOverwrittenPPEDescriptions,
  setFormEdited,
} from 'reducers/pageAction';
import { ColumnsProps } from '../helper';

const EditableCell = ({ row }: { row: any }) => {
  return (
    <FormControl variant="filled" style={{ width: '100%' }}>
      <PreviewMode row={row} />
    </FormControl>
  );
};

const PreviewMode = ({ row }: { row: any }) => {
  const dispatch = useDispatch();
  const [desc, setDesc] = React.useState(row.long_desc);
  const [editMode, setEditMode] = React.useState(false);
  const overwrittenPPEDescriptions = useSelector(
    (state: any) => state.pageReducer.overwrittenPPEDescriptions
  );

  const { focused } = useFormControl() || {};

  useEffect(() => {
    setDesc(overwrittenPPEDescriptions[row.id] ?? row.long_desc);

    if (!focused && editMode) {
      dispatch(
        setOverwrittenPPEDescriptions({
          ...overwrittenPPEDescriptions,
          [row.id]: desc,
        })
      );
      dispatch(setFormEdited(true));
      setEditMode(false);
    }
  }, [focused]);

  if (!editMode) {
    return (
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Typography variant="subtitle1">
          {overwrittenPPEDescriptions[row.id] ?? row.long_desc}
        </Typography>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setEditMode(true);
          }}
        >
          <Edit fontSize={'small'} />
        </IconButton>
      </Box>
    );
  }
  return (
    <FilledInput
      hiddenLabel
      value={desc}
      onChange={(e) => setDesc(e.target.value)}
      autoFocus
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(
                setOverwrittenPPEDescriptions({
                  ...overwrittenPPEDescriptions,
                  [row.id]: desc,
                })
              );
              dispatch(setFormEdited(true));
              setEditMode(false);
            }}
            edge="end"
          >
            <Save />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

const ProtectionTableCellValue = ({
  column,
  row,
  onDelete,
}: {
  column: string;
  row: any;
  onDelete: () => void;
}) => {
  if (column === 'link_ppe_icon') {
    return <img alt="" src={row.link_ppe_icon} width={50} height={50} />;
  }

  if (column === 'long_desc') {
    return <EditableCell row={row} />;
  }

  if (column === 'action') {
    return (
      <Button onClick={onDelete}>
        <Delete />
      </Button>
    );
  }

  return <Typography variant="subtitle1">{row[column] ?? null}</Typography>;
};

export const ProtectionTableCell = ({
  index,
  column,
  row,
  onDelete,
}: {
  index: string;
  column: ColumnsProps;
  row: any;
  onDelete: any;
}) => {
  return (
    <TableCell
      key={column.id + index}
      align={column.align ? column.align : 'center'}
      style={{
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        padding: '20px 10px',
      }}
    >
      <ProtectionTableCellValue
        column={column.id}
        row={row}
        onDelete={onDelete}
      />
    </TableCell>
  );
};
