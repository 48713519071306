import React, { CSSProperties } from 'react';
import { Icon, IconProps, Typography } from '@mui/material';
import { isForWorkplaceSafety } from 'utils/helper';

interface LogoProps {
  className?: string,
  onClick?: () => void,
  isLogin?: boolean;
  styles?: CSSProperties
}
const Logo: React.FC<LogoProps> = ({
  className = '',
  onClick= () => {},
  isLogin,
  styles={}
}) => {
  if (isForWorkplaceSafety()) {
    return (
      <div
        style={{
          fontWeight: 600,
          ...styles
        }}
        onClick={() => onClick()}
        className={className}
      >
        <img
          alt="workplace_safety_logo"
          src={'/images/workplace_safety_logo.svg'}
          style={{ height: '100%' }}
        />
        <span>SDS Creator</span>
      </div>
    )
  }
  if (isLogin)
    return <img
      alt="SDS login logo"
      src={'/images/logo.svg'}
      style={{...styles}}
    />

  return <img
    alt="SDS logo"
    src={'/images/SDS-logo-h-negative.svg'}
    className={className}
    style={{...styles}}
    onClick={() => onClick()}
  />

};

export default Logo;
