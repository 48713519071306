import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'calc(100% - 58px)',
    },
    stepper: {
        background: '#fff',
        width: '100%',
        overflowY: 'hidden',
        overflowX: 'auto',
    },
    navigator: {
        display: 'flex',
        flexDirection: 'row',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#fff',
        zIndex: 2
    }
}));
