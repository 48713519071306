import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

export default function RestoreDataPopup({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Unsaved data
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved data. Restore them?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>No, thanks</Button>
          <Button onClick={onSubmit} autoFocus>
            Restore
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
