import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  buttonsWrapper: {
    width: '50%',
    marginBottom: '40px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  formRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
  },
  submitBtn: {
    backgroundColor: '#0d324f',
    color: '#fff',
  },
});

export default useStyles;
