import { Button, Checkbox, Typography } from '@material-ui/core';
import DesktopDialog from 'components/dialog/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { PopupProps } from './PopupsInterface';
import useStyles from './styles';

interface SelectTransportPopupProps extends PopupProps {
  choices: any;
  defaultSelectedIcons: any;
}

const SelectIsoIconsPopup = React.memo(
  ({
    choices,
    defaultSelectedIcons,
    onClose,
    open,
    onSubmit,
  }: SelectTransportPopupProps) => {
    const classes = useStyles();
    const { t } = useTranslation(['popups', 'actions', 'sds_authoring']);

    const [selectedIcons, setSelectedIcons] = React.useState(
      defaultSelectedIcons ? defaultSelectedIcons : []
    );

    const handleSelect = (row: any) => {
      if (selectedIcons.find((el: any) => el.id === row.id)) {
        setSelectedIcons(
          selectedIcons.filter((item: any) => item.id !== row.id)
        );
      } else {
        setSelectedIcons([row, ...selectedIcons]);
      }
    };

    if (!open) {
      return null;
    }

    return (
      <DesktopDialog
        classes={{ paper: classes.popup }}
        onClose={onClose}
        title={t('sds_authoring:select_icons')}
        disableEnforceFocus
      >
        <div className={classes.content}>
          <Virtuoso
            style={{ height: '650px', width: '100%' }}
            totalCount={choices.length}
            itemContent={(index, el) => (
              <div key={index} className={classes.rowItem}>
                <Checkbox
                  color={'primary'}
                  onClick={() => handleSelect(choices[index])}
                  checked={selectedIcons.find(
                    (el: any) => el.id === choices[index].id
                  )}
                />
                <div className={classes.transportIconInfo}>
                  <img
                    className={classes.transportIcon}
                    src={choices[index].url}
                    alt={''}
                  />
                  <Typography>{choices[index].title}</Typography>
                </div>
              </div>
            )}
          />
        </div>
        <div className={classes.actionWrapper}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              onSubmit(selectedIcons);
            }}
            className={classes.button}
          >
            {t('actions:add')}
          </Button>
          <Button
            onClick={onClose}
            variant={'outlined'}
            color={'primary'}
            className={classes.button}
          >
            {t('actions:cancel')}
          </Button>
        </div>
      </DesktopDialog>
    );
  }
);

export default SelectIsoIconsPopup;
