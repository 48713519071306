import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StepFieldsProps } from '../utils';

export const getStep5Fields = (): Array<StepFieldsProps> => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const { t } = useTranslation(['fields']);
  return [
    {
      title: translations.headers['5_1_header_extinguishing_media'],
      fields: [
        {
          name: 'firefighting_icons',
          label: '',
          xs: 12,
          isFieldArray: true,
        },
        {
          name: 'suitable_extinguishing_media',
          component: TextFieldInputV2,
          label: translations.sections.suitable_extinguishing_media,
          multiline: true,
          xs: 12,
        },
        {
          name: 'unsuitable_extinguishing_media',
          component: TextFieldInputV2,
          label: translations.sections.unsuitable_extinguishing_media,
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title:
        translations.headers[
          '5_2_header_special_hazards_arising_from_substance'
        ],
      fields: [
        {
          name: 'special_hazards_arising_from_substance',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'explosion_data_mechanical',
          component: TextFieldInputV2,
          label: translations.sections.explosion_data_static
            ? translations.sections.explosion_data_static
            : t('fields:explosion_data_static'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'fire_hazard',
          component: TextFieldInputV2,
          label: translations.sections.fire_hazard
            ? translations.sections.fire_hazard
            : t('fields:fire_hazard'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'in_case_of_fire',
          component: TextFieldInputV2,
          label: translations.sections.in_case_of_fire
            ? translations.sections.in_case_of_fire
            : t('fields:in_case_of_fire'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'explosion_hazard',
          component: TextFieldInputV2,
          label: translations.sections.explosion_hazard
            ? translations.sections.explosion_hazard
            : t('fields:explosion_hazard'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'fire_fighting_hazardous_decomposition_products',
          component: TextFieldInputV2,
          label: translations.sections
            .fire_fighting_hazardous_decomposition_products
            ? translations.sections
                .fire_fighting_hazardous_decomposition_products
            : t('fields:fire_fighting_hazardous_decomposition_products'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'hazardous_combustion_products',
          component: TextFieldInputV2,
          label: translations.sections.hazardous_combustion_products,
          multiline: true,
          xs: 12,
        },
        {
          name: 'in_case_of_fire_liberated',
          component: TextFieldInputV2,
          label: translations.sections.in_case_of_fire_liberated
            ? translations.sections.in_case_of_fire_liberated
            : t('fields:in_case_of_fire_liberated'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'fire_fighting_reactivity',
          component: TextFieldInputV2,
          label: translations.sections.fire_fighting_reactivity
            ? translations.sections.fire_fighting_reactivity
            : t('sds_authoring:fire_fighting_reactivity'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'specific_hazard',
          component: TextFieldInputV2,
          label: translations.sections.specific_hazard
            ? translations.sections.specific_hazard
            : t('fields:specific_hazard'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'product_mixture_hazard',
          component: TextFieldInputV2,
          label: translations.sections.product_mixture_hazard
            ? translations.sections.product_mixture_hazard
            : t('fields:product_mixture_hazard'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'general_hazard',
          component: TextFieldInputV2,
          label: translations.sections.general_hazard
            ? translations.sections.general_hazard
            : t('fields:general_hazard'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['5_3_header_advice_for_firefighters'],
      fields: [
        {
          name: 'advice_for_firefighters',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'protective_actions',
          component: TextFieldInputV2,
          label: translations.sections.protective_actions,
          multiline: true,
          xs: 12,
        },
        {
          name: 'protective_equipment',
          component: TextFieldInputV2,
          label: translations.sections.protective_equipment,
          multiline: true,
          xs: 12,
        },
        {
          name: 'extinguishing_method',
          component: TextFieldInputV2,
          label: translations.sections.extinguishing_method,
          multiline: true,
          xs: 12,
        },
        {
          name: 'fire_fighting_additional_info',
          component: TextFieldInputV2,
          label: translations.sections.fire_fighting_additional_info,
          multiline: true,
          xs: 12,
        },
      ],
    },
  ];
};
