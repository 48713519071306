import { Icon } from '@mui/material';

const AllMySdsIcon = ({
  width,
  height,
  color = '',
  style = {},
}: {
  width: string | number;
  height: string | number;
  color?: string;
  style?: any;
}) => (
  <Icon style={{ width: width, height: height, fill: color, ...style }}>
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 102.000000 105.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,105.000000) scale(0.100000,-0.100000)">
        <path
          d="M229 1035 c-43 -23 -50 -56 -47 -220 l3 -150 38 -3 37 -3 0 155 0
      156 215 0 215 0 0 -120 0 -120 125 0 125 0 0 -320 0 -320 -340 0 -340 0 0 80
      0 80 -41 0 -41 0 4 -94 c3 -84 6 -97 27 -120 l24 -26 366 0 c272 1 370 4 382
      13 37 28 39 49 39 399 l0 344 -143 142 -144 142 -239 0 c-187 -1 -245 -4 -265
      -15z m660 -232 c2 -2 -23 -3 -57 -3 l-62 0 0 57 0 57 57 -54 c32 -30 60 -56
      62 -57z"
        />
        <path
          d="M63 595 c-56 -24 -71 -88 -30 -129 14 -14 48 -29 81 -37 61 -13 80
      -27 70 -53 -5 -12 -19 -16 -58 -16 -28 0 -58 5 -66 10 -15 9 -50 -13 -50 -32
      0 -16 68 -38 115 -38 74 0 112 21 125 69 16 60 3 77 -80 102 -79 24 -99 41
      -79 65 10 12 26 15 66 12 42 -4 56 -1 69 14 16 17 15 19 -19 33 -45 18 -102
      19 -144 0z"
        />
        <path
          d="M693 595 c-37 -16 -53 -42 -53 -84 0 -39 35 -66 106 -82 59 -13 78
      -28 68 -53 -7 -18 -92 -23 -123 -6 -14 7 -23 4 -37 -11 -18 -20 -18 -21 0 -35
      47 -34 162 -31 201 6 27 25 39 74 25 101 -7 11 -37 27 -80 40 -79 24 -99 41
      -79 65 10 12 26 15 66 12 42 -4 56 -1 69 14 16 17 15 19 -19 33 -45 18 -102
      19 -144 0z"
        />
        <path
          d="M310 449 l0 -151 98 4 c66 3 107 10 130 22 96 50 97 208 1 256 -30
      16 -59 20 -134 20 l-95 0 0 -151z m201 62 c33 -33 37 -67 14 -111 -16 -30 -62
      -50 -117 -50 l-28 0 0 95 0 95 51 0 c43 0 55 -5 80 -29z"
        />
      </g>
    </svg>
  </Icon>
);

export default AllMySdsIcon;
