import { deleteRequest, post } from 'api';
import { useTranslation } from 'react-i18next';

export interface OurSDSColumnsProps {
  id: string;
  title: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  width?: any;
}

export const getColumnList = (): Array<OurSDSColumnsProps> => {
  const { t } = useTranslation(['sds_list']);

  return [
    {
      id: 'product_name',
      title: t('sds_list:product_name'),
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'supplier_name',
      title: `${t('sds_list:supplier_name')}`,
      align: 'left',
      minWidth: 100,
    },
    {
      id: 'product_number',
      title: t('sds_list:product_number'),
      width: 100,
    },
    {
      id: 'revision_date',
      title: `${t('sds_list:revision_date')}`,
      width: 100,
    },
    {
      id: 'version',
      title: `${t('sds_list:version')}`,
      minWidth: 50,
      maxWidth: 80,
    },
    {
      id: 'language',
      title: `${t('sds_list:language')}`,
      align: 'left',
      minWidth: 50,
    },
    {
      id: 'country',
      title: `${t('sds_list:country')}`,
      align: 'left',
      minWidth: 50,
    },
    {
      id: 'created_date',
      title: `${t('sds_list:created_date')}`,
      width: 100,
    },
    {
      id: 'updated_date',
      title: `${t('sds_list:updated_date')}`,
      width: 100,
    },
    {
      id: 'status',
      title: `${t('sds_list:status')}`,
      minWidth: 100,
    },
    {
      id: 'action',
      title: ``,
      minWidth: 50,
      align: 'right',
    },
  ];
};

export const getCountries = () => {
  var countries = require('i18n-iso-countries');
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/nl.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/it.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/id.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/hi.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/th.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/sl.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/sk.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/sr.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/ro.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/lv.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/ko.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/is.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/hu.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/el.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/et.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/hr.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/bg.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/sq.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/ar.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/ms.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/tr.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/uk.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/fi.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/da.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/no.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/vi.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/zh.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/ja.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/bs.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/gl.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/he.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/fa.json'));
  return countries;
};

export const createNewRevision = (pdfId: string, getPDFs: () => void) => {
  const createNewRevisionPDFRequest = post(
    `/sdsAuthoring/files/${pdfId}/revision/`
  );
  createNewRevisionPDFRequest.then((response) => {
    getPDFs();
  });
};
export const archivePDF = (pdfId: string, getPDFs: () => void) => {
  const archivePDFRequest = post(`/sdsAuthoring/files/${pdfId}/archive/`);
  archivePDFRequest.then((response) => {
    if (response.status === 200) {
      getPDFs();
    }
  });
};
export const unArchivePDF = (pdfId: string, getPDFs: () => void) => {
  const archivePDFRequest = post(`/sdsAuthoring/files/${pdfId}/unArchive/`);
  archivePDFRequest.then((response) => {
    if (response.status === 200) {
      getPDFs();
    }
  });
};
export const deletePDF = (pdfId: string, getPDFs: () => void) => {
  const deletePDFRequest = deleteRequest(`/sdsAuthoring/files/${pdfId}/`);
  deletePDFRequest.then((response) => {
    getPDFs();
  });
};
