import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import EditIcon from '@mui/icons-material/Edit';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chip, Stack } from '@mui/material';
import { Button, Input } from '@material-ui/core';
import {
  setOverwrittenHazardStatements,
  setFormEdited,
} from 'reducers/pageAction';
import useStyles from './styles';

const HazardCodePanel = ({ hazard }: { hazard: any }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['actions', 'sds_authoring']);

  const [editMode, setEditMode] = React.useState(false);
  const [statements, setStatements] = React.useState(hazard.statements);
  const overwrittenHazardStatements = useSelector(
    (state: any) => state.pageReducer.overwrittenHazardStatements
  );

  return (
    <div className={classes.hazardBlockWrapper}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.hazardBlockSummary}>
            <div className={classes.hazardCodeTitleBlock}>
              <Typography style={{ fontWeight: 600, marginRight: '20px' }}>
                {hazard.statement_code}
              </Typography>
              {hazard.hazard_category_abbreviation && (
                <Typography>{hazard.hazard_category_abbreviation}</Typography>
              )}
              <div className={classes.statementsWrapper}>
                {editMode ? (
                  <>
                    <Input
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      style={{ width: '80%' }}
                      value={statements}
                      onChange={(e) => setStatements(e.target.value)}
                    />
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(
                          setOverwrittenHazardStatements({
                            ...overwrittenHazardStatements,
                            [hazard.id]: statements,
                          })
                        );
                        dispatch(setFormEdited(true));
                        setEditMode(false);
                      }}
                    >
                      {t('actions:save')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography>
                      {overwrittenHazardStatements[hazard.id]
                        ? overwrittenHazardStatements[hazard.id]
                        : hazard.statements}
                    </Typography>
                    <EditIcon
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setEditMode(true);
                      }}
                      fontSize={'small'}
                    />
                  </>
                )}
              </div>
            </div>
            {hazard.pictogram_url && (
              <img alt="" src={hazard.pictogram_url} width={50} height={50} />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div>
              {hazard.signal_word && (
                <Typography style={{ fontWeight: 600, marginBottom: '10px' }}>
                  {t('sds_authoring:signal_word')}: {hazard.signal_word}
                </Typography>
              )}
            </div>
          </div>
          {hazard.hazard_class && (
            <Typography style={{ fontWeight: 600, marginBottom: '10px' }}>
              {t('sds_authoring:hazard_class')}: {hazard.hazard_class}
            </Typography>
          )}
          {hazard.category && (
            <Typography style={{ fontWeight: 600, marginBottom: '10px' }}>
              {t('sds_authoring:category')}: {hazard.category}
            </Typography>
          )}
          {hazard.hazard_category_abbreviation && (
            <Typography style={{ fontWeight: 600, marginBottom: '10px' }}>
              {t('sds_authoring:category_abbr')}:{' '}
              {hazard.hazard_category_abbreviation}
            </Typography>
          )}
          <div>
            <Typography fontWeight={600}>
              {t('sds_authoring:prevention_statements')}
            </Typography>
            <Stack style={{ flexWrap: 'wrap' }} direction="row" spacing={1}>
              {hazard.prevention?.split(',')?.map((el: string) => {
                return el ? (
                  <Chip
                    style={{ margin: '5px' }}
                    key={el}
                    label={el}
                    color={'primary'}
                  />
                ) : null;
              })}
              {hazard.response?.split(',')?.map((el: string) => {
                return el ? (
                  <Chip
                    style={{ margin: '5px' }}
                    key={el}
                    label={el}
                    color={'primary'}
                  />
                ) : null;
              })}
              {hazard.storage?.split(',')?.map((el: string) => {
                return el ? (
                  <Chip
                    style={{ margin: '5px' }}
                    key={el}
                    label={el}
                    color={'primary'}
                  />
                ) : null;
              })}
              {hazard.disposal?.split(',')?.map((el: string) => {
                return el ? (
                  <Chip
                    style={{ margin: '5px' }}
                    key={el}
                    label={el}
                    color={'primary'}
                  />
                ) : null;
              })}
            </Stack>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default HazardCodePanel;
