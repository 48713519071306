import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: '5px',
  },
  contentWrapper: {
    width: '100%',
    height: '100%',
    margin: '0px auto',
  },
  dialogContent: {
    minHeight: '80vh',
  },
}));

export default useStyles;
