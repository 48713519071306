import { Add } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Button, Grid, Select, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextFieldInputV2 from 'components/text-field';
import { setPhysChemProperties } from 'reducers/pageAction';
import OutlinedSection from '../outlined-section';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import { AutocompleteInput, AutocompleteOptions } from './AutoCompleteInput';
import { CustomFormProps } from '../utils';
import { DeleteOutline } from '@material-ui/icons';
import SaveAsButton from 'components/save-as-btn';

interface PhysicalFormProps extends CustomFormProps {
  initialValues: any;
}

const PhysicalAndChemicalPropertiesForm = (
  props: PhysicalFormProps & InjectedFormProps<{}, PhysicalFormProps>
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleNext, errors, initialValues, dirty, handleCopy } = props;
  const [fieldsChanged, setFieldsChanged] = React.useState<boolean>(false);
  const { t } = useTranslation(['sds_authoring']);
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const [showAddNew, setShowAddNew] = React.useState(false);
  const form = useSelector(
    (state: any) => state.form.physicalAndChemicalPropertiesForm
  );
  const physChemProperties: any = useSelector(
    (state: any) => state.pageReducer.physChemProperties
  );
  const additionalPhysChemProperties = useSelector(
    (state: any) => state.pageReducer.additionalPhysChemProperties
  );

  const header9_1property = physChemProperties?.find((el: any) => {
    if (el) {
      return el.tag === '#9_1_HEADER_PHYSICAL_AND_CHEM_PROPERTIES';
    }
  });

  const filteredProperties = physChemProperties.filter((el: any) => {
    if (el) {
      return el.tag !== header9_1property?.tag;
    }
  });

  const options = AutocompleteOptions();

  interface PhysChemPropertiesType {
    [key: string]: any;
  }
  const handleSubmit = () => {
    if (dirty || fieldsChanged) {
      var physChemProperties: PhysChemPropertiesType = {};
      Object.keys(form.values).forEach((key: any) => {
        if (key !== 'phys_chem_properties_other_information') {
          const value = form.values[key];

          physChemProperties[key] = form.values[key];

          options.map((option) => {
            if (value === option.value) {
              return (physChemProperties[key] = option.name);
            }
          });
        }
      });
      handleNext({
        physical_chemical_properties: physChemProperties,
        phys_chem_properties_other_information:
          form.values.phys_chem_properties_other_information,
      });
    } else {
      handleNext(null);
    }
  };

  const handleSelectChange = (e: any) => {
    setFieldsChanged(true);
    const selectedEl = additionalPhysChemProperties[e.target.value];
    dispatch(setPhysChemProperties([...physChemProperties, selectedEl]));
    setShowAddNew(false);
  };

  const handleDeleteField = (property: any) => {
    setFieldsChanged(true);
    dispatch(
      setPhysChemProperties(
        physChemProperties.filter((el: any) => el.tag !== property.tag)
      )
    );
  };

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['9_header_physical_chem_properties']}
      </Typography>
      <form onSubmit={handleSubmit}>
        <OutlinedSection
          title={
            translations.headers['9_1_header_physical_and_chem_properties']
          }
        >
          {header9_1property &&
            initialValues &&
            initialValues[header9_1property.literal] && (
              <Field
                name={header9_1property.tag}
                component={TextFieldInputV2}
                label={header9_1property.literal}
                classes={{ root: classes.textInput }}
              />
            )}
          {filteredProperties.map((el: any, index: number) => (
            <Grid item container md={6} xs={12} key={index}>
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={11}>
                  <Field
                    name={el.tag}
                    component={AutocompleteInput}
                    label={el.literal}
                    classes={{ root: classes.textInput }}
                  />
                </Grid>
                {additionalPhysChemProperties.find(
                  (additionalProp: any) => additionalProp.tag === el.tag
                ) ? (
                  <Grid item xs={1}>
                    <Button onClick={() => handleDeleteField(el)}>
                      <DeleteOutline />
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          ))}
          <Grid container item xs={12} spacing={{ xs: 2, md: 6 }}>
            <Grid item xs={12} md={6}>
              {!showAddNew && (
                <Button variant="outlined" onClick={() => setShowAddNew(true)}>
                  {t('actions:add')}
                  <Add />
                </Button>
              )}
              {showAddNew && (
                <FormControl variant="filled" sx={{ width: '100%' }}>
                  <Select onChange={handleSelectChange} native>
                    <option value={''}>-</option>
                    {additionalPhysChemProperties.map(
                      (item: any, index: number) => {
                        return (
                          <option value={index} key={index}>
                            {item.literal}
                          </option>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </OutlinedSection>

        <OutlinedSection
          title={translations.headers['9_2_header_other_information']}
        >
          <Grid item xs={12}>
            <Field
              name={'phys_chem_properties_other_information'}
              component={TextFieldInputV2}
              fullWidth
              multiline
              classes={{ root: classes.textInput }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.errorsRow}>
              {errors?.map((el: any, index: any) => {
                return (
                  <p key={index} className={classes.errorText}>
                    {el}
                  </p>
                );
              })}
            </div>
          </Grid>
        </OutlinedSection>

        <SubmitButton handleSubmit={handleSubmit} />
        <SaveAsButton handleSubmit={handleCopy} />

      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (
    state.pageReducer.sdsInfo &&
    !state.form.physicalAndChemicalPropertiesForm?.values
  ) {
    return {
      initialValues: {
        ...state.pageReducer.sdsInfo.physical_chemical_properties,
        phys_chem_properties_other_information:
          state.pageReducer.sdsInfo.phys_chem_properties_other_information,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, PhysicalFormProps>({
    form: 'physicalAndChemicalPropertiesForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(PhysicalAndChemicalPropertiesForm)
);
