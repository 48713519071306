import React from 'react';
import {
  createTheme as createThemeCore,
  ThemeProvider as ThemeProviderCore,
} from '@material-ui/core/styles';
import {
  createTheme as createThemeMui,
  ThemeProvider as ThemeProviderMui,
} from '@mui/material/styles';
import { isForWorkplaceSafety } from 'utils/helper';

const paletteInv = {
  primary: {
    main: '#626DF9',
  },
  headerColor: {
    main: '#626DF9'
  },
  hoverColor: {
    main: '#e0e7fa',
  },
  backgroundColor: {
    main: '#F2F5F7',
  },
  amber: {
    main: '#FFA500',
  },
  red: {
    main: '#FF0000',
  },
  secondary: {
    main: '#424B5A',
  },
  success: {
    main: '#008000',
  },
  purple: {
    main: '#310D67',
  },
  colors: {
    green: '#008000',
    yellow: '#FFFF00',
    amber: '#FFA500',
    red: '#FF0000',
    gray: '#cccccc',
    purple: '#310D67',
    white: '#fff',
    text: '#424B5A',
    primaryLight: '#E0E7FA',
    primaryDark: '#0006D2',
    primary: '#626DF9',
    secondary: '#00009E',
    light: '#FFFFFF',
    dark: '#0006D2',
    textLight: '#52525F',
  },
};

const paletteWorkplace = {
  primary: {
    main: '#006666',
  },
  headerColor: {
    main: '#003B3C'
  },
  hoverColor: {
    main: '#e0e7fa',
  },
  backgroundColor: {
    main: '#F2F5F7',
  },
  amber: {
    main: '#FFA500',
  },
  red: {
    main: '#FF0000',
  },
  secondary: {
    main: '#424B5A',
  },
  success: {
    main: '#008000',
  },
  purple: {
    main: '#310D67',
  },
  colors: {
    green: '#008000',
    yellow: '#FFFF00',
    amber: '#FFA500',
    red: '#FF0000',
    gray: '#cccccc',
    purple: '#310D67',
    white: '#fff',
    text: '#424B5A',
    primaryLight: '#E0E7FA',
    primaryDark: '#0006D2',
    primary: '#626DF9',
    secondary: '#00009E',
    light: '#FFFFFF',
    dark: '#0006D2',
    textLight: '#52525F',
  },
};

const palette = (
  isForWorkplaceSafety()
  ?  paletteWorkplace
  : paletteInv
)

const typography = {
  fontFamily: 'Montserrat, sans-serif',
  h1: {
    fontSize: 48,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h2: {
    fontSize: 36,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h3: {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h4: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h5: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.5,
  },
  h6: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  subtitle1: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  subtitle2: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  caption: {
    fontSize: 12,
    fontWeight: 600,
  },
  overline: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
    lineHeight: 1.66,
  },
  button: {
    fontWeight: 500,
    textTransform: undefined,
  },
};
export const theme = createThemeCore({
  palette: palette,
  typography: typography,
  overrides: {
    MuiButton: {
      root: {
        fontFamily: 'Montserrat, sans-serif',
      },
    },
    MuiDialog: {
      root: {
        '& .MuiDialogActions-root': {
          fontWeight: 500,
          justifyContent: 'center',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center',
        '& .MuiButtonBase-root': {
          position: 'absolute',
          top: 8,
          right: 8,
        },
      },
    },
    MuiDialogActions: {
      root: {
        '& .MuiButton-label': {
          textTransform: 'uppercase',
        },
      },
    },
    MuiFormControl: {
      root: {
        '& .MuiFormLabel-root': {
          fontSize: 14,
        },
        '& .MuiInputBase-root': {
          fontSize: 14,
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 14,
        fontFamily: 'Montserrat, sans-serif',
        padding: '10px',
      },
    },
  },
});
export const themeMui = createThemeMui({
  palette: palette,
  typography: typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat, sans-serif',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialogActions-root': {
            fontWeight: 500,
            justifyContent: 'center',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          '& .MuiButtonBase-root': {
            position: 'absolute',
            top: 8,
            right: 8,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButton-label': {
            textTransform: 'uppercase',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            fontSize: 14,
          },
          '& .MuiInputBase-root': {
            fontSize: 14,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 500,
          color: theme.palette.colors.text,
          padding: '20px 10px',
        },
        head: {
          color: theme.palette.colors.light,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          fontSize: 16,
          fontWeight: 500,
        },
        selectLabel: {
          fontSize: 16,
          fontWeight: 500,
        },
        select: {
          fontSize: 16,
          fontWeight: 500,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontFamily: 'Montserrat, sans-serif',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '& .MuiBreadcrumbs-separator': {
            margin: 0,
          },
          '& .MuiBreadcrumbs-li': {
            color: theme.palette.colors.text,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: theme.palette.colors.text,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme.palette.colors.text,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#EEEDED',
          '&:hover': {
            backgroundColor: '#e8e8e8',
          },
          '&.Mui-focused': {
            backgroundColor: '#EEEDED',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: '14px',
          fontWeight: 500,
        },
      },
    },
  },
});

const AppThemeProvider = ({ children }) => {
  return (
    <ThemeProviderCore theme={theme}>
      <ThemeProviderMui theme={themeMui}>{children}</ThemeProviderMui>
    </ThemeProviderCore>
  );
};

export default AppThemeProvider;
