import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '80%',
  },
  tableRow: {
    cursor: 'pointer',
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
  },
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '15px 0',
    bottom: 0,
  },
  createBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
      marginTop: 10
    },
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  }
}));

export default useStyles;
