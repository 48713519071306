export const required = (value) => (value ? undefined : 'Required');

const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength15 = maxLength(15);

const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const minValue18 = minValue(18);
