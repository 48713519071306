import React from 'react';
import { useTranslation } from 'react-i18next';
import { change, Field, FieldArray, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { Loader } from 'components';
import SelectInput from 'components/select-input';
import TextFieldInputV2 from 'components/text-field';
import { get } from 'api';
import { required } from '../sds-authoring-forms/validators';
import useStyles from './styles';
import { AddCircleOutline } from '@mui/icons-material';

const Classifications = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fields } = props;
  const { t } = useTranslation(['actions', 'our_components']);
  const [loading, setLoading] = React.useState(false);
  const [codesWithAbbreviations, setCodesWithAbbreviations] =
    React.useState(null);

  const getCodesWithAbbreviations = () => {
    setLoading(true);
    const getCodesWithAbbreviationsRequest = get(
      '/sdsAuthoring/customerComponents/classifications'
    );
    getCodesWithAbbreviationsRequest.then((response) => {
      if (response.status === 200) {
        setCodesWithAbbreviations(response.data);
        setLoading(false);
      }
    });
  };

  React.useEffect(() => {
    getCodesWithAbbreviations();
  }, []);

  return (
    <div style={{ width: '90%' }}>
      {loading || !codesWithAbbreviations ? (
        <Loader />
      ) : (
        <>
          {fields?.getAll()?.map((hazardClassification, index) => {
            const codeAbbreviations =
              codesWithAbbreviations[hazardClassification.code];
            if (codeAbbreviations && codeAbbreviations.length === 1) {
              dispatch(
                change(
                  `componentClassificationsForm`,
                  `classifications[${index}].abbreviation`,
                  codeAbbreviations[0]
                )
              );
            }
            return (
              <div key={index}>
                <div className={classes.rowWrapper}>
                  <div className={classes.formRow}>
                    <div className={classes.inputWrapper}>
                      {hazardClassification.added ? (
                        <Field
                          validate={[required]}
                          name={`classifications[${index}].code`}
                          component={SelectInput}
                          fullWidth
                          label={t('our_components:code')}
                          classes={{ root: classes.textInput }}
                          options={Object.keys(codesWithAbbreviations)}
                        />
                      ) : (
                        <Field
                          name={`classifications[${index}].code`}
                          component={TextFieldInputV2}
                          fullWidth
                          disabled
                          label={t('our_components:code')}
                        />
                      )}
                    </div>
                    <div className={classes.inputWrapper}>
                      <Field
                        validate={[required]}
                        name={`classifications[${index}].abbreviation`}
                        component={SelectInput}
                        label={t('our_components:abbreviation')}
                        classes={{ root: classes.textInput }}
                        options={codeAbbreviations || []}
                      />
                    </div>
                  </div>
                  <Button color="primary" onClick={() => fields.remove(index)}>
                    <DeleteIcon />
                  </Button>
                </div>
              </div>
            );
          })}
          <Button onClick={() => fields.push({ added: true })}>
            {t('actions:add_new_classification')}
            <AddCircleOutline color="primary" sx={{ marginLeft: '5px' }} />
          </Button>
        </>
      )}
    </div>
  );
};

const EditComponentClassificationsForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(['actions']);
  const { onSubmit, dirty } = props;
  const form = useSelector((state) => state.form.componentClassificationsForm);

  const handleSubmit = () => {
    onSubmit(form.values?.classifications);
  };

  return (
    <div className={classes.formWrapper}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FieldArray name="classifications" component={Classifications} />
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!dirty}
          color="primary"
        >
          {t('actions:save')}
        </Button>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  if (
    state.pageReducer.componentClassifications &&
    !state.form.componentClassificationsForm?.values
  ) {
    return {
      initialValues: {
        classifications: state.pageReducer.componentClassifications,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'componentClassificationsForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(EditComponentClassificationsForm)
);
