import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { Loader } from 'components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { getColumnList } from '../helper';
import { SDSTableCell } from '../sds-table-cell';
import SDSTableHeader from '../sds-table-header';
import useStyles from './styles';

interface Props {
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: any, newPage: number) => any;
  handleChangeRowsPerPage: (event: any) => any;
  loading: boolean;
  sdsPaginatedData: any;
  setSelectedSDS: (value: any) => void;
  setOpenArchivePopup: (value: boolean) => void;
  setOpenDeletePopup: (value: boolean) => void;
  setOpenCreateRevisionPopup: (value: boolean) => void;
  setOpenUnArchivePopup: (value: boolean) => void;
}

function SDSTable({
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  loading,
  sdsPaginatedData,
  setSelectedSDS,
  setOpenArchivePopup,
  setOpenDeletePopup,
  setOpenCreateRevisionPopup,
  setOpenUnArchivePopup,
}: Props) {
  const classes = useStyles();
  const history = useHistory();
  const columns = getColumnList();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Paper elevation={3}>
          <TableContainer>
            <SimpleBar style={{ maxHeight: '72vh' }}>
              <Table
                classes={{
                  root: classes.table,
                }}
                size="small"
                aria-label="a dense table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <SDSTableHeader columns={columns} />
                  </TableRow>
                </TableHead>
                <TableBody className="">
                  {sdsPaginatedData.results.map((row: any) => (
                    <TableRow
                      key={row.id}
                      hover
                      className={classes.tableRow}
                      onClick={() => {
                        history.push(`/sds-authoring/${row.id}`);
                      }}
                    >
                      {columns.map((column, index) => (
                        <SDSTableCell
                          key={index}
                          index={index}
                          column={column}
                          row={row}
                          setSelectedSDS={setSelectedSDS}
                          setOpenArchivePopup={setOpenArchivePopup}
                          setOpenDeletePopup={setOpenDeletePopup}
                          setOpenCreateRevisionPopup={
                            setOpenCreateRevisionPopup
                          }
                          setOpenUnArchivePopup={setOpenUnArchivePopup}
                        />
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      count={sdsPaginatedData.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </SimpleBar>
          </TableContainer>
        </Paper>
      )}
    </>
  );
}

export default SDSTable;
