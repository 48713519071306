import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    chipsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        alignItems: 'center',
    },
}));

export default useStyles;
