import {
  ArchiveRounded,
  ModeEditRounded,
  PictureAsPdfRounded,
  WarningAmberRounded,
} from '@mui/icons-material';
import { Box, TableCell, Tooltip } from '@mui/material';
import StatusLabel from 'components/status-label';
import { OUR_SDS_STATUS } from 'enums/our-sds-status.enum';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setComponentClassifications } from 'reducers/pageAction';
import { OurComponentsProps } from '../helper';
import useStyles from './styles';
import { UnarchiveRounded } from '@material-ui/icons';

const OurComponentsCellValue = ({
  column,
  row,
  codesWithMissedClassifications,
  setSelectedComponent,
  setOpenEditClassificationsPopup,
  setOpenArchivePopup,
  setOpenUnArchivePopup,
}: {
  column: string;
  row: any;
  codesWithMissedClassifications: any;
  setSelectedComponent: any;
  setOpenEditClassificationsPopup: any;
  setOpenArchivePopup: any;
  setOpenUnArchivePopup: (value: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['tooltips']);
  const classes = useStyles();

  if (column === 'component_name') {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {codesWithMissedClassifications.length > 0 && (
          <Tooltip
            title={`${t(
              'tooltips:unable_detect_hazard_abbreviation'
            )} ${codesWithMissedClassifications.join(',')}. ${t(
              'tooltips:add_manually_abbreviation'
            )}`}
            describeChild
          >
            <WarningAmberRounded style={{ marginLeft: 0, fill: '#e38b02' }} />
          </Tooltip>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: row.component_name,
          }}
        />
      </Box>
    );
  }

  if (column === 'classifications') {
    return (
      <>
        {row.classifications.map((el: any, index: number) => {
          if (el.abbreviation === '') {
            return <p key={index}>{el.code}</p>;
          } else {
            return (
              <p key={index}>
                {el.abbreviation}, {el.code}
              </p>
            );
          }
        })}
        <Tooltip title={t('tooltips:edit_classifications')} describeChild>
          <ModeEditRounded
            style={{ marginLeft: 0 }}
            className={classes.edit}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedComponent(row);
              dispatch(setComponentClassifications(row.classifications));
              setOpenEditClassificationsPopup(true);
            }}
          />
        </Tooltip>
      </>
    );
  }

  if (column === 'action') {
    return (
      <div className={classes.tableActions}>
        <Tooltip title={t('tooltips:open_tooltip')} describeChild>
          <a
            className={classes.pdfIcon}
            href={row.pdf_view_url}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PictureAsPdfRounded />
          </a>
        </Tooltip>
        {row.status !== 'archived' && (
          <Tooltip title={t('tooltips:move_to_archive')} describeChild>
            <ArchiveRounded
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedComponent(row);
                setOpenArchivePopup(true);
              }}
            />
          </Tooltip>
        )}
        {row.status === 'archived' && (
          <Tooltip title={t('tooltips:remove_from_archive')} describeChild>
            <UnarchiveRounded
              fill={'#0d324f'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedComponent(row);
                setOpenUnArchivePopup(true);
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  }

  if (column === 'ghs_pictograms') {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {row.ghs_pictograms?.map((el: any, index: number) => (
          <img key={index} src={el} alt={'GHS'} width="24px" height="24px" />
        ))}
      </Box>
    );
  }

  if (column === 'status') {
    const status = row.status;
    if (status === OUR_SDS_STATUS.ARCHIVED) {
      return <StatusLabel color={'amber'} label={status} />;
    }
    if (status === OUR_SDS_STATUS.APPROVED) {
      return <StatusLabel color={'success'} label={status} />;
    }
    return <StatusLabel color={'secondary'} label={status} />;
  }

  return row[column] ?? null;
};

export const OurComponentsCell = ({
  index,
  column,
  row,
  codesWithMissedClassifications,
  setSelectedComponent,
  setOpenEditClassificationsPopup,
  setOpenArchivePopup,
  setOpenUnArchivePopup,
}: {
  index: number;
  column: OurComponentsProps;
  row: any;
  codesWithMissedClassifications: any;
  setSelectedComponent: any;
  setOpenEditClassificationsPopup: any;
  setOpenArchivePopup: any;
  setOpenUnArchivePopup: (value: boolean) => void;
}) => {
  return (
    <TableCell
      key={column.id + index}
      align={column.align ?? 'center'}
      style={{
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        padding: '20px 10px',
      }}
    >
      <OurComponentsCellValue
        column={column.id}
        row={row}
        codesWithMissedClassifications={codesWithMissedClassifications}
        setSelectedComponent={setSelectedComponent}
        setOpenEditClassificationsPopup={setOpenEditClassificationsPopup}
        setOpenArchivePopup={setOpenArchivePopup}
        setOpenUnArchivePopup={setOpenUnArchivePopup}
      />
    </TableCell>
  );
};
