let initialState = {
  pdfId: null,
  userInfo: null,
  languageCode: 'en',
  translations: null,
  countriesSettings: {},
  overwrittenHazardStatements: {},
  sdsInfo: {},
  physChemProperties: {},
  additionalPhysChemProperties: {},
  completedAuthoringStep: [],
  showConfirmBackDialog: false,
  defaultSupplierInfo: null,
  structuredComponentData: {},
  selectedComponentId: null,
  structuredDataEdited: false,
  componentClassifications: null,
  componentDetails: null,
  doNotIncludeStructuredDataFromComponents: [],
  formEdited: false,
  overwrittenPPEDescriptions: {},
  isSubstanceSDS: false,
};

const pageReducer = (state = initialState, action) => {
  const actionType = action.type;
  switch (actionType) {
    case 'pdfId':
      return {
        ...state,
        pdfId: action.payload,
      };
    case 'formEdited':
      return {
        ...state,
        formEdited: action.payload,
      };
    case 'componentClassifications':
      return {
        ...state,
        componentClassifications: action.payload,
      };
    case 'doNotIncludeStructuredDataFromComponents':
      return {
        ...state,
        doNotIncludeStructuredDataFromComponents: action.payload,
      };
    case 'componentDetails':
      return {
        ...state,
        componentDetails: action.payload,
      };
    case 'defaultSupplierInfo':
      return {
        ...state,
        defaultSupplierInfo: action.payload,
      };
    case 'overwrittenHazardStatements':
      return {
        ...state,
        overwrittenHazardStatements: action.payload,
      };
    case 'languageCode':
      return {
        ...state,
        languageCode: action.payload,
      };
    case 'userInfo':
      return {
        ...state,
        userInfo: action.payload,
      };
    case 'sdsInfo':
      return {
        ...state,
        sdsInfo: action.payload,
      };
    case 'translations':
      return {
        ...state,
        translations: action.payload,
      };
    case 'countriesSettings':
      return {
        ...state,
        countriesSettings: action.payload,
      };
    case 'physChemProperties': {
      return {
        ...state,
        physChemProperties: action.payload,
      };
    }
    case 'additionalPhysChemProperties': {
      return {
        ...state,
        additionalPhysChemProperties: action.payload,
      };
    }
    case 'addCompletedStep': {
      return {
        ...state,
        completedAuthoringStep: [
          ...state.completedAuthoringStep,
          action.payload,
        ],
      };
    }
    case 'removeCompletedStep': {
      return {
        ...state,
        completedAuthoringStep: state.completedAuthoringStep.filter(
          (el) => el !== action.payload
        ),
      };
    }
    case 'clearCompletedSteps': {
      return {
        ...state,
        completedAuthoringStep: [],
      };
    }
    case 'showConfirmBackDialog': {
      return {
        ...state,
        showConfirmBackDialog: action.payload,
      };
    }
    case 'structuredComponentData': {
      return {
        ...state,
        structuredComponentData: {
          ...state.structuredComponentData,
          ...action.payload,
        },
      };
    }
    case 'selectedComponentId': {
      return {
        ...state,
        selectedComponentId: action.payload,
      };
    }
    case 'structuredDataEdited': {
      return {
        ...state,
        structuredDataEdited: action.payload,
      };
    }
    case 'overwrittenPPEDescriptions': {
      return {
        ...state,
        overwrittenPPEDescriptions: action.payload,
      };
    }
    case 'isSubstanceSDS': {
      return {
        ...state,
        isSubstanceSDS: action.payload,
      };
    }

    default:
      return state;
  }
};

export default pageReducer;
