import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import useStyles from '../../styles';
import { useSelector } from 'react-redux';

const InformationOtherHazardFields = () => {
  const { t } = useTranslation(['fields']);
  const classes = useStyles();
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        marginTop: 20,
      }}
    >
      <Field
        name="endocrine_disrupting_properties"
        component={TextFieldInputV2}
        label={
          translations.sections.endocrine_disrupting_properties
            ? translations.sections.endocrine_disrupting_properties
            : t('fields:endocrine_disrupting_properties')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
      <Field
        name="toxic_other_information"
        component={TextFieldInputV2}
        label={
          translations.sections.toxic_other_information
            ? translations.sections.toxic_other_information
            : t('fields:toxic_other_information')
        }
        classes={{ root: classes.textInput }}
        multiline={true}
      />
    </div>
  );
};

export default InformationOtherHazardFields;
