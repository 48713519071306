import TextFieldInputV2 from 'components/text-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StepFieldsProps } from '../utils';

export const getStep4Fields = (): Array<StepFieldsProps> => {
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const { t } = useTranslation(['fields']);
  return [
    {
      title: translations.headers['4_1_header_description_first_aid_meadures'],
      fields: [
        {
          name: 'first_aid_icons',
          label: '',
          xs: 12,
          isFieldArray: true,
        },
        {
          name: 'first_aid_general_notes',
          component: TextFieldInputV2,
          label: translations.sections.first_aid_general_notes
            ? translations.sections.first_aid_general_notes
            : t('fields:general_notes'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'if_inhaled',
          component: TextFieldInputV2,
          label: translations.sections.if_inhaled
            ? translations.sections.if_inhaled
            : t('fields:if_inhaled'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'skin_contact',
          component: TextFieldInputV2,
          label: translations.sections.skin_contact
            ? translations.sections.skin_contact
            : t('fields:skin_contact'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'eyes_contact',
          component: TextFieldInputV2,
          label: translations.sections.eyes_contact
            ? translations.sections.eyes_contact
            : t('fields:eyes_contact'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'if_swallowed',
          component: TextFieldInputV2,
          label: translations.sections.if_swallowed
            ? translations.sections.if_swallowed
            : t('fields:if_swallowed'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'self_protection',
          component: TextFieldInputV2,
          label: translations.sections.self_protection
            ? translations.sections.self_protection
            : t('fields:self_protection'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title: translations.headers['4_2_header_most_important_symptoms'],
      fields: [
        {
          name: 'symptoms',
          component: TextFieldInputV2,
          label: translations.sections.symptoms
            ? translations.sections.symptoms
            : t('fields:symptoms'),
          multiline: true,
          xs: 12,
        },
      ],
    },
    {
      title:
        translations.headers[
          '4_3_header_indication_of_any_immediate_medical_attn'
        ],
      fields: [
        {
          name: 'medical_attention_and_treatment_needed',
          component: TextFieldInputV2,
          label: '',
          multiline: true,
          xs: 12,
        },
        {
          name: 'note_to_physicians',
          component: TextFieldInputV2,
          label: translations.sections.note_to_physicians
            ? translations.sections.note_to_physicians
            : t('fields:note_to_physicians'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'general_advice',
          component: TextFieldInputV2,
          label: translations.sections.general_advice
            ? translations.sections.general_advice
            : t('fields:general_advice'),
          multiline: true,
          xs: 12,
        },
        {
          name: 'specific_treatment',
          component: TextFieldInputV2,
          label: translations.sections.specific_treatment
            ? translations.sections.specific_treatment
            : t('fields:specific_treatment'),
          multiline: true,
          xs: 12,
        },
      ],
    },
  ];
};
